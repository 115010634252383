import React, { Component, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import * as router from 'react-router-dom';
import { Container } from 'reactstrap';
import {
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav2 as AppSidebarNav,
} from '@coreui/react';
// sidebar nav config
import navigation from '../../_nav';
// routes config
import routes from '../../routes';
import LocalStorage, { LocalDataKeys } from '../../services/local-storage'
import UserType from '../../common/user-type';
import Loader from '../../components/Loader';

const DefaultFooter = React.lazy(() => import('../Layout/DefaultFooter'));
const DefaultHeader = React.lazy(() => import('../Layout/DefaultHeader'));

const AuthorizationRoute =
  (props) => {
    const currentRole = parseInt(LocalStorage.manipulateStorage(LocalDataKeys.role, 'get'))
    if (currentRole === props.role || props.role === UserType.ALL) {
      return <Route {...props} />;
    } else {
      return <Redirect to="/notFound/" />;
    }
  }

class Main extends Component {

  constructor() {
    super();
    this.state = {
      roleBasedNav: {
        items: []
      }
    }
  }

  componentDidMount() {
    let roleBasedNav = {items: []}
    const currentRole = parseInt(LocalStorage.manipulateStorage(LocalDataKeys.role, 'get'))
    navigation.items.forEach(nav => {
      if (currentRole === nav.role || nav.role === UserType.ALL) {
        roleBasedNav.items.push(nav);
      }
    });
    this.setState({roleBasedNav})
  }

  loading = () => <Loader />

  signOut(e) {
    e.preventDefault()
    LocalStorage.clearLocalStorage()
    this.props.history.push('/login')
  }

  render() {
    return (
      <div className="app">
        <AppHeader fixed>
          <Suspense>
            <DefaultHeader onLogout={e => this.signOut(e)} />
          </Suspense>
        </AppHeader>
        <div className="app-body">
          <AppSidebar fixed display="lg">
            <AppSidebarHeader />
            <AppSidebarForm />
            <Suspense>
              <AppSidebarNav navConfig={this.state.roleBasedNav} {...this.props} router={router} />
            </Suspense>
            <AppSidebarFooter />
            <AppSidebarMinimizer />
          </AppSidebar>
          <main className="main">
            <Container fluid>
              <Suspense>
                <Switch>
                  {routes.map((route, idx) => {
                    return route.component ? (
                      <AuthorizationRoute
                        key={idx}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        role={route.role}
                        render={props => (
                          <route.component {...props} />
                        )} />
                    ) : (null);
                  })}
                  <Redirect from="/" to="/dashboard" />
                </Switch>
              </Suspense>
            </Container>
          </main>
        </div>
        <AppFooter>
          <Suspense>
            <DefaultFooter />
          </Suspense>
        </AppFooter>
      </div>
    );
  }
}

export default Main;
