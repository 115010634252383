import React, { Component } from 'react'; // NOSONAR
import {
    Col,
    Row,
    FormGroup,
    Label,
    Button
} from 'reactstrap';
import { Formik, Field, Form, FieldArray, getIn } from 'formik';
import * as Yup from 'yup';
import PageLayout from '../Layout/PageLayout'
import customTableAPI from '../../api/custom-table';
import { showAlert } from '../../components/Alert';
import { formatError } from '../../common/utils';
import Loader from '../../components/Loader';
import * as _ from 'lodash'

const customTable = {
    tableName: '',
    modelDefinition: [],
    businessUnitId: NaN,
}

const modelDefinitionObj = {
    name: '',
    type: '',
    oldName: '',
    required: false,
    // unique: false,
    id: false
}

class CustomTableForm extends Component {
    constructor(props) {
        super(props);
        let { businessUnitId } = this.props.match.params
        businessUnitId = (businessUnitId) ? parseInt(businessUnitId) : undefined;
        const mode = (this.props.match.params.id) ? 'Edit' : 'Add'
        const is_disabled = (this.props.match.params.id) ? true : false

        this.state = {
            isLoading: false,
            is_disabled,
            mode,
            businessUnitId,
            customTable,
            text: ''
        };
    }

    componentDidMount = async () => {
        if (this.props.match.params.id) {
            this.getCustomTable(this.props.match.params.id);
        }
    }

    getCustomTable = async id => {
        this.setState({ isLoading: true })
        try {
            const res = await customTableAPI.getById(id);
            const tableData = { ...res.data }
            let index = 0;
            tableData.modelDefinition = _.map(tableData.modelDefinition, value => {
                index++;
                // value.unique = (value.index) ? value.index.unique : (value.unique) ? value.unique : false
                value.required = (value.required) ? value.required : false
                value.id = (value.id) ? value.id : false
                // value.default = (value.default) ? value.default : ''
                value.name = (value.postgresql) ? value.postgresql?.columnName : value.name
                value.oldName = (value.oldName) ? value.oldName : value.name
                return ({ index, ...value })
            })
            this.setState({ customTable: tableData, isLoading: false })
        } catch (error) {
            this.handleFailure(error.response)
        }
    }

    handleEmailContentChange = text => {
        this.setState({ text })
    }

    handleSubmit = (values, actions) => {
        if (values.modelDefinition && values.modelDefinition.length <= 0) {
            actions.setErrors({
                modelDefinition: 'Table config is required'
            })
            actions.setSubmitting(false)
            return;
        }
        const data = {
            ...values,
            modelDefinition: [],
            businessUnitId: this.state.businessUnitId
        };
        // If current user is business user then no need to pass business unit ID, it will be default to current user's business unit
        if (!this.state.businessUnitId) {
            delete data.businessUnitId
        }

        data.tableName = data.tableName.toLowerCase()
        values.modelDefinition.map(val => {
            delete val.index
            delete val.postgresql
            val.name = val.name.toLowerCase()
            if (!val.generated) {
                data.modelDefinition.push(val)
            }
        })
        this.setState({
            isLoading: true,
        });
        if (!values.id) {
            delete data.id;
            this.add(data, actions);
        } else {
            const notAllowedEdit = ['businessUnitId', 'tableName', 'deleted', 'createdOn', 'modifiedOn', 'generated']; // following properties are not allowed to edit
            notAllowedEdit.map(val => {
                return delete data[val]
            })
            delete data.name
            this.update(data, actions);
        }
    };

    add = async (data, actions) => {
        try {
            await customTableAPI.add(data);
            const msg = 'Table added successfully';
            this.handleSuccess(msg, actions);
        } catch (error) {
            this.handleFailure(error.response, actions)
        }
    }

    update = async (data, actions) => {
        const payload = { ...data }
        const id = data.id
        delete payload.id
        try {
            await customTableAPI.update(id, payload);
            const msg = 'Table updated successfully';
            this.handleSuccess(msg, actions);
        } catch (error) {
            this.handleFailure(error.response, actions)
        }
    };

    handleSuccess = (msg) => {
        showAlert(msg);
        this.redirect();
    }

    handleFailure = (error, actions) => {
        showAlert(formatError(error), 'error', true);
        if (actions) {
            actions.setSubmitting(false)
        }
        this.setState({
            isLoading: false,
        });
    }

    redirect = () => {
        if (this.state.businessUnitId) {
            this.props.history.push(`/business-unit/${this.state.businessUnitId}/customTable`);
        } else {
            this.props.history.push('/customTable');
        }
    }

    disableElement = field => (this.state.mode === 'Edit' && field.id) ? true : false

    errorMessage = ({ errors, touched, property }) => {
        const error = getIn(errors, property)
        const touch = getIn(touched, property)
        return touch && error ? <small className='error'>{error}</small> : null;
    }

    render() {
        return (
            <PageLayout headerName={`${this.state.mode} Table`}>
                {this.state.isLoading ? <Loader /> : null}
                <Formik
                    initialValues={this.state.customTable}
                    onSubmit={(values, actions) => this.handleSubmit(values, actions)}
                    validationSchema={customTableValidationSchema}
                    enableReinitialize={true}
                    validateOnChange={false}
                >
                    {prop => {
                        const {
                            values,
                            touched,
                            errors,
                            isSubmitting
                        } = prop;
                        return (
                            <Form className='login-form' noValidate autoComplete='off'>
                                {isSubmitting ? <Loader /> : null}
                                <Row>
                                    <Col xl={10}>
                                        <FormGroup>
                                            <Label className="text-muted" htmlFor="name">
                                                Name<sup className="required">*</sup> (<b>Only alphabets & _ are allowed beginning with alphabet separated by single _ </b> )
                                            </Label>
                                            <Field
                                                name='tableName'
                                                type='text'
                                                placeholder='Table Name'
                                                className='form-control'
                                                required
                                                value={values.tableName}
                                                style={{ textTransform: "lowercase" }}
                                                disabled={this.state.is_disabled}
                                            />
                                            {errors.tableName && touched.tableName ? (
                                                <small className='error'>{errors.tableName}</small>
                                            ) : null}
                                        </FormGroup>
                                        <FormGroup>
                                            <Label className="text-muted" htmlFor="name">
                                                Table Config<sup className="required">*</sup> (<b>Only alphanumeric & _ are allowed beginning with alphabet separated by single _ </b> )
                                            </Label>
                                            <FieldArray
                                                name="modelDefinition"
                                                render={arrayHelpers => (
                                                    <div className="w-100 border rounded query-main">
                                                        {(values.modelDefinition.length > 0) ? (
                                                            values.modelDefinition.map((field, index) => (
                                                                    <Row className="py-3 border-bottom mx-0 align-items-top" key={index}>
                                                                        <Col sm={4} lg={3}>
                                                                            <Label className="text-muted" htmlFor="name">
                                                                                Property Name <sup className="required">*</sup>
                                                                            </Label>
                                                                            <Field
                                                                                name={`modelDefinition.${index}.name`}
                                                                                className="form-control mb-2"
                                                                                type="text"
                                                                                value={field.name}
                                                                                placeholder="Property Name"
                                                                                style={{ textTransform: "lowercase" }}
                                                                            />
                                                                            {this.errorMessage({ errors: errors.modelDefinition, touched: touched.modelDefinition, property: `${index}.name` })}
                                                                        </Col>
                                                                        <Col sm={4} lg={3}>
                                                                            <Label className="text-muted" htmlFor="type">
                                                                                Type <sup className="required">*</sup>
                                                                            </Label>
                                                                            <Field
                                                                                as="select"
                                                                                name={`modelDefinition.${index}.type`}
                                                                                className="form-control mb-2"
                                                                                value={field.type}
                                                                                disabled={this.disableElement(field)}
                                                                            >
                                                                                <option value="">Select Data Type</option>
                                                                                <option value="string">String</option>
                                                                                <option value="number">Number</option>
                                                                                <option value="date">Date</option>
                                                                                <option value="boolean">Boolean</option>
                                                                            </Field>
                                                                            {this.errorMessage({ errors: errors.modelDefinition, touched: touched.modelDefinition, property: `${index}.type` })}
                                                                        </Col>
                                                                        {/* <Col sm={2}>
                                                                            <Label className="text-muted" htmlFor="default">
                                                                                Default Value <sup className="required">*</sup>
                                                                            </Label>
                                                                            <Field
                                                                                name={`modelDefinition.${index}.default`}
                                                                                className="form-control mb-2"
                                                                                type="text"
                                                                                value={field.default}
                                                                                placeholder="Default Value"
                                                                            />
                                                                        </Col> */}
                                                                        <Col sm={2} lg={1} className="text-center col-6">
                                                                            <Label className="text-muted" htmlFor="required">
                                                                                Required
                                                                            </Label>
                                                                            <Field
                                                                                name={`modelDefinition.${index}.required`}
                                                                                className="form-control mb-2"
                                                                                type="checkbox"
                                                                                checked={field.required}
                                                                                disabled={this.disableElement(field)}
                                                                            />
                                                                        </Col>
                                                                        {/* <Col sm={1}>
                                                                            <Label className="text-muted" htmlFor="unique">
                                                                                Unique
                                                                            </Label>
                                                                            <Field
                                                                                name={`modelDefinition.${index}.unique`}
                                                                                className="form-control"
                                                                                type="checkbox"
                                                                                checked={field.unique}
                                                                                disabled={this.disableElement(field)}
                                                                            />
                                                                        </Col> */}
                                                                        <Col lg={1} sm={2} className="text-center col-6">
                                                                            <Label className="text-muted" htmlFor="id">
                                                                                ID
                                                                            </Label>
                                                                            <Field
                                                                                name={`modelDefinition.${index}.id`}
                                                                                className="form-control mb-2"
                                                                                type="checkbox"
                                                                                checked={field.id}
                                                                                title={(this.state.mode === 'Edit') ? `Cannot be Modified` : `Makr as Primary Key`}
                                                                                disabled={(this.state.mode === 'Edit') ? true : false}
                                                                            />
                                                                        </Col>
                                                                        <Col sm={3} className="text-right text-md-left">
                                                                            <Label />
                                                                            <div>
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-danger mt-2"
                                                                                    onClick={() => arrayHelpers.remove(index)}
                                                                                    disabled={this.disableElement(field)}
                                                                                >
                                                                                    <em className="fa fa-minus" aria-hidden="true"></em>
                                                                                </button>
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-primary ml-2 mt-2"
                                                                                    onClick={() => arrayHelpers.insert(index + 1, modelDefinitionObj)}
                                                                                >
                                                                                    <em className="fa fa-plus" aria-hidden="true"></em>
                                                                                </button>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>

                                                                ))
                                                        ) : (
                                                                <button type="button" className="btn btn-primary m-2" onClick={() => arrayHelpers.replace(0, modelDefinitionObj)}>
                                                                    Add new property
                                                                </button>
                                                            )}
                                                    </div>
                                                )}
                                            />
                                            {errors.modelDefinition && touched.modelDefinition && typeof errors.modelDefinition === 'string' ? (
                                                <small className='error'>{errors.modelDefinition}</small>
                                            ) : null}
                                        </FormGroup>
                                    </Col>

                                    <Col lg={12} className="text-right">
                                        <Button
                                            className="mr-2"
                                            color="light"
                                            onClick={() => this.redirect()}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            color="success"
                                            type="submit"
                                            disabled={isSubmitting}
                                        >
                                            Save
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        );
                    }}
                </Formik>
            </PageLayout>
        );
    }
}

export default CustomTableForm;

const customTableValidationSchema = Yup.object().shape({
    tableName: Yup.string()
        .required('Table Name is required')
        .matches(/^[a-zA-Z](?:_?[a-zA-Z]+)*$/, 'Only alphabets & _ are allowed beginning with alphabet separated by single _')
        .nullable(),
    modelDefinition: Yup.array()
        .of(
            Yup.object().shape({
                name: Yup.string()
                    .matches(/^[a-zA-Z](?:_?[a-zA-Z0-9]+)*$/, 'Only alphanumeric & _ are allowed beginning with alphabet separated by single _')
                    .required('Name is required'),
                type: Yup.string()
                    .required('Data Type is required')
            })
        )
});
