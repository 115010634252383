import React, { Component } from 'react'; // NOSONAR
import { Button, Card, CardBody, Col, Container, Label, Row, FormGroup } from 'reactstrap';
import authAPI from '../../../api/auth';
import logo from '../../../assets/img/brand/bayview-logo.png';
import { Field, Formik, Form } from 'formik';
import { object as yupObject, string as yupString } from 'yup';
import Loader from '../../../components/Loader';
import { formatError } from '../../../common/utils';
import { showAlert } from '../../../components/Alert';
import LocalStorage, { LocalDataKeys } from '../../../services/local-storage';

export default class Login extends Component {
    handleSubmit = async (values, actions) => {
        const payload = {
            client_id: `${process.env.REACT_APP_CLIENT_ID}`,
            client_secret: `${process.env.REACT_APP_CLIENT_SECRET}`,
            username: values.username,
            password: values.password,
        };
        // Try login
        try {
            const res = await authAPI.login(payload);
            LocalStorage.manipulateStorage(LocalDataKeys.accessToken, 'set', res.data.accessToken);
            LocalStorage.manipulateStorage(LocalDataKeys.refreshToken, 'set', res.data.refreshToken);
            // If successful login then get profile data
            try {
                const response = await authAPI.me();
                for (let key in LocalDataKeys) {
                    LocalStorage.manipulateStorage(LocalDataKeys[key], 'set', response.data[key]);
                }
                LocalStorage.manipulateStorage(LocalDataKeys.businessUnitId, 'set', response.data.defaultBusinessUnit);
                this.props.history.push('/');
            } catch (error) {
                LocalStorage.clearLocalStorage();
                showAlert(formatError(error), 'error', true);
                actions.resetForm();
                actions.setSubmitting(false);
            }
        } catch (error) {
            showAlert(formatError(error), 'error', true);
            actions.resetForm();
            actions.setSubmitting(false);
        }
    };

    render() {
        return (
            <div className="login-layout">
                <div className="app cst-hgt flex-row align-items-center">
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg="6">
                                <Card className="border-0">
                                    <CardBody>
                                        <Formik
                                            validationSchema={yupObject().shape({
                                                //
                                                username: yupString()
                                                    .required('username is required')
                                                    .email('Please enter Valid Email'),
                                                password: yupString().required('password is required'),
                                            })}
                                            initialValues={{
                                                username: '',
                                                password: '',
                                            }}
                                            onSubmit={(values, actions) => this.handleSubmit(values, actions)}
                                        >
                                            {props => {
                                                const { isSubmitting, values, errors, touched } = props;
                                                return (
                                                    <Form className="login-form" noValidate autoComplete="off">
                                                        {isSubmitting ? <Loader /> : null}
                                                        <div className="top text-center mb-5">
                                                            <div className="text-center mb-2">
                                                                <img src={logo} alt="Bayview Logo" />
                                                            </div>
                                                            <h1>Login</h1>
                                                            <p className="text-muted">Sign In with your email.</p>
                                                        </div>
                                                        <FormGroup>
                                                            <Label className="text-muted" htmlFor="exampleEmail">
                                                                Username <sup className="required">*</sup>
                                                            </Label>
                                                            <Field
                                                                name="username"
                                                                type="email"
                                                                placeholder="Username"
                                                                className="form-control"
                                                                required
                                                                label={'email'}
                                                                value={values.username}
                                                            />
                                                            {errors.username && touched.username ? (
                                                                <small className="error">{errors.username}</small>
                                                            ) : null}
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label className="text-muted" htmlFor="password">
                                                                Password <sup className="required">*</sup>
                                                            </Label>
                                                            <Field
                                                                name="password"
                                                                placeholder="Password"
                                                                className="form-control"
                                                                type="password"
                                                                required
                                                                value={values.password}
                                                            />
                                                            {errors.password && touched.password ? (
                                                                <small className="error">{errors.password}</small>
                                                            ) : null}
                                                            <div className="text-center mt-2">
                                                                <a href="/#/forgot-password">Forgot Password</a>
                                                            </div>
                                                        </FormGroup>
                                                        <Row style={{ margin: 20 }}>
                                                            <Col xs="12">
                                                                <Button
                                                                    type="submit"
                                                                    disabled={isSubmitting}
                                                                    color="primary"
                                                                    className="px-4 btn-lg btn-block"
                                                                >
                                                                    Login
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                );
                                            }}
                                        </Formik>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        );
    }
}
