import React, { Component } from 'react'; // NOSONAR
import { Button, Card, CardBody, Col, Container, Label, Row, FormGroup } from 'reactstrap';
import logo from '../../../assets/img/brand/bayview-logo.png';
import { Field, Formik, Form } from 'formik';
import { object as yupObject, string as yupString } from 'yup';
import Loader from '../../../components/Loader';
import userAPI from '../../../api/users';
import { showAlert } from '../../../components/Alert';

export default class ForgotPassword extends Component {

    handleSubmit = async (values, actions) => {
        const payload = { email: values.email };
        try {
            await userAPI.forgotPassword(payload);
            showAlert('Email Sent');
            this.props.history.push('/login');
        } catch (error) {
            actions.resetForm();
            actions.setSubmitting(false);
            showAlert('Invalid email. Please try with correct email address', 'error', true);
        }
    };

    render() {
        return (
            <div className="login-layout">
                <div className="app cst-hgt flex-row align-items-center">
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg="6">
                                <Card className="border-0">
                                    <CardBody>
                                        <Formik
                                            validationSchema={yupObject().shape({
                                                email: yupString()
                                                    .email()
                                                    .required('email is required'),
                                            })}
                                            initialValues={{
                                                email: '',
                                            }}
                                            onSubmit={(values, actions) => {
                                                this.handleSubmit(values, actions);
                                            }}
                                        >
                                            {props => {
                                                const { isSubmitting, values, errors, touched, handleChange } = props;
                                                return (
                                                    <Form className="login-form" noValidate autoComplete="off">
                                                        {isSubmitting ? <Loader /> : null}
                                                        <div className="top text-center mb-3">
                                                            <div className="text-center mb-2">
                                                                <img src={logo} alt="Bayview Logo" />
                                                            </div>
                                                            <h1>Forgot Password</h1>
                                                        </div>
                                                        <FormGroup>
                                                            <Label className="text-muted" htmlFor="email">
                                                                Email <sup className="required">*</sup>
                                                            </Label>
                                                            <Field
                                                                name="email"
                                                                type="email"
                                                                placeholder="Email"
                                                                className="form-control"
                                                                required
                                                                onChange={handleChange}
                                                                value={values.email}
                                                            />
                                                            {errors.email && touched.email ? (
                                                                <small className="error pb-0">{errors.email}</small>
                                                            ) : null}
                                                        </FormGroup>

                                                        <Row>
                                                            <Col xs="12">
                                                                <Button
                                                                    type="submit"
                                                                    disabled={isSubmitting}
                                                                    color="primary"
                                                                    className="px-4 btn-lg btn-block"
                                                                >
                                                                    Send Email
                                                                </Button>
                                                                <div className="text-center mt-2">
                                                                    <a href="/#/login">Back to Login</a>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                );
                                            }}
                                        </Formik>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        );
    }
}
