import React, {Component} from 'react'
import {isEqual} from 'lodash'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import classnames from 'classnames'
import Loader from '../../components/Loader'

import * as defaultConfig from '../../common/default-config.json'
const config = defaultConfig.default;

class DataTable extends Component {
  constructor(props) {
    super(props)

    this.state = {
      columns: this.getColumns(props)
    }
  }

  _setTableOption = (isLoading = false) => {
    if (isLoading) {
      return (
        'Loading...'
      )
    } else {
      if (this.props.noDataText) {
        return this.props.noDataText
      }
    }
    return 'No Record Found'
  }

  static renderShowsTotal(start, to, total) {
    let startVal = start
    if (start > to) {
      startVal = 1
    }
    return (
      <span>
        Showing rows {`${startVal} to ${to} of ${total}`}
      </span>
    )
  }

  handleTableChange = (type, {sortField, sortOrder, page, sizePerPage}) => {
    if (type === 'sort') {
      this.props.onSortChange(sortField, sortOrder)
    }
    if (type === 'pagination') {
      this.props.onPageChange(page, sizePerPage)
    }
  }

  expandColumnRenderer(expanded, header = false) {
    const classes = classnames(
      // 'fa',
      // 'fa-lg',
      {
        'icon-arrow-up': expanded,
        'icon-arrow-down': !expanded
      }
    )
    let title = expanded ? 'collapse' : 'expand'
    if (header) {
      title = title + 'All'
    }
    return <i className={classes} title={this.props.translate(`Actions.${title}`)}/>
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (!isEqual(this.props, nextProps)) {
      this.setState({
        columns: this.getColumns(nextProps)
      })
    }
  }

  getColumns(props) {
    const {columns} = props
    return columns.map(column => {
      if (!column.isTranslated && column.text !== '') {
        column.text = (column.text || column.dataField)
        column.isTranslated = true
      }

      if (column.titleFormat) {
        column.headerFormatter = columnName => <div>{columnName.text} {columnName.titleFormat}</div>
      }
      column.headerStyle = column.headerStyle || {'width': column.width, textAlign: 'center'}
      if (!column.attrs) {
        column.style = column.style || {textAlign: 'center'}
      } else {
        if (column.attrs.style) {
          column.attrs.style.textAlign = 'center'
        } else {
          column.attrs.style = {textAlign: 'center'}
        }
      }
      return column
    })
  }

  render() {
    const {data, isLoading, currentPage, totalDataSize, expandRow, size, selectRow, rowClasses} = this.props
    let pagination = !!totalDataSize
    let showRow = null
    if (!pagination) {
      const showSize = totalDataSize || size || 0
      showRow = <span>
        {showSize || 1}
      </span>
    }
    const options = {
      sizePerPage: config.PAGINATION_LIMIT,
      paginationSize: 3,
      page: (currentPage) || 1,
      totalSize: (totalDataSize) || 0,
      alwaysShowAllBtns: true, // Always show next and previous button
      // withFirstAndLast: false, // Hide the going to First and Last page button
      hideSizePerPage: true, /** Hide the sizePerPage dropdown always **/
      hidePageListOnlyOnePage: true, /** Hide the pagination list when only one page **/
      // firstPageText: <i className='icon-control-start' />,
      // prePageText: <i className='icon-arrow-left' />,
      // nextPageText: <i className='icon-arrow-right' />,
      // lastPageText: <i className='icon-control-end' />,
      showTotal: true,
      paginationTotalRenderer: DataTable.renderShowsTotal
    }
    if (expandRow) {
      expandRow.showExpandColumn = true
      expandRow.expandByColumnOnly = true
      expandRow.expandColumnRenderer = ({expanded}) => this.expandColumnRenderer(expanded)
      expandRow.expandHeaderColumnRenderer = ({isAnyExpands}) => this.expandColumnRenderer(isAnyExpands, true)
    }
    const bootstrapTable = <div className="table-responsive-md">{(isLoading ) ? <Loader/> : null}
      <BootstrapTable
        bordered={false}
        hover
        remote
        classes={'dataTable table-bordered'}
        keyField={'id'}
        data={(data) || []}
        columns={this.state.columns}
        rowClasses={rowClasses}
        selectRow={selectRow}
        onTableChange={this.handleTableChange}
        pagination={pagination ? paginationFactory(options) : null}
        noDataIndication={this._setTableOption((isLoading || !data))}
        expandRow={expandRow}
      />
    </div>
    delete data.userBusinessUnit;
    return (
      pagination
        ? bootstrapTable : <div>
          {bootstrapTable}
          {showRow}
        </div>
    )
  }
}

export default DataTable
