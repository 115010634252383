import React, { Component } from 'react'; // NOSONAR
import { Button, Card, CardBody, Col, CardHeader, Label, Row, FormGroup } from 'reactstrap';
import { Field, Formik, Form } from 'formik';
import { object as yupObject, string as yupString } from 'yup';
import Loader from '../../../components/Loader';
import userAPI from '../../../api/users';
import { showAlert } from '../../../components/Alert';
import { formatError } from '../../../common/utils';
import { validatePassword } from '../../../common/utils'
import Exception from '../../../common/exception'

export default class ChangePassword extends Component {
    handleSubmit = async (values, actions) => {
        if (!validatePassword(values.newPassword)) {
            actions.resetForm();
            actions.setSubmitting(false);
            showAlert(Exception.PASSWORD_EXCEPTION, 'error', true);
            return
        }
        let payload = {
            password: values.newPassword,
            oldPassword: values.oldPassword,
        };
        try {
            await userAPI.changePassword(payload);
            showAlert('Password changed successfully');
            this.props.history.push('/login');
        } catch (error) {
            actions.resetForm();
            actions.setSubmitting(false);
            showAlert(formatError(error), 'error', true);
        }
    };

    render() {
        return (
            <div className="animated fadeIn">
                <Row className="row justify-content-center">
                    <Col className="col-xl-6 col-md-9">
                        <Card>
                            <CardHeader>
                                <h3>Change Password</h3>
                            </CardHeader>
                            <CardBody>
                                <Formik
                                    validationSchema={yupObject().shape({
                                        //
                                        oldPassword: yupString().required('old password is required'),
                                        newPassword: yupString().required('new password is required'),
                                        confirmPassword: yupString()
                                            .required('password is required')
                                            .test('passwords matched', 'Passwords does not match', function (value) {
                                                return this.parent.newPassword === value;
                                            }),
                                    })}
                                    initialValues={{
                                        oldPassword: '',
                                        newPassword: '',
                                        confirmPassword: '',
                                    }}
                                    onSubmit={(values, actions) => {
                                        this.handleSubmit(values, actions);
                                    }}
                                >
                                    {props => {
                                        const { isSubmitting, values, errors, touched, handleChange } = props;
                                        return (
                                            <Form className="login-form" noValidate autoComplete="off">
                                                {isSubmitting ? <Loader /> : null}
                                                <FormGroup className="row">
                                                    <Label className="text-muted col-md-4 col-form-label" htmlFor="oldPassword">
                                                        Old Password <sup className="required">*</sup>
                                                    </Label>
                                                    <div className="col-md-8">
                                                        <Field
                                                            name="oldPassword"
                                                            type="password"
                                                            placeholder="Password"
                                                            className="form-control"
                                                            required
                                                            onChange={handleChange}
                                                            value={values.oldPassword}
                                                        />
                                                        {errors.oldPassword && touched.oldPassword ? (
                                                            <small className="error pb-0">{errors.oldPassword}</small>
                                                        ) : null}
                                                    </div>
                                                </FormGroup>
                                                <FormGroup className="row">
                                                    <Label className="text-muted col-md-4 col-form-label" htmlFor="newPassword">
                                                        New Password <sup className="required">*</sup>
                                                    </Label>
                                                    <div className="col-md-8">
                                                        <Field
                                                            name="newPassword"
                                                            placeholder="Password"
                                                            className="form-control"
                                                            type="password"
                                                            onChange={handleChange}
                                                            required
                                                            value={values.newPassword}
                                                        />
                                                        {errors.newPassword && touched.newPassword ? (
                                                            <small className="error pb-0">{errors.newPassword}</small>
                                                        ) : null}
                                                    </div>
                                                </FormGroup>
                                                <FormGroup className="row">
                                                    <Label className="text-muted col-md-4 col-form-label" htmlFor="confirmPassword">
                                                        Confirm Password <sup className="required">*</sup>
                                                    </Label>
                                                    <div className="col-md-8">
                                                        <Field
                                                            name="confirmPassword"
                                                            placeholder="Password"
                                                            className="form-control"
                                                            type="password"
                                                            onChange={handleChange}
                                                            required
                                                            value={values.confirmPassword}
                                                        />
                                                        {errors.confirmPassword && touched.confirmPassword ? (
                                                            <small className="error pb-0">{errors.confirmPassword}</small>
                                                        ) : null}
                                                    </div>
                                                </FormGroup>

                                                <Row>
                                                    <Col md="8 offset-md-4 text-right">
                                                        <Button
                                                            type="submit"
                                                            disabled={isSubmitting}
                                                            color="primary"
                                                            className="px-4 btn-lg btn-block"
                                                        >
                                                            Change Password
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        );
                                    }}
                                </Formik>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}
