import React, { Component } from "react";
import EmailEditor from "react-email-editor";
import { Formik, Form } from "formik";
import { FormGroup, Button, ModalFooter } from "reactstrap";
class UnlayerEditor extends Component {
    constructor(props) {
        super(props);

    }

onLoad = () => {
    if (this.editor !== undefined) {
        this.editor.loadDesign (this.props.jsonBody);
      } else {
        setTimeout (() => this.editor.loadDesign (this.props.jsonBody), 1000);
      }
    };
    exportHtml = async (editor) => {
        editor.exportHtml((data) => {
            const { html, design } = data;
            this.props.onSave(design, html)

        });
    };

    handleSubmit = (editor) => {
        this.exportHtml(editor);
    };

    render() {
        return (
            <Formik
                initialValues={{}}
                onSubmit={() => {
                    this.handleSubmit(this.editor);
                }}
            >
                {(prop) => {
                    return (
                        <Form>
                            <div>
                                <EmailEditor
                                    ref={(editor) => (this.editor = editor)}
                                    style={{ maxHeight: 600 }}
                                    appearance
                                    onLoad={this.onLoad}

                                />
                                <ModalFooter>

                                    <FormGroup>
                                        <Button type="submit" color="success">
                                            Save
                                        </Button>
                                    </FormGroup>
                                </ModalFooter>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        );
    }

}

export default UnlayerEditor;
