import axiosInstance from '../../api';

const baseURL = 'roles'

const get = async () => await axiosInstance.get(baseURL)

const roleAPI = {
  get
};

export default roleAPI;
