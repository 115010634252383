import axiosInstance from '../../api'

const baseURL = 'templates'

const add = async payload => await axiosInstance.post(baseURL, payload)

const update = async (id, payload) => await axiosInstance.patch(`${baseURL}/${id}`, payload)

const getByBuIdAndChannelType = (businessUnitId, channelType) => {

  let where = {
    businessUnitId,
    channelType
  }
  return get(where, null, null);
};

const get = async (where, sorting, pagination) => {
  const query = {
    include: [
      {
        relation: 'types'
      },
      {
        relation: 'subscriptionTypes'
      }
    ],
    where
  };
  if (sorting) {
    query.order = sorting;
  }
  if (pagination) {
    query.offset = pagination.offset;
    query.limit = pagination.limit;
  }
  const filter = JSON.stringify(query);
  return await axiosInstance.get(baseURL, {params: {filter}})
};


const changeStatus = async id => await axiosInstance.patch(`${baseURL}/${id}/change-status`)

const getTotalCount = async where => await axiosInstance.get(`${baseURL}/count`, {params: {where}})

const getTemplateTypes = async () => await axiosInstance.get('template-types')

const getSubscriptionTypes = async () => await axiosInstance.get('subscription-types')

const getById = async id => await axiosInstance.get(`${baseURL}/${id}`)

const templateAPI = {
  add,
  update,
  get,
  getTotalCount,
  getById,
  getByBuIdAndChannelType,
  changeStatus,
  getTemplateTypes,
  getSubscriptionTypes
};

export default templateAPI;
