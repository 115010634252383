import React, {Component} from 'react'; // NOSONAR
import {Button, Col, FormGroup, Label, Row, Table} from 'reactstrap';
import {Field, Form, Formik} from 'formik';
import * as Yup from 'yup';
import PageLayout from '../Layout/PageLayout'
import smsAPI from '../../api/sms';
import templateAPI from '../../api/template';
import {showAlert} from '../../components/Alert';
import {formatError} from '../../common/utils';
import Loader from '../../components/Loader';
import ChannelType from "../../common/channel-type";
import _ from 'lodash';
import moment from 'moment';
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'


const initialFormValues = {
  mobileNumber: '',
  businessUnitId: NaN,
  templateName: '',
  messageBody: '',
}


class SmsDemoForm extends Component {
  constructor(props) {
    super(props);
    let {businessUnitId} = this.props.match.params
    businessUnitId = (businessUnitId) ? parseInt(businessUnitId) : undefined
    const mode = 'SMS Demo Form'

    this.state = {
      isLoading: true,
      mode,
      templateListSms: [],
      formValues: initialFormValues,
      businessUnitId,
      smsHistoryServerErrors: [],
      sentMessages: []
    };
  }

  componentDidMount = async () => {
    this.getSmsTemplates();
    this.getSentSms();
  };


  getSmsTemplates = async () => {
    try {

      const resForSms = await templateAPI.getByBuIdAndChannelType(this.state.businessUnitId, ChannelType.SMS);

      this.setState({
        templateListSms: resForSms.data,
      })
    } catch (error) {
      this.handleFailure(error.response)
    }
  }

getSentSms = async () => {

  smsAPI.sentMessages(this.state.businessUnitId)
    .then((result)=>{
      this.setState({
        isLoading: false,
        sentMessages: result.data
      });
    })
    .catch(()=>{
      this.setState({
        isLoading: false
      });
      this.handleServerError()
    });
}
  handleSubmit = async (values) => {

    this.setState({
      isLoading: true,
    });

    this.sendSms(values);

  };


  sendSms = async (values) => {

    const payload = {
      templateName: values.templateName,
      to: [values.mobileNumber],
      templateData: {
        sms_body: values.messageBody
      }
    };

    try {
      await smsAPI.sendSms(this.state.businessUnitId, payload);
      const msg = 'SMS sent successfully';
      this.handleSuccess(msg);
      this.getSentSms()
    } catch (error) {
      this.handleFailure(error.response)
    }
  };

  handleSuccess = (msg) => {
    showAlert(msg);
    this.setState({isLoading: false});
  }

  handleFailure = (error) => {
    showAlert(formatError(error), 'error', true);
    this.setState({isLoading: false});

  }


  toggle = () => this.setState(prevState => ({dropdownOpen: !prevState.dropdownOpen}));


  handleServerError = () => {
  let smsHistoryServerErrors = [];
    smsHistoryServerErrors.push( 'There was an error retrieving SMS History, please try again later.');
  this.setState({isLoading: false, smsHistoryServerErrors});
};

  renderSmsHistoryServerErrors = () => {
  if (_.isEmpty(this.state.smsHistoryServerErrors)) {
    return;
  }

  return (
    <div className="box box--error">
      {this.state.smsHistoryServerErrors.map((errorMessage, index) =>
        <p key={index}>{errorMessage}</p>
      )}
    </div>
  );
};

   smsTableData = ()=>{

     return this.state.sentMessages.map((sentSms)=> {
       return(
         {
           id: sentSms.id,
           createdOn: moment(sentSms.createdOn).format('YYYY/MM/DD'),
           //PhoneNumber comes with quotation mark and square brackets
           //.slice() here removes them
           phoneNumber: (sentSms.emails).slice(2, -2),
           content: sentSms.templateData.sms_body})
     })
   };

  renderSentHistoryTable = () => {

    if (_.isEmpty(this.state.sentMessages)) {
      return null;
    }

    const columns = [
      { dataField: "phoneNumber", text: "Phone Number"},
      { dataField: "content", text: "Message Content"},
      { dataField: "createdOn", text: "Date Sent"}
    ];

    const pagination = paginationFactory({
      sizePerPage: 5,
      sizePerPageList: [],
      showTotal: true,
      alwaysShowAllBtns: true,
    });

    return (
      <BootstrapTable
      keyField="id"
      data={ this.smsTableData() }
      columns={ columns }
      pagination={ pagination }
      hover
      />
    )
  };

  render() {
    return (
      <PageLayout headerName={this.state.mode}>
        {this.state.isLoading ? <Loader/> :
          <Formik
            initialValues={this.state.formValues}
            onSubmit={(values) => this.handleSubmit(values)}
            validationSchema={smsFormValidationSchema}
            enableReinitialize={true}>
            {prop => {
              const {
                values,
                touched,
                errors,
                isSubmitting,
                handleChange,
                setFieldValue
              } = prop;
              return (
                <Form className='login-form' noValidate autoComplete='off'>
                  {isSubmitting ? <Loader/> : null}
                  <p>You must enter a cell phone number and message content. Message content must be
                    no longer than 500 characters. Enter Phone number with area code, input number without symbols.</p>
                  <Row>
                    <Col lg={2}>
                      <FormGroup>
                        <Label className="text-muted" htmlFor="name">
                          Mobile Number <sup className="required">*</sup>
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col lg={4}>
                      <FormGroup>
                        <Field
                          name='mobileNumber'
                          type='tel'
                          placeholder='Mobile number'
                          className='form-control'
                          required
                          value={values.mobileNumber}/>
                        {errors.mobileNumber && touched.mobileNumber ? (
                          <small className='error'>{errors.mobileNumber}</small>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={2}>
                      <FormGroup>
                        <Label className="text-muted" htmlFor="template">
                          SMS Template <sup className="required">*</sup>
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col lg={4}>
                      <FormGroup>
                        <Field as="select" name="templateName" required value={values.templateName.toString()}
                               className='form-control'>
                          <option value="">Select Template</option>
                          {this.state.templateListSms.filter(template => template.name === "SMS-CustomBody").map(template =>
                            <option
                              key={template.id}
                              value={template.name}>{template.name}</option>)}
                        </Field>
                        {errors.templateName && touched.templateName ? (
                          <small className='error'>{errors.templateName}</small>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={2}>
                      <FormGroup>
                        <Label className="text-muted" htmlFor="name">
                          Message Content <sup className="required">*</sup>
                          <br/>(not to exceed 500 characters)
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup>
                        <Field as="textarea"
                          name='messageBody'
                          type='textarea'
                          placeholder='MessageBody'
                          className='form-control sms-message-field'
                          required
                          value={values.messageBody}/>
                        {errors.messageBody && touched.messageBody ? (
                          <small className='error'>{errors.messageBody}</small>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col sm={12} className="text-right">
                      <Button
                        className="mr-2"
                        color="light"
                        onClick={() => this.props.history.push(`/business-unit`)}>
                        Cancel
                      </Button>
                      <Button
                        color="success"
                        type="submit"
                        disabled={isSubmitting}>
                        Send SMS
                      </Button>
                    </Col>
                  </Row>
                  <Row className="sms-history-section">
                    <Col lg={9}>
                      <FormGroup>
                        <h3 className="text-primary">SMS Sent History</h3>
                        {this.renderSmsHistoryServerErrors()}
                        {this.renderSentHistoryTable()}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button
                        color="light"
                        type="submit"
                        onClick={() => this.props.history.push(`/business-unit`)}>
                        Exit to main screen
                      </Button>
                    </Col>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        }
      </PageLayout>
    );
  }
}

export default SmsDemoForm;

const smsFormValidationSchema = Yup.object().shape({
  mobileNumber: Yup.number()
    .required('Mobile Number is required')
    .min(999999999, 'Should be minimum of 10 ')
    .max(9999999999999, 'Should be maximum of 13')
    .nullable(),
  messageBody: Yup.string()
    .required('Message Body is required')
    .max(500, 'Your message is longer than 500 characters!')
    .nullable(),
  templateName: Yup.string()
    .required('Template is required')
    .nullable(),


});
