import React from 'react';
import BusinessUnit from './views/BusinessUnit';
import Consumer from './views/Consumer';
import ChangePassword from './views/Auth/ChangePassword'
import User from './views/Users/index';
import Logout from './views/Auth/Logout';
import Template from './views/Template';
import TemplateForm from './views/Template/TemplateForm';
import TemplateConfig from './views/TemplateConfig';
import TemplateConfigForm from './views/TemplateConfig/TemplateConfigForm'
import Query from './views/Query';
import QueryForm from './views/Query/QueryForm';
import Campaign from './views/Campaign';
import CampaignForm from './views/Campaign/CampaignForm';
import CampaignAttemptLog from './views/Campaign/CampaignAttemptLog';
import CustomTable from './views/CustomTable';
import CustomTableForm from './views/CustomTable/CustomTableForm';
import SmsDemo from './views/SmsDemo/SmsDemoForm';
import UserType from '../src/common/user-type'

const Dashboard = React.lazy(() => import('./views/Dashboard'));

const routes = [
    { path: '/', exact: true, name: 'Home', role: UserType.ADMIN },
    { path: '/overview', name: 'Dashboard', component: Dashboard, role: UserType.ADMIN },
    { path: '/user-management', name: 'User Management', component: User, role: UserType.ADMIN },
    { path: '/campaign', name: 'Campaign', component: Campaign, role: UserType.BUSINESS_USER },
    { path: '/campaignAttemptLog/:campaignId', name: 'Campaign Attempt Log', component: CampaignAttemptLog, role: UserType.ALL },
    { path: '/addEditCampaign/:id?', name: 'Campaign', component: CampaignForm, role: UserType.BUSINESS_USER },
    { path: '/template', name: 'Template', component: Template, role: UserType.BUSINESS_USER },
    { path: '/addEditTemplate/:id?', name: 'Template', component: TemplateForm, role: UserType.BUSINESS_USER },
    { path: '/customTable', name: 'Custom Table', component: CustomTable, role: UserType.BUSINESS_USER },
    { path: '/addEditCustomTable/:id?', name: 'Custom Table', component: CustomTableForm, role: UserType.BUSINESS_USER },
    { path: '/query', name: 'Query', component: Query, role: UserType.BUSINESS_USER },
    { path: '/addEditQuery/:id?', name: 'Query', component: QueryForm, role: UserType.BUSINESS_USER },
    { path: '/business-unit', exact: true, name: 'Business Unit', component: BusinessUnit, role: UserType.ADMIN },
    { path: '/business-unit/:businessUnitId/consumer', name: 'Consumer', component: Consumer, role: UserType.ADMIN },
    { path: '/business-unit/:businessUnitId/smsDemo', name: 'Sms Demo', component: SmsDemo, role: UserType.ADMIN },
    { path: '/business-unit/:businessUnitId/template', name: 'Business Unit Template', component: Template, role: UserType.ADMIN },
    { path: '/business-unit/:businessUnitId/templateConfig', name: 'Business Unit Template Config', component: TemplateConfig, role: UserType.ADMIN },
    { path: '/business-unit/:businessUnitId/addEditTemplateConfig/:id?', name: 'Business Unit Template Config', component: TemplateConfigForm, role: UserType.ADMIN },
    { path: '/business-unit/:businessUnitId/addEditTemplate/:id?', name: 'Business Unit Template', component: TemplateForm, role: UserType.ADMIN },
    { path: '/business-unit/:businessUnitId/campaign', name: 'Business Unit Campaign', component: Campaign, role: UserType.ADMIN },
    { path: '/business-unit/:businessUnitId/addEditCampaign/:id?', name: 'Business Unit Campaign', component: CampaignForm, role: UserType.ADMIN },
    { path: '/business-unit/:businessUnitId/query', name: 'Business Unit Query', component: Query, role: UserType.ADMIN },
    { path: '/business-unit/:businessUnitId/addEditQuery/:id?', name: 'Business Unit Query', component: QueryForm, role: UserType.ADMIN },
    { path: '/business-unit/:businessUnitId/customTable', name: 'Business Unit Custom Table', component: CustomTable, role: UserType.ADMIN },
    { path: '/business-unit/:businessUnitId/addEditCustomTable/:id?', name: 'Business Unit Custom Table', component: CustomTableForm, role: UserType.ADMIN },
    { path: '/logout', exact: true, name: 'Logout', component: Logout, role: UserType.ALL },
    { path: '/change-password', exact: true, name: 'Change Password', component: ChangePassword, role: UserType.ALL },
];

export default routes;
