import axiosInstance from '../../api'

const baseURL = 'business-units'

const add = async  payload => await axiosInstance.post(baseURL, payload)

const update = async (id, payload) => await axiosInstance.patch(`${baseURL}/${id}`, payload)

const get = async (sorting, pagination) => {
  const query = { where: { isInternal: false } };
  if (sorting) {
    query.order = sorting;
  }
  if (pagination) {
    query.offset = pagination.offset;
    query.limit = pagination.limit;
  }
  const filter = JSON.stringify(query);
  return await axiosInstance.get(baseURL, { params: { filter } })
};

const getById = async id => {
  const query = { where: { isInternal: false } };
  const filter = JSON.stringify(query);
  return await axiosInstance.get(`${baseURL}/${id}`, { params: { filter } })
};

const getTotalCount = async () => await axiosInstance.get(`${baseURL}/count`, { params: { where: JSON.stringify({ isInternal: false }) } })

const getTableConfig = async (businessUnitId, tableName) => {
  const query = { where: { businessUnitId, tableName } };
  const filter = JSON.stringify(query);
  return await axiosInstance.get(`associated-tables`, { params: { filter } })
};

const businessUnitAPI = {
  add,
  get,
  update,
  getById,
  getTotalCount,
  getTableConfig
};

export default businessUnitAPI;
