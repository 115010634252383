import axiosInstance from '../../api'

const sendSms = async (businessUnitId, payload) => {

  const urlParams = {
    params: {
      businessUnitId: businessUnitId
    }
  }

  return await axiosInstance.post(`sms/on-demand-data`, payload, urlParams)
}

const sentMessages = async (businessUnitId) => {

  const filter ={
      "offset": 0,
      "limit": 10,
      "skip": 0,
      "order": [
        "created_on DESC"
      ],
      "where": {
        "template.name": "SMS-CustomBody"
      },
      "fields": {
        "id": true,
        "emails": true,
        "createdOn": true,
        "templateData":true
      }
    };

  return  await axiosInstance.get(`on-demand-data/logs`, {params: {filter, businessUnitId}})
};

const smsAPI = {
  sendSms,
  sentMessages
};

export default smsAPI;
