import React, {Component} from 'react'; // NOSONAR
import {Col, FormGroup, Label, Row,} from 'reactstrap';
import {Field, Form, Formik} from 'formik';
import Loader from '../../components/Loader';
import * as Yup from 'yup';
import * as _ from 'lodash'

const customForm = {
  filterBy: 'name',
  filterSearch: '',
  validateChannelsType: false,
  validateSearch: false,
};

const filterBy = [
  'name',
  'channelType',
  'frequency',
  'startDate',
  'endDate',
  'status',
  'timeZone'
];

class CampaignFilter extends Component {

  constructor(props) {
    super(props);

    this.state = {
      customForm,
      errorClass: 'filter-search-error'
    };
  }

  handleSubmit = (values, actions) => {
    actions.setSubmitting(false)
    this.handleSearch(values);
  };

  handleSearch = (values) => {

    let presetValueFilter = ['channelType'].includes(values.filterBy);// not using open value filterSearch input

    if (!presetValueFilter && _.isEmpty(values.filterSearch)) {
      this.setState({errorClass: ''});
      return
    }

    let where, wildcardSearch;
    switch (values.filterBy) {

      case 'name':
      case 'timeZone':
        wildcardSearch = '%' + values.filterSearch + '%';
        where = {
          [values.filterBy]: {
            ilike: wildcardSearch, // ilike PG case insensitive
          }
        }
        break;

      case 'status':
      case 'frequency':
        wildcardSearch = values.filterSearch + '%';
        where = {
          [values.filterBy]: {
            ilike: wildcardSearch,
          }
        }
        break;

      case 'startDate':
      case 'endDate':
        let dateValue = values.filterSearch + "T00:00:00.000Z";
        where = {
          [values.filterBy]: {gt: dateValue}
        }
        break;

      case 'channelType':
        where = {
          [values.filterBy]: {
            ilike: values.channelType
          }
        }
        break;
    }

    this.props.getFilteredData(where);//callback from parent
  };

  clearSearch = () => {
    this.props.getFilteredData();
  }

  renderChannelTypes = (values, errors, touched) => {
    const displayChannels = values.filterBy === 'channelType';

    if (!displayChannels) {
      touched.channelType = false;
      values.channelType = 'email';
      values.validateChannelsType = false;
      return null
    }

    values.validateChannelsType = true;

    return (
      <React.Fragment>
        <div className="channel-type" role="group" aria-labelledby="channel-radio-group">
          <Col sm={6} className='email-check'>
            <FormGroup sm={4}>
              <Label className="text-muted" htmlFor="email">
                Email
              </Label>
              <Field
                name='channelType'
                type='radio'
                className='form-control'
                value='email'

              />
            </FormGroup>
          </Col>
          <Col sm={6} className='push-check'>
            <FormGroup sm={4}>
              <Label className="text-muted" htmlFor="push">
                Push
              </Label>
              <Field
                name='channelType'
                type='radio'
                className='form-control'
                value='push'
              />
            </FormGroup>
          </Col>
          <Col sm={6} className='sms-check'>
            <FormGroup sm={4}>
              <Label className="text-muted" htmlFor="sms">
                SMS
              </Label>
              <Field
                name='channelType'
                type='radio'
                className='form-control'
                value='sms'

              />
            </FormGroup>
          </Col>
        </div>
        <div className="channel-type-error">
          {errors.channelType && touched.channelType ? (
            <small className='error'>{errors.channelType}</small>
          ) : null}
        </div>
      </React.Fragment>
    )
  }

  renderFilterSection = () => {
    return (
      <Formik
        initialValues={this.state.customForm}
        onSubmit={(values, actions) => this.handleSubmit(values, actions)}
        validationSchema={customFormValidationSchema}
      >
        {prop => {
          const {
            values,
            resetForm,
            touched,
            errors,
            isSubmitting
          } = prop;
          return (
            <Form className='login-form' noValidate autoComplete='off'>
              {isSubmitting ? <Loader/> : null}
              <Row>
                <Col sm={6}>
                  <FormGroup className='filter-by'>
                    <Row className="filter-row">
                      <Col sm={3} className="filter-by-label">
                        <Label className="text-muted" htmlFor="filterBy">
                          Filter by:
                        </Label>
                      </Col>
                      <Col sm={6} className="filter-by-select">
                        <Field
                          name='filterBy'
                          as='select'
                          className='form-control'
                          value={values.filterBy}>
                          <option value="name">Name</option>
                          <option value="channelType">Channel Type</option>
                          <option value="frequency">Frequency</option>
                          <option value="startDate">Start Date GT yyyy-mm-dd</option>
                          <option value="endDate">End Date GT yyyy-mm-dd</option>
                          <option value="timeZone">Time Zone</option>
                          <option value="status">Status</option>
                        </Field>
                        {errors.filterBy && touched.filterBy ? (
                          <small className='error'>{errors.filterBy}</small>
                        ) : null}
                      </Col>
                    </Row>
                    <React.Fragment>
                      {this.renderChannelTypes(values, errors, touched, resetForm)}
                    </React.Fragment>

                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <FormGroup className='filter-search'>
                    <Field
                      name='filterSearch'
                      type='input'
                      placeholder='Type to search'
                      className='form-control'
                    />
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => this.handleSearch(values)}>
                      Go
                    </button>
                  </FormGroup>
                  <div>
                    <small className={`error ${this.state.errorClass}`}>Please enter a search phrase</small>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={6} className='action-buttons'>
                  <FormGroup sm={4}>
                    <button
                      className="btn btn-primary"
                      onClick={() => this.handleSearch(values)}
                    >
                      Apply Filters
                    </button>
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={() => {
                        resetForm();
                        this.clearSearch();
                        this.setState({errorClass: 'filter-search-error'})
                      }}>
                      Clear
                    </button>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          )
        }}
      </Formik>
    )
  };


  render() {
    return (
      this.renderFilterSection()
    )
  }
}

export default CampaignFilter;

const
  customFormValidationSchema = Yup.object().shape({
    filterBy: Yup.string()
      .required('Filter is required')
      .oneOf(filterBy, "Invalid Filter"),

    validateChannelsType: Yup.bool(),
    channelType: Yup.string().when('validateChannelsType', {
      is: true,
      then: Yup.string().required('Please select a Channel Type'),
    }),
  });
