// components/Loader.jsx
import convert from 'htmr';

const setUnSubscribeLink = (body, subscriptionType, businessUnitName) => {
  return `${body}
      <a href='${process.env.UNSUBSCRIBE_LINK}?bvc={{Attributes.username}}&type=${subscriptionType}&businessUnit=${businessUnitName}'>Unsubscribe</a>`;
}

export const TemplatePreview = props => {
  if (props.html) {
    return convert(`${setUnSubscribeLink(props.html, props.subscriptionType, props.businessUnitName)}`)
  } else {
    return null
  }
};

const Preview = props => {
  if (props.html) {
      return convert(props.html)
  } else {
      return null
  }
};

export default Preview
