import React, { Component } from 'react'; // NOSONAR
import { Button, Col, FormGroup, Label, Row } from 'reactstrap';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import PageLayout from '../Layout/PageLayout'
import { showAlert } from '../../components/Alert';
import { formatError } from '../../common/utils';
import Loader from '../../components/Loader';
import ChannelType from "../../common/channel-type";
import templateConfigAPI from '../../api/templateConfig';

const templateConfig = {
  name: '',
  channelType: '',
  businessUnitId: NaN,
  appId: ''
}

class TemplateConfigForm extends Component {
  constructor(props) {
    super(props);
    let { businessUnitId } = this.props.match.params
    businessUnitId = (businessUnitId) ? parseInt(businessUnitId) : undefined
    const mode = (this.props.match.params.id) ? 'Edit' : 'Add'
    const is_disabled = (this.props.match.params.id) ? true : false

    this.state = {
      isLoading: true,
      is_disabled,
      mode,
      businessUnitId,
      templateConfig,
    };
  }

  componentDidMount = async () => {
    if (this.props.match.params.id) {
      this.getTemplateConfig(this.props.match.params.id);
    } else{
      this.setState({ isLoading: false})
    }
  }


  getTemplateConfig = async id => {
    try {
      const res = await templateConfigAPI.getById(id);
      this.setState({ templateConfig: res.data, isLoading: false })
    } catch (error) {
      this.handleFailure(error.response)
    }
  }



  handleSubmit = (values, actions) => {
    const data = {
      ...values,
      businessUnitId: this.state.businessUnitId,
    };

    // If current user is business user then no need to pass business unit ID, it will be default to current user's business unit
    if (!this.state.businessUnitId) {
      delete data.businessUnitId
    }
    this.setState({
      isLoading: true,
    });

    if (!values.id) {
      delete data.id;
      this.add(data, actions);
    } else {
      const notAllowedEdit = ['businessUnitId','deleted', 'createdOn', 'modifiedOn']; // following properties are not allowed to edit
      notAllowedEdit.map(val => {
        return delete data[val]
      })
      this.update(data, actions);
    }
  };

  add = async (data, actions) => {
    try {
      await templateConfigAPI.add(data);
      const msg = 'Pinpoint Project Config added successfully';
      this.handleSuccess(msg, actions);
    } catch (error) {
      this.handleFailure(error.response, actions)
    }
  }

  update = async (data, actions) => {
    const payload = { ...data }
    const id = data.id
    delete payload.id
    try {
      await templateConfigAPI.update(id, payload);
      const msg = 'Pinpoint Project Config updated successfully';
      this.handleSuccess(msg, actions);
    } catch (error) {
      this.handleFailure(error.response, actions)
    }
  };


  handleSuccess = (msg) => {
    showAlert(msg);
    this.redirect();
  }

  handleFailure = (error, actions) => {
    showAlert(formatError(error), 'error', true);
    if (actions) {
      actions.setSubmitting(false)
    }
    this.setState({
      isLoading: false,
    });
  }

  redirect = () => {
    if (this.state.businessUnitId) {
      this.props.history.push(`/business-unit/${this.state.businessUnitId}/templateConfig`);
    } else {
      this.props.history.push('/templateConfig');
    }
  }

  render() {
    return (
      <PageLayout headerName={`${this.state.mode} Pinpoint Project Config`}>
        {this.state.isLoading ? <Loader /> : null}
        <Formik
          initialValues={this.state.templateConfig}
          onSubmit={(values, actions) => this.handleSubmit(values, actions)}
          validationSchema={templateConfigValidationSchema}
          enableReinitialize={true}
        >
          {prop => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
            } = prop;
            return (
              <Form className='login-form' noValidate autoComplete='off'>
                {isSubmitting ? <Loader /> : null}
                <Row>
                  <Col lg={6}>
                    <FormGroup>
                      <Label className="text-muted" htmlFor="name">
                        Name<sup className="required">*</sup> (<b>Only alphanumeric, - & _ are allowed beginning with
                        alphabet</b>)
                      </Label>
                      <Field
                        name='name'
                        type='text'
                        placeholder='Name'
                        className='form-control'
                        required
                        value={values.name}
                      />
                      {errors.name && touched.name ? (
                        <small className='error'>{errors.name}</small>
                      ) : null}
                    </FormGroup>
                  </Col>

                  <Col lg={6}>
                    <FormGroup>
                      <Label className="text-muted" htmlFor="channelType">
                        Channel Type <sup className="required">*</sup>
                      </Label>
                      <Field as="select" name="channelType" required
                        value={values.channelType.toString()}
                        className='form-control' onChange={e => {
                          handleChange(e)
                        }}
                      >
                        <option value="">Select Channel Type</option>
                        {Object.keys(ChannelType).map(key => <option key={key} value={key}>{key}</option>)}
                      </Field>
                      {errors.channelType && touched.channelType ? (
                        <small className='error'>{errors.channelType}</small>
                      ) : null}
                    </FormGroup>
                  </Col>

                  <Col lg={6}>
                    <FormGroup>
                      <Label className="text-muted" htmlFor="appId">
                        App ID<sup className="required">*</sup>
                      </Label>
                      <Field
                        name='appId'
                        type='text'
                        placeholder='App ID'
                        className='form-control'
                        required
                        value={values.appId}
                      />
                      {errors.appId && touched.appId ? (
                        <small className='error'>{errors.appId}</small>
                      ) : null}
                    </FormGroup>
                  </Col>

                  <Col lg={12} className="text-right">
                    <Button
                      className="mr-2"
                      color="light"
                      onClick={() => this.redirect()}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="success"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </PageLayout>
    );
  }
}

export default TemplateConfigForm;

const templateConfigValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required')
    .matches(/^[a-zA-Z]([a-zA-Z0-9_-]+)*$/, 'Only alphanumeric, - & _ are allowed beginning with alphabet') //NOSONAR
    .min(3, 'Should be minimum of 3 characters')
    .nullable(),
  channelType: Yup.string()
    .required('Channel Type is required')
    .nullable(),
  appId: Yup.string()
    .required('App ID is required')
    .nullable()
});
