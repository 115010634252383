import swal from '@sweetalert/with-react';

export async function showAlert(text, icon = 'success', dangerMode = false) {
  return await swal({
    text,
    icon,
    dangerMode,
  });
}

export async function confirmAlert(text, icon = 'error', dangerMode = true) {
  return await swal({
    title: "Are you sure?",
    text,
    icon,
    buttons: true,
    dangerMode
  });
}
