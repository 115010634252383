import React, { Component } from 'react'; // NOSONAR
import {
  Col,
  Row,
  FormGroup,
  Label,
} from 'reactstrap';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import PageLayout from '../Layout/PageLayout'
import ModalLayout from '../Layout/ModalLayout'
import userAPI from '../../api/users';
import businessUnitAPI from '../../api/business-unit';
import roleAPI from '../../api/roles';
import { showAlert, confirmAlert } from '../../components/Alert'
import { formatError } from '../../common/utils';
import DataTable from '../../components/DataTable'

import * as defaultConfig from '../../common/default-config.json'
const config = defaultConfig.default;

class User extends Component {

  constructor(props) {
    super(props);
    this.user = {
      id: NaN,
      firstName: '',
      lastName: '',
      defaultBusinessUnit: '',
      username: '',
      roleId: '',
    }

    this.state = {
      isLoading: false,
      modal: false,
      is_disabled: false,
      mode: 'Add',
      userList: [],
      totalDataSize: 0,
      businessUnitsList: [],
      rolesList: [],
      selectedRole: 0,
      user: this.user,
      currentPage: 1,
      sortOrder: config.DEFAULT_SORTING,
      pagination: config.DEFAULT_PAGINATION,
    };
  }
  componentDidMount() {
    this.getUserCount();
    this.getUsers();
    this.getBusinessUnits();
    this.getRoles();
  }

  onPageChange = (page, sizePerPage) => {
    this.setState({ pagination: { offset: (page * sizePerPage) - sizePerPage, limit: sizePerPage }, currentPage: page })
    this.getUsers(undefined, {
      offset: page * sizePerPage - sizePerPage,
      limit: sizePerPage
    })
  };
  onSortChange = (sortName, sortOrder) => {
    this.setState({ sortOrder: `${sortName} ${sortOrder}` })
    this.getUsers(`${sortName} ${sortOrder}`)
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  handleUpdate = (user) => {
    this.toggle();
    this.setState({
      mode: 'Edit',
      is_disabled: true,
      selectedRole: user.userBusinessUnit.roleId,
      user: {
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        defaultBusinessUnit: user.userBusinessUnit.businessUnitId,
        username: user.username,
        roleId: user.userBusinessUnit.roleId,
      }
    });
  };

  handleSubmit = (values, actions) => {
    const data = {
      ...values,
    };
    data.roleId = parseInt(data.roleId)
    if (data.roleId === 2 && !data.defaultBusinessUnit) {
      actions.setErrors({
        defaultBusinessUnit: 'Business Unit is required'
      })
      actions.setSubmitting(false)
      return;
    }
    if (data.roleId === 2) {
      data.defaultBusinessUnit = parseInt(data.defaultBusinessUnit)
    } else {
      delete data.defaultBusinessUnit
    }
    this.setState({
      isLoading: true,
    });

    if (!values.id) {
      delete data.id;
      this.add(data, actions);
    } else {
      delete data.username // name is not allowed to edit
      this.update(data, actions);
    }
  };

  add = async (data, actions) => {
    try {
      await userAPI.add(data);
      const msg = 'User added successfully';
      this.handleSuccess(msg, actions);
      this.getUserCount()
    } catch (error) {
      this.handleFailure(error.response, actions)
    }
  }

  update = async (data, actions) => {
    const payload = { ...data }
    const id = data.id
    delete payload.id
    try {
      await userAPI.update(id, payload);
      const msg = 'User updated successfully';
      this.handleSuccess(msg, actions);
    } catch (error) {
      this.handleFailure(error.response, actions)
    }
  };

  handleSuccess = (msg, actions) => {
    showAlert(msg);
    this.resetState(actions.resetForm);
    this.getUsers();
  }

  handleFailure = (error, actions) => {
    showAlert(formatError(error), 'error', true);
    if (actions) {
      actions.setSubmitting(false)
    }
    this.setState({
      isLoading: false,
    });
  }

  getUserCount = async () => {
    try {
      const res = await userAPI.getTotalCount(this.state.businessUnitId);
      this.setState({ totalDataSize: res.data.count })
    } catch (error) {
      this.handleFailure(error.response)
    }
  };

  getUsers = async (sorting = this.state.sortOrder, pagination = this.state.pagination) => {
    this.setState({
      isLoading: true,
    });
    try {
      const res = await userAPI.get(sorting, pagination);
      this.setState({ userList: res.data, isLoading: false })
    } catch (error) {
      this.handleFailure(error.response)
    }
  };

  getBusinessUnits = async () => {
    try {
      const res = await businessUnitAPI.get();
      this.setState({ businessUnitsList: res.data })
    } catch (error) {
      this.handleFailure(error.response)
    }
  };

  getRoles = async () => {
    try {
      const res = await roleAPI.get();
      this.setState({ rolesList: res.data })
    } catch (error) {
      this.handleFailure(error.response)
    }
  };

  resetState = (resetForm) => {
    if (resetForm) {
      resetForm();
    }
    this.setState({
      isLoading: false,
      modal: false,
      selectedRole: 0,
      user: this.user,
      is_disabled: false,
      mode: 'Add',
    });
  }

  actionFieldFormat = (cell, row) => {
    return (
      <div role='group' className='btn-group-none'>
        <i
          title='Edit'
          className='btn text-primary pointer mr-1 icon-pencil'
          onClick={() => this.handleUpdate(row)}
        />
        {!row.isRegistered && <i
          title='Resend Registration Email'
          className='btn text-primary pointer fa fa-send'
          onClick={() => this.resendRegistrationEmail(row.id)}
        />}
      </div>
    )
  }

  resendRegistrationEmail = async id => {
    const confirm = await confirmAlert('It will resend registration email to this user?', 'info', false);
    if (confirm) {
      try {
        await userAPI.resendRegistrationEmail(id);
        showAlert('Email sent successfully');
      } catch (error) {
        this.handleFailure(error.response)
      }
    }
  }

  businessUnitFieldFormat = (cell, row) => {
    return (
      <div>
        {row?.userBusinessUnit?.businessUnit?.name}
      </div>
    )
  }

  roleFieldFormat = (cell, row) => {
    return (
      <div>
        {row?.userBusinessUnit?.role?.name}
      </div>
    )
  }

  render() {
    const gridColumns = [
      { dataField: 'firstName', text: 'First Name', sort: true },
      { dataField: 'lastName', text: 'Last Name', sort: true },
      { dataField: 'username', text: 'Email' },
      { dataField: 'defaultBusinessUnit', text: 'Business Unit', formatter: this.businessUnitFieldFormat, sort: true },
      { dataField: 'role', text: 'Role', formatter: this.roleFieldFormat },
      { dataField: 'action', text: 'Action', formatter: this.actionFieldFormat }
    ]
    return (
      <PageLayout allowAddForm toggleModal={this.toggle} headerName='Manage Users'>
        <DataTable
          isLoading={this.state.isLoading}
          data={this.state.userList}
          columns={gridColumns}
          uniqueColumn={'id'}
          onSortChange={(sortName, sortOrder) => this.onSortChange(sortName, sortOrder)}
          currentPage={this.state.currentPage}
          totalDataSize={this.state.totalDataSize}
          onPageChange={(page, sizePerPage) => this.onPageChange(page, sizePerPage)}
        />
        <Formik
          initialValues={this.state.user}
          onSubmit={(values, actions) => this.handleSubmit(values, actions)}
          validationSchema={userValidationSchema}
          enableReinitialize={true}
        >
          {prop => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              resetForm,
              handleChange
            } = prop;
            return (
              <ModalLayout
                mode={this.state.mode}
                isOpen={this.state.modal}
                toggle={this.resetState}
                values={values}
                touched={touched}
                errors={errors}
                isSubmitting={isSubmitting}
                resetForm={resetForm}
                handleChange={handleChange}
                resetState={this.resetState}
                headerText="User"
                {...this.props}
              >
                <Row>
                  <Col lg={6}>
                    <FormGroup>
                      <Label className="text-muted" htmlFor="name">
                        First Name <sup className="required">*</sup>
                      </Label>
                      <Field
                        name='firstName'
                        type='text'
                        placeholder='First Name'
                        className='form-control'
                        required
                        value={values.firstName}
                      />
                      {errors.firstName && touched.firstName ? (
                        <small className='error'>{errors.firstName}</small>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col lg={6}>
                    <FormGroup>
                      <Label className="text-muted" htmlFor="name">
                        Last Name <sup className="required">*</sup>
                      </Label>
                      <Field
                        name='lastName'
                        type='text'
                        placeholder='Last Name'
                        className='form-control'
                        required
                        value={values.lastName}
                      />
                      {errors.lastName && touched.lastName ? (
                        <small className='error'>{errors.lastName}</small>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col lg={6}>
                    <FormGroup>
                      <Label className="text-muted" htmlFor="name">
                        Email <sup className="required">*</sup>
                      </Label>
                      <Field
                        name='username'
                        type='text'
                        placeholder='Email'
                        className='form-control'
                        required
                        value={values.username}
                        disabled={this.state.is_disabled}
                      />
                      {errors.username && touched.username ? (
                        <small className='error'>{errors.username}</small>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col lg={6}>
                    <FormGroup>
                      <Label className="text-muted" htmlFor="role">
                        Role <sup className="required">*</sup>
                      </Label>
                      <Field as="select" name="roleId" required value={values.roleId} className='form-control' onChange={e => {
                        this.setState({ selectedRole: parseInt(e.target.value) });
                        handleChange(e)
                      }}
                      >
                        <option value="">Select Role</option>
                        {this.state.rolesList.map(role => <option key={role.id} value={role.id}>{role.name}</option>)}
                      </Field>
                      {errors.roleId && touched.roleId ? (
                        <small className='error'>{errors.roleId}</small>
                      ) : null}
                    </FormGroup>
                  </Col>
                  {this.state.selectedRole === 2 &&
                    <Col lg={6}>
                      <FormGroup>
                        <Label className="text-muted" htmlFor="description">
                          Business Unit
                          </Label>
                        <Field as="select" name="defaultBusinessUnit" value={values.defaultBusinessUnit} className='form-control'>
                          <option value="">Select Business Unit</option>
                          {this.state.businessUnitsList.map(businessUnit => <option key={businessUnit.id} value={businessUnit.id}>{businessUnit.name}</option>)}
                        </Field>
                        {errors.defaultBusinessUnit && touched.defaultBusinessUnit ? (
                          <small className='error'>{errors.defaultBusinessUnit}</small>
                        ) : null}
                      </FormGroup>
                    </Col>
                  }
                </Row>
              </ModalLayout>
            );
          }}
        </Formik>
      </PageLayout>
    );
  }
}

const userValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(/^[a-zA-Z']+$/, 'Only alphabets without spaces')
    .required('First Name is required')
    .min(3, 'Should be minimum of 3 characters')
    .nullable(),
  lastName: Yup.string()
    .matches(/^[a-zA-Z']+$/, 'Only alphabets without spaces')
    .required('Last Name is required')
    .min(3, 'Should be minimum of 3 characters')
    .nullable(),
  username: Yup.string()
    .email('Please enter Valid Email')
    .required('Email is required')
    .nullable(),
  roleId: Yup.string()
    .required('Role is required'),
});

export default User;
