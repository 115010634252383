import React, {Component} from 'react'; // NOSONAR
import {Button, Col, FormGroup, Label, Row} from 'reactstrap';
import {Field, Form, Formik} from 'formik';
import * as Yup from 'yup';
import PageLayout from '../Layout/PageLayout'
import queryAPI from '../../api/query';
import campaignAPI from '../../api/campaign';
import commonAPI from '../../api/common';
import {showAlert} from '../../components/Alert';
import {createFormattedSQL, formatError} from '../../common/utils';
import Loader from '../../components/Loader';
import {formatQuery} from 'react-querybuilder';
import CampaignFrequency from '../../common/campaign-frequency';
import * as moment from 'moment';
import Preview from '../../components/Preview'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import {Scrollbars} from 'react-custom-scrollbars';
import QueryType from '../../common/query-type';
import ChannelType from "../../common/channel-type";

const campaign = {
  name: '',
  description: '',
  tps: 50,
  frequency: '',
  startDate: '',
  startTime: '',
  endDate: '',
  channelType: '',
  businessUnitId: NaN,
  queryId: '',
  timeZone: 'America/New_York'
}

class CampaignForm extends Component {
  constructor(props) {
    super(props);
    let {businessUnitId} = this.props.match.params;
    businessUnitId = (businessUnitId) ? parseInt(businessUnitId) : undefined;
    const mode = (this.props.match.params.id) ? 'Edit' : 'Add';
    const is_disabled = !!(this.props.match.params.id);

    this.state = {
      businessUnitId,
      where: {businessUnitId},

      isLoading: true,
      is_disabled,
      mode,
      queryList: {
        SMS: [],
        EMAIL: [],
        PUSH: []
      },
      timeZoneList: [],

      campaign,
      templateName: '',
      selectedFrequency: 'once',
      formattedSQL: '',
      queryType: '',
      s3Key: ''
    };
  }

  componentDidMount = async () => {
    this.getTimezones();
    await this.getQueries();
    if (this.props.match.params.id) {
      this.getCampaign(this.props.match.params.id);
    }
  }

  getTimezones = async () => {
    try {
      const res = await campaignAPI.getTimezones();
      this.setState({timeZoneList: res.data})
    } catch (error) {
      this.handleFailure(error.response)
    }
  }

  getQueries = async () => {
    try {
      const resForEmail = await queryAPI.get(this.state.where, null, null, {
        channelType: ChannelType.EMAIL
      });
      const resForSms = await queryAPI.get(this.state.where, null, null, {
        channelType: ChannelType.SMS
      });
      const resForPush = await queryAPI.get(this.state.where, null, null, {
        channelType: ChannelType.PUSH
      });
      this.setState({
        queryList: {EMAIL: resForEmail.data, SMS: resForSms.data, PUSH: resForPush.data}
      })
      if (!this.props.match.params.id) {
        this.setState({isLoading: false});
      }
      return Promise.resolve();
    } catch (error) {
      this.handleFailure(error.response)
      return Promise.reject();
    }
  };

  getCampaign = async id => {
    try {
      const res = await campaignAPI.getById(id);
      const dateTime = `${moment(res.data.startDate).utc().format("YYYY-MM-DD").toString()} ${res.data.startTime}`
      res.data.startDate = moment(dateTime).toDate()
      res.data.startTime = moment(dateTime).toDate()
      if (res.data.endDate) {
        res.data.endDate = moment(moment(res.data.endDate).utc().format("YYYY-MM-DD")).toDate()
      }

      let templateName = '';
      let templateBody = '';
      let formattedSQL = '';
      let type = '';
      let s3Key = '';
      this.state.queryList[res.data.channelType].map(query => {
        if (query.id === res.data.queryId) {
          templateName = query.template.name;
          templateBody = query.template.body;
          type = query.type
          s3Key = query.s3Key
          if (query.type !== QueryType.ADHOC) {
            formattedSQL = createFormattedSQL(formatQuery(query.queryJson, 'sql'))
          }
        }
      })
      this.setState({
        campaign: res.data,
        selectedFrequency: res.data.frequency,
        templateName,
        templateBody,
        formattedSQL,
        channelType: res.data.channelType,
        type,
        isLoading: false,
        s3Key
      })
    } catch (error) {
      this.handleFailure(error.response)
    }
  }


  handleSubmit = (values, actions) => {
    const data = {
      ...values,
      businessUnitId: this.state.businessUnitId
    };

    // If current user is business user then no need to pass business unit ID, it will be default to current user's business unit
    if (!this.state.businessUnitId) {
      delete data.businessUnitId
    }

    if (data.frequency === CampaignFrequency[values.channelType].ONCE) {
      delete data.endDate
    } else {
      if (!data.endDate) {
        actions.setErrors({
          endDate: 'End Date is required'
        })
        actions.setSubmitting(false)
        return;
      }
    }

    data.startDate = `${moment(data.startDate).format('YYYY-MM-DDTHH:mm:ss').toString()}Z`
    data.startTime = moment(data.startTime).format('HH:mm').toString()
    if (data.endDate) {
      data.endDate = `${moment(data.endDate).format('YYYY-MM-DDTHH:mm:ss').toString()}Z`
    }
    data.queryId = parseInt(data.queryId)
    this.setState({
      isLoading: true,
    });
    if (!values.id) {
      delete data.id;
      this.add(data, actions);
    } else {
      const notAllowedEdit = ['deleted', 'status', 'createdOn', 'modifiedOn', 'businessUnitId', 'name', 'ruleName', 'ruleArn', 's3Key']; // following properties are not allowed to edit
      notAllowedEdit.map(val => {
        return delete data[val]
      })
      this.update(data, actions);
    }
  };

  add = async (data, actions) => {
    try {
      await campaignAPI.add(data);
      const msg = 'Campaign added successfully';
      this.handleSuccess(msg);
    } catch (error) {
      this.handleFailure(error.response, actions)
    }
  }

  update = async (data, actions) => {
    const payload = {...data}
    const id = data.id
    delete payload.id
    try {
      await campaignAPI.update(id, payload);
      const msg = 'Campaign updated successfully';
      this.handleSuccess(msg);
    } catch (error) {
      this.handleFailure(error.response, actions)
    }
  };

  handleSuccess = (msg) => {
    showAlert(msg);
    this.redirect();
  }

  handleFailure = (error, actions) => {
    showAlert(formatError(error), 'error', true);
    if (actions) {
      actions.setSubmitting(false)
    }
    this.setState({
      isLoading: false,
    });
  }

  download = async () => {
    this.setState({
      isLoading: true
    });
    try {
      const res = await commonAPI.downloadFile(this.state.s3Key);
      this.setState({
        isLoading: false
      });
      window.location = res.data
    } catch (error) {
      this.handleFailure(error.response)
    }
  };

  redirect = () => {
    if (this.state.businessUnitId) {
      this.props.history.push(`/business-unit/${this.state.businessUnitId}/campaign`);
    } else {
      this.props.history.push('/campaign');
    }
  }

  setTemplate = (val, list) => {
    if (val === '') {
      this.setQueryNull()
    } else {
      list.map(query => {
        if (query.id === parseInt(val)) {
          let formattedSQL = ''
          let s3Key = ''
          if (query.type !== QueryType.ADHOC) {
            formattedSQL = createFormattedSQL(formatQuery(query.queryJson, 'sql'))
          } else {
            s3Key = query.s3Key
          }
          this.setState({
            templateName: query.template.name,
            templateBody: query.template.body,
            type: query.type,
            formattedSQL,
            s3Key
          })
        }
      })
    }
  }

  setQueryNull = () => {
    this.setState({
      templateName: '',
      templateBody: '',
      type: '',
      formattedSQL: '',
      s3Key: ''
    });
  }

  onStartDateChange = date => this.setState({startDate: date});

  render() {
    return (
      <PageLayout headerName={`${this.state.mode} Campaign`}>
        {this.state.isLoading ? <Loader/> : null}
        <Formik
          initialValues={this.state.campaign}
          onSubmit={(values, actions) => this.handleSubmit(values, actions)}
          validationSchema={campaignValidationSchema}
          enableReinitialize={true}
        >
          {prop => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              setFieldValue
            } = prop;
            return (
              <Form className='login-form' noValidate autoComplete='off'>
                {isSubmitting ? <Loader/> : null}
                <Row>
                  <Col lg={6}>
                    <FormGroup>
                      <Label className="text-muted" htmlFor="name">
                        Name<sup className="required">*</sup> (<b>Only alphanumeric, - & _ are allowed beginning with
                        alphabet</b>)
                      </Label>
                      <Field
                        name='name'
                        type='text'
                        placeholder='Name'
                        className='form-control'
                        required
                        value={values.name}
                        disabled={this.state.is_disabled}
                      />
                      {errors.name && touched.name ? (
                        <small className='error'>{errors.name}</small>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col lg={6}>
                    <FormGroup>
                      <Label className="text-muted" htmlFor="channelType">
                        Channel Type <sup className="required">*</sup>
                      </Label>
                      <Field as="select" name="channelType" required
                             value={values.channelType.toString()}
                             className='form-control' onChange={e => {
                        this.setQueryNull();
                        setFieldValue('queryId', '');
                        handleChange(e)
                      }}
                      >
                        <option value="">Select Channel Type</option>
                        {Object.keys(ChannelType).map(key => <option key={key} value={key}>{key}</option>)}
                      </Field>
                      {errors.channelType && touched.channelType ? (
                        <small className='error'>{errors.channelType}</small>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col lg={6}>
                    <FormGroup>
                      <Label className="text-muted" htmlFor="description">
                        Description
                      </Label>
                      <Field
                        name='description'
                        as='textarea'
                        placeholder='Description'
                        className='form-control'
                        value={values.description}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg={6}>
                    <FormGroup>
                      <Label className="text-muted" htmlFor="tps">
                        Maximum number of messages per second <b>(50-20,000)</b> <sup className="required">*</sup>
                      </Label>
                      <Field
                        name='tps'
                        type='number'
                        min='50'
                        max='20000'
                        placeholder='Maximum number of messages per second'
                        className='form-control'
                        value={values.tps}
                      />
                      {errors.tps && touched.tps ? (
                        <small className='error'>{errors.tps}</small>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col lg={6}>
                    <FormGroup>
                      <Label className="text-muted" htmlFor="query">
                        Query <sup className="required">*</sup>
                      </Label>
                      <Field as="select" name="queryId" required value={values.queryId.toString()}
                             className='form-control' onChange={e => {
                        this.setTemplate(e.target.value, this.state.queryList[values.channelType]);
                        handleChange(e)
                      }}
                      >
                        <option value="">Select Query</option>
                        {values.channelType && this.state.queryList[values.channelType].map(query => <option
                          key={query.id}
                          value={query.id}>{query.name}</option>)}
                      </Field>
                      {errors.queryId && touched.queryId ? (
                        <small className='error'>{errors.queryId}</small>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col lg={12}>
                    <FormGroup>
                      <Label className="text-muted" htmlFor="template">
                        <strong>{this.state.templateName}</strong> Preview
                      </Label>
                      <div className="rounded p-3 queryOutput table-style">
                        <Scrollbars autoHide style={{height: ChannelType.EMAIL === values.channelType ? 500 : 100}}>
                          <Preview html={this.state.templateBody}/>
                        </Scrollbars>
                      </div>
                    </FormGroup>
                  </Col>
                  <Col lg={6}>
                    <FormGroup>
                      <Label className="text-muted" htmlFor="template">
                        Query Output
                      </Label>
                      {this.state.type === QueryType.ADHOC ?
                        <div className="rounded p-3 queryOutput table-style">
                          ADHOC Customers -
                          <i
                            title='Download Customers'
                            className='fa fa-cloud-download btn text-primary pointer'
                            onClick={() => this.download()}
                          />
                        </div>
                        :
                        <div className="rounded p-3 queryOutput table-style addScroll">
                          <Preview html={this.state.formattedSQL}/>
                        </div>

                      }
                    </FormGroup>
                  </Col>
                  {values.channelType && <Col lg={6}>
                    <FormGroup>
                      <Label className="text-muted" htmlFor="frequency">
                        Frequency <sup className="required">*</sup>
                      </Label>
                      <Field as="select" name="frequency" required value={values.frequency} className='form-control'
                             onChange={e => {
                               this.setState({selectedFrequency: e.target.value});
                               handleChange(e)
                             }}
                      >
                        <option value="">Select Frequency</option>
                        {Object.entries(CampaignFrequency[values.channelType]).map((frequency) => <option
                          key={frequency[1]}
                          value={frequency[1]}>{frequency[1]}</option>)}
                      </Field>
                      {errors.frequency && touched.frequency ? (
                        <small className='error'>{errors.frequency}</small>
                      ) : null}
                    </FormGroup>
                  </Col>}
                  <Col lg={6}>
                    <FormGroup>
                      <Label className="text-muted" htmlFor="startDate">
                        Start Date <sup className="required">*</sup>
                      </Label>
                      <DatePicker
                        selected={values.startDate}
                        dateFormat="MM/dd/yyyy"
                        className="form-control"
                        name="startDate"
                        placeholder='Start Date of campaign'
                        onChange={date => setFieldValue('startDate', date)}
                      />
                      {errors.startDate && touched.startDate ? (
                        <small className='error'>{errors.startDate}</small>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col lg={6}>
                    <FormGroup>
                      <Label className="text-muted" htmlFor="startTime">
                        Start Time <sup className="required">*</sup>
                      </Label>
                      <DatePicker
                        selected={values.startTime}
                        dateFormat="hh:mm aa"
                        className="form-control"
                        name="startTime"
                        showTimeSelect
                        showTimeSelectOnly
                        placeholder='Start Time of campaign'
                        onChange={time => setFieldValue('startTime', time)}
                      />
                      {errors.startTime && touched.startTime ? (
                        <small className='error'>{errors.startTime}</small>
                      ) : null}
                    </FormGroup>
                  </Col>
                  {this.state.selectedFrequency !== 'once' &&
                  <Col lg={6}>
                    <FormGroup>
                      <Label className="text-muted" htmlFor="endDate">
                        End Date <sup className="required">*</sup>
                      </Label>
                      <DatePicker
                        selected={values.endDate}
                        dateFormat="MM/dd/yyyy"
                        className="form-control"
                        name="endDate"
                        placeholder='End Date of campaign'
                        onChange={date => setFieldValue('endDate', date)}
                      />
                      {errors.endDate && touched.endDate ? (
                        <small className='error'>{errors.endDate}</small>
                      ) : null}
                    </FormGroup>
                  </Col>
                  }
                  <Col lg={6}>
                    <FormGroup>
                      <Label className="text-muted" htmlFor="timeZone">
                        Timezone <sup className="required">*</sup>
                      </Label>
                      <Field as="select" name="timeZone" required value={values.timeZone} className='form-control'
                             onChange={e => handleChange(e)}
                      >
                        <option value="">Select Timezone</option>
                        {this.state.timeZoneList.map(timezone => <option key={timezone}
                                                                         value={timezone}>{timezone}</option>)}
                      </Field>
                      {errors.timeZone && touched.timeZone ? (
                        <small className='error'>{errors.timeZone}</small>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col sm={12} className="text-right">
                    <Button
                      className="mr-2"
                      color="light"
                      onClick={() => this.redirect()}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="success"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </PageLayout>
    );
  }
}

export default CampaignForm;

const campaignValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required')
    .matches(/^[a-zA-Z]([a-zA-Z0-9_-]+)*$/, 'Only alphanumeric, - & _ are allowed beginning with alphabet') //NOSONAR
    .min(3, 'Should be minimum of 3 characters')
    .nullable(),
  tps: Yup.number()
    .required('Max email value is required')
    .min(50, 'Minimum allowed value is 50')
    .max(20000, 'Maximum allowed value is 20,000'),
  queryId: Yup.number()
    .required('Query is required')
    .nullable(),
  frequency: Yup.string()
    .required('Frequency is required')
    .nullable(),
  startDate: Yup.string()
    .required('Start Date is required')
    .nullable(),
  startTime: Yup.string()
    .required('Start Time is required')
    .nullable(),
  timeZone: Yup.string()
    .required('Timezone is required')
    .nullable(),
  channelType: Yup.string()
    .required('Channel Type is required')
    .nullable(),
});
