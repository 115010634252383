import React, { Component } from 'react'; // NOSONAR
import {
    Label,
    Col,
    Row,
    FormGroup
} from 'reactstrap';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import PageLayout from '../Layout/PageLayout'
import ModalLayout from '../Layout/ModalLayout'
import consumerAPI from '../../api/consumer';
import businessUnitAPI from '../../api/business-unit';
import { showAlert, confirmAlert } from '../../components/Alert'
import { formatError } from '../../common/utils';
import DataTable from '../../components/DataTable'

import * as defaultConfig from '../../common/default-config.json'
const config = defaultConfig.default;

class Consumer extends Component {
    constructor(props) {
        super(props);
        let { businessUnitId } = this.props.match.params
        businessUnitId = (businessUnitId) ? parseInt(businessUnitId) : undefined
        this.state = {
            isLoading: false,
            modal: false,
            mode: 'Add',
            consumerList: [],
            totalDataSize: 0,
            businessUnitId,
            businessUnitName: '',
            consumer: {
                name: '',
                description: '',
                businessUnitId,
            },
            currentPage: 1,
            sortOrder: config.DEFAULT_SORTING,
            pagination: config.DEFAULT_PAGINATION,
        };
    }

    componentDidMount() {
        this.getTotalCount(this.state.businessUnitId);
        this.getData(this.state.businessUnitId);
    }

    onPageChange = (page, sizePerPage) => {
        this.setState({ currentPage: page })
        this.setState({ pagination: { offset: (page * sizePerPage) - sizePerPage, limit: sizePerPage } })
        this.getData(this.state.businessUnitId, undefined, {
            offset: page * sizePerPage - sizePerPage,
            limit: sizePerPage
        })
    };
    onSortChange = (sortName, sortOrder) => {
        this.setState({ sortOrder: `${sortName} ${sortOrder}` })
        this.getData(this.state.businessUnitId, `${sortName} ${sortOrder}`)
    };

    getTotalCount = async businessUnitId => {
        try {
            const res = await consumerAPI.getTotalCount(businessUnitId);
            this.setState({ totalDataSize: res.data.count })
        } catch (error) {
            this.handleFailure(error.response)
        }
    }

    getData = async (businessUnitId, sorting = this.state.sortOrder, pagination = this.state.pagination) => {
        this.setState({
            isLoading: true,
        });
        try {
            const res = await consumerAPI.get(businessUnitId, sorting, pagination);
            this.setState({ consumerList: res.data, isLoading: false })
            if (res.data.length > 0) {
                this.setState({ businessUnitId: res.data[0]?.businessUnit?.id, businessUnitName: res.data[0]?.businessUnit?.name })
            } else {
                if (!this.state.businessUnitName) {
                    try {
                        const response = await businessUnitAPI.getById(businessUnitId);
                        this.setState({ businessUnitId, businessUnitName: response.data.name })
                    } catch (error) {
                        this.handleFailure(error.response)
                    }
                }
            }
        } catch (error) {
            this.handleFailure(error.response)
        }
    };

    toggle = () => {
        this.setState({
            modal: !this.state.modal,
        });
    };

    handleDelete = async consumer => {
        const confirm = await confirmAlert('Are you sure to delete this consumer?');
        if (confirm) {
            this.setState({
                isLoading: true,
            });
            try {
                await consumerAPI.deleteConsumer(consumer.id);
                const msg = 'Consumer deleted successfully';
                this.handleSuccess(msg);
                this.getTotalCount(consumer.businessUnitId)
                this.getData(consumer.businessUnitId)
            } catch (error) {
                this.handleFailure(error.response)
            }
        }
    };

    handleSubmit = async (values, actions) => {
        this.setState({
            isLoading: true,
        });
        try {
            await consumerAPI.add(values);
            const msg = 'Consumer added successfully';
            this.handleSuccess(msg, actions);
            this.getTotalCount(values.businessUnitId)
        } catch (error) {
            this.handleFailure(error.response, actions)
        }
    };

    handleSuccess = (msg, actions) => {
        showAlert(msg);
        (actions) ? this.resetState(actions.resetForm) : this.resetState();
        this.getData(this.state.businessUnitId);
    }

    handleFailure = (error, actions) => {
        showAlert(formatError(error), 'error', true);
        if (actions) {
            actions.setSubmitting(false)
        }
        this.setState({
            isLoading: false,
        });
    }

    resetState = (resetForm) => {
        if (resetForm) {
            resetForm();
        }
        this.setState({
            modal: false,
            consumer: {
                name: '',
                description: '',
                businessUnitId: this.state.consumer.businessUnitId
            },
            is_disabled: false
        });
    }

    actionFieldFormat = (cell, row) => {
        return (
            <div role='group' className='btn-group-none'>
                <i
                    title='Delete Consumer'
                    className='btn btn-danger m-1 fa fa-remove'
                    onClick={() => this.handleDelete(row)}
                />
            </div>
        )
    }

    render() {
        const gridColumns = [
            { dataField: 'name', text: 'Name', sort: true },
            { dataField: 'description', text: 'Description' },
            { dataField: 'clientId', text: 'Client ID' },
            { dataField: 'clientSecret', text: 'Secret' },
            { dataField: 'action', text: 'Action', formatter: this.actionFieldFormat }
        ]
        return (
            <PageLayout allowAddForm toggleModal={this.toggle} headerName={`${this.state.businessUnitName} Consumers`}>
                <DataTable
                    isLoading={this.state.isLoading}
                    data={this.state.consumerList}
                    columns={gridColumns}
                    key={'id'}
                    onSortChange={(sortName, sortOrder) => this.onSortChange(sortName, sortOrder)}
                    currentPage={this.state.currentPage}
                    totalDataSize={this.state.totalDataSize}
                    onPageChange={(page, sizePerPage) => this.onPageChange(page, sizePerPage)}
                />
                <Formik
                    initialValues={this.state.consumer}
                    onSubmit={(values, actions) => this.handleSubmit(values, actions)}
                    validationSchema={consumerValidationSchema}
                    enableReinitialize={true}
                >
                    {prop => {
                        const {
                            values,
                            touched,
                            errors,
                            isSubmitting,
                            resetForm,
                        } = prop;
                        return (
                            <ModalLayout
                                mode={this.state.mode}
                                isOpen={this.state.modal}
                                toggle={this.resetState}
                                values={values}
                                touched={touched}
                                errors={errors}
                                isSubmitting={isSubmitting}
                                resetForm={resetForm}
                                resetState={this.resetState}
                                headerText="Consumer"
                                {...this.props}
                            >
                                <Row>
                                    <Col sm={12}>
                                        <FormGroup>
                                            <Label className="text-muted" htmlFor="name">
                                                Name <sup className="required">*</sup>
                                            </Label>
                                            <Field
                                                name='name'
                                                type='text'
                                                placeholder='Name'
                                                className='form-control'
                                                required
                                                value={values.name}
                                            />
                                            {errors.name && touched.name ? (
                                                <small className='error'>{errors.name}</small>
                                            ) : null}
                                        </FormGroup>
                                    </Col>
                                    <Col sm={12}>
                                        <FormGroup>
                                            <Label className="text-muted" htmlFor="description">
                                                Description
                                                    </Label>
                                            <Field
                                                name='description'
                                                as='textarea'
                                                placeholder='Description'
                                                className='form-control'
                                                value={values.description}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </ModalLayout>
                        );
                    }}
                </Formik>
            </PageLayout>
        );
    }
}

const consumerValidationSchema = Yup.object().shape({
    name: Yup.string()
        .matches(/^[A-Za-z ]+$/, 'enter valid name')
        .required('Name is required')
        .min(3, 'Should be minimum of 3 characters')
        .nullable(),
    description: Yup.string().nullable(),
});

export default Consumer;
