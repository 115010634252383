import axiosInstance from '../../api'

const baseURL = 'campaigns'

const add = async payload => await axiosInstance.post(baseURL, payload)

const update = async (id, payload) => await axiosInstance.patch(`${baseURL}/${id}`, payload)

const get = async (where, sorting, pagination) => {
  const query = {
    include: [
      {
        relation: 'query'
      }
    ],
    where
  };
  if (sorting) {
    query.order = sorting;
  }
  if (pagination) {
    query.offset = pagination.offset;
    query.limit = pagination.limit;
  }
  const filter = JSON.stringify(query);
  return await axiosInstance.get(baseURL, {params: {filter}})
};

const getTotalCount = async where => await axiosInstance.get(`${baseURL}/count`, {params: {where}})

const getById = async id => {
  const query = {
    include: [
      {
        relation: 'query',
      }
    ]
  };
  const filter = JSON.stringify(query);
  return await axiosInstance.get(`${baseURL}/${id}`, {params: {filter}})
};

const getTimezones = async () => await axiosInstance.get(`timezones`)

const campaignAPI = {
  add,
  update,
  get,
  getTotalCount,
  getById,
  getTimezones
};

export default campaignAPI;
