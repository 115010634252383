import React, { Component } from 'react'; // NOSONAR
import {
    Col,
    Row,
    FormGroup,
    Label,
} from 'reactstrap';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import PageLayout from '../Layout/PageLayout'
import ModalLayout from '../Layout/ModalLayout'
import businessUnitAPI from '../../api/business-unit';
import { showAlert } from '../../components/Alert'
import { formatError } from '../../common/utils';
import DataTable from '../../components/DataTable'

import * as defaultConfig from '../../common/default-config.json'
const config = defaultConfig.default;

class BusinessUnit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            modal: false,
            is_disabled: false,
            mode: 'Add',
            businessUnitsList: [],
            totalDataSize: 0,
            businessUnit: {
                id: NaN,
                name: '',
                description: '',
            },
            currentPage: 1,
            sortOrder: config.DEFAULT_SORTING,
            pagination: config.DEFAULT_PAGINATION,
        };
    }

    componentDidMount() {
        this.getTotalCount();
        this.getData();
    }

    onPageChange = (page, sizePerPage) => {
        this.setState({ currentPage: page })
        this.setState({ pagination: { offset: (page * sizePerPage) - sizePerPage, limit: sizePerPage } })
        this.getData(undefined, {
            offset: page * sizePerPage - sizePerPage,
            limit: sizePerPage
        })
    };
    onSortChange = (sortName, sortOrder) => {
        this.setState({ sortOrder: `${sortName} ${sortOrder}` })
        this.getData(`${sortName} ${sortOrder}`)
    };

    toggle = () => {
        this.setState({
            modal: !this.state.modal,
        });
    };

    handleUpdate = (businessUnit) => {
        this.toggle();
        this.setState({
            mode: 'Edit',
            is_disabled: true,
            businessUnit: {
                id: businessUnit.id,
                name: businessUnit.name,
                description: businessUnit.description,
            }
        });
    };

    handleSubmit = (values, actions) => {
        const data = {
            ...values,
        };
        this.setState({
            isLoading: true,
        });
        if (!values.id) {
            delete data.id;
            this.add(data, actions);
        } else {
            delete data.name // name is not allowed to edit
            this.update(data, actions);
        }
    };

    add = async (data, actions) => {
        try {
            await businessUnitAPI.add(data);
            const msg = 'Business Unit added successfully';
            this.handleSuccess(msg, actions);
            this.getTotalCount()
        } catch (error) {
            this.handleFailure(error.response, actions)
        }
    }

    update = async (data, actions) => {
        const payload = { ...data }
        const id = data.id
        delete payload.id
        try {
            await businessUnitAPI.update(id, payload);
            const msg = 'Business Unit updated successfully';
            this.handleSuccess(msg, actions);
        } catch (error) {
            this.handleFailure(error.response, actions)
        }
    };

    handleSuccess = (msg, actions) => {
        showAlert(msg);
        this.resetState(actions.resetForm);
        this.getData();
    }

    handleFailure = (error, actions) => {
        showAlert(formatError(error), 'error', true);
        if (actions) {
            actions.setSubmitting(false)
        }
        this.setState({
            isLoading: false,
        });
    }

    getTotalCount = async () => {
        try {
            const res = await businessUnitAPI.getTotalCount();
            this.setState({ totalDataSize: res.data.count })
        } catch (error) {
            this.handleFailure(error.response)
        }
    }

    getData = async (sorting = this.state.sortOrder, pagination = this.state.pagination) => {
        this.setState({
            isLoading: true,
        });
        try {
            const res = await businessUnitAPI.get(sorting, pagination);
            this.setState({ businessUnitsList: res.data, isLoading: false })
        } catch (error) {
            this.handleFailure(error.response)
        }
    };

    resetState = (resetForm) => {
        if (resetForm) {
            resetForm();
        }
        this.setState({
            isLoading: false,
            modal: false,
            businessUnit: {
                id: NaN,
                name: '',
                description: '',
            },
            is_disabled: false,
            mode: 'Add',
        });
    }

    actionFieldFormat = (cell, row) => {
        return (
            <div role='group' className='btn-group-none'>
                <i
                    title='Edit'
                    className='btn text-primary pointer mr-1 icon-pencil'
                    onClick={() => this.handleUpdate(row)}
                />
                <i
                    title='View Consumers'
                    className='btn text-primary pointer mr-1 icon-user'
                    onClick={() => this.props.history.push(`/business-unit/${row.id}/consumer`)}
                />
                <i
                    title='View Campaigns'
                    className='btn text-primary pointer mr-1 fa fa-bullhorn'
                    onClick={() => this.props.history.push(`/business-unit/${row.id}/campaign`)}
                />
                <i
                    title='View Queries'
                    className='btn text-primary pointer mr-1 fa fa-question-circle'
                    onClick={() => this.props.history.push(`/business-unit/${row.id}/query`)}
                />
                <i
                    title='View Templates'
                    className='btn text-primary pointer fa fa-envelope'
                    onClick={() => this.props.history.push(`/business-unit/${row.id}/template`)}
                />
                <i
                    title='View Tables'
                    className='btn text-primary pointer fa fa-table'
                    onClick={() => this.props.history.push(`/business-unit/${row.id}/customTable`)}
                />
                 <i
                    title='Pinpoint Project Config'
                    className='btn text-primary pointer mr-1 fa fa-cogs'
                    onClick={() => this.props.history.push(`/business-unit/${row.id}/templateConfig`)}
                />

                <i
                  title='SMS Demo'
                  className='btn text-primary pointer mr-1 fa fa-commenting-o'
                  onClick={() => this.props.history.push(`/business-unit/${row.id}/smsDemo`)}
                />
            </div>
        )
    }

    render() {
        const gridColumns = [
            { dataField: 'name', text: 'Name', sort: true },
            { dataField: 'description', text: 'description' },
            { dataField: 'action', text: 'Action', formatter: this.actionFieldFormat }
        ]
        return (
            <PageLayout allowAddForm toggleModal={this.toggle} headerName='Manage Business Units'>
                <DataTable
                    isLoading={this.state.isLoading}
                    data={this.state.businessUnitsList}
                    columns={gridColumns}
                    key={'id'}
                    onSortChange={(sortName, sortOrder) => this.onSortChange(sortName, sortOrder)}
                    currentPage={this.state.currentPage}
                    totalDataSize={this.state.totalDataSize}
                    onPageChange={(page, sizePerPage) => this.onPageChange(page, sizePerPage)}
                />
                <Formik
                    initialValues={this.state.businessUnit}
                    onSubmit={(values, actions) => this.handleSubmit(values, actions)}
                    validationSchema={businessUnitValidationSchema}
                    enableReinitialize={true}
                >
                    {prop => {
                        const {
                            values,
                            touched,
                            errors,
                            isSubmitting,
                            resetForm,
                        } = prop;
                        return (
                            <ModalLayout
                                mode={this.state.mode}
                                isOpen={this.state.modal}
                                toggle={this.resetState}
                                values={values}
                                touched={touched}
                                errors={errors}
                                isSubmitting={isSubmitting}
                                resetForm={resetForm}
                                resetState={this.resetState}
                                headerText="Business Unit"
                                {...this.props}
                            >
                                <Row>
                                    <Col sm={12}>
                                        <FormGroup>
                                            <Label className="text-muted" htmlFor="name">
                                                Name <sup className="required">*</sup>
                                            </Label>
                                            <Field
                                                name='name'
                                                type='text'
                                                placeholder='Name'
                                                className='form-control'
                                                required
                                                value={values.name}
                                                disabled={this.state.is_disabled}
                                            />
                                            {errors.name && touched.name ? (
                                                <small className='error'>{errors.name}</small>
                                            ) : null}
                                        </FormGroup>
                                    </Col>
                                    <Col sm={12}>
                                        <FormGroup>
                                            <Label className="text-muted" htmlFor="description">
                                                Description
                                                    </Label>
                                            <Field
                                                name='description'
                                                as='textarea'
                                                placeholder='Description'
                                                className='form-control'
                                                value={values.description}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </ModalLayout>
                        );
                    }}
                </Formik>
            </PageLayout>
        );
    }
}

export default BusinessUnit;

const businessUnitValidationSchema = Yup.object().shape({
    name: Yup.string()
        .required('Name is required')
        .min(3, 'Should be minimum of 3 characters')
        .nullable(),
});
