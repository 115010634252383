// components/Loader.jsx
import React, {Component} from 'react'

class Loader extends Component {
  render() {
    return (
      <div className='page-preloader-cover d-flex align-items-center justify-content-center'>
         <div className="cst-loader"></div>
      </div>
    )
  }
}

export default Loader
