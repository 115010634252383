import axiosInstance from '../../api'

const baseURL = 'associated-tables'

const add = async payload => await axiosInstance.post(baseURL, payload)

const update = async (id, payload) => await axiosInstance.patch(`${baseURL}/${id}`, payload)

const get = async (businessUnitId, sorting, pagination) => {
  const query = { where: { businessUnitId } };
  if (sorting) {
    query.order = sorting;
  }
  if (pagination) {
    query.offset = pagination.offset;
    query.limit = pagination.limit;
  }
  const filter = JSON.stringify(query);
  return await axiosInstance.get(baseURL, { params: { filter } })
};

const getTotalCount = async businessUnitId => await axiosInstance.get(`${baseURL}/count`, { params: { where: JSON.stringify({ businessUnitId }) } })

const getById = async id => await axiosInstance.get(`${baseURL}/${id}`)

const customTableAPI = {
  add,
  update,
  get,
  getTotalCount,
  getById
};

export default customTableAPI;
