import React from 'react';
import { Modal, ModalBody } from 'reactstrap';

function ModalEditorLayout(props) {
    return (
        <Modal
            isOpen={props.isOpen}
            style={{ minWidth: 1100 }}
            toggle={() => {
                props.toggle()
            }}
            backdrop={true}
        >
            <ModalBody>{props.children}</ModalBody>
        </Modal>
    );
}
export default ModalEditorLayout;