import React, {Component} from 'react'; // NOSONAR
import {HashRouter, Redirect, Route, Switch} from 'react-router-dom';
import './App.scss';
import LocalStorage, {LocalDataKeys} from './services/local-storage';
import Main from './views/Main';
import Login from './views/Auth/Login';
import SetPassword from './views/Auth/SetPassword';
import ForgotPassword from './views/Auth/ForgotPassword'
import Loader from './components/Loader';
import FullStory from 'react-fullstory';
import * as defaultConfig from './common/default-config.json'

const config = defaultConfig.default;

const loading = () => <Loader />;

const ProtectedRoute = // NOSONAR
  ({ ...props }) => {
    if (LocalStorage.manipulateStorage(LocalDataKeys.accessToken, 'get')) {
      return <Route {...props} />;
    } else {
      return <Redirect to="/login" />;
    }
  }

class App extends Component {

  render() {
    return (
      <div className='app-container'>
        {(process.env.REACT_APP_ENV === config.FULLSTORY.env) && <FullStory org={config.FULLSTORY.org} />}
        <HashRouter>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route exact path="/forgot-password" name="Login Page" render={props => <ForgotPassword {...props} />} />
              <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
              <Route exact path="/set-password/" name="Password Page" render={props => <SetPassword type="new" {...props} />} />
              <Route exact path="/reset-password/" name="Reset Password Page" render={props => <SetPassword type="reset" {...props} />} />
              <ProtectedRoute path='/' name='Home' component={Main} />
            </Switch>
          </React.Suspense>
        </HashRouter>
      </div>
    );
  }
}

export default App;
