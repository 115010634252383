import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/stable';
// import 'react-app-polyfill/ie11'; // For IE 11 support
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import {HashRouter, Route, Switch} from 'react-router-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

ReactDOM.render(<HashRouter>
  <React.Suspense fallback={loading()}>
    <Switch>
      <Route path="/" name="Home" render={props => <App {...props}/>}/>
    </Switch>
  </React.Suspense>
</HashRouter>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
