import React, { Component } from 'react'; // NOSONAR
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
} from 'reactstrap';
import { Form } from 'formik';
import Loader from '../../components/Loader';

function ModalLayout(props) {
  return (
    <Modal
      backdrop={true}
      isOpen={props.isOpen}
      toggle={() => props.resetState(props.resetForm)}
      className={props.className}
    >
      <ModalHeader
        className="text-primary"
        toggle={() => props.resetState(props.resetForm)}
      >
        {props.mode} {props.headerText}
                                    </ModalHeader>
      <Form className='login-form' noValidate autoComplete='off'>
        {props.isSubmitting ? <Loader /> : null}
        <ModalBody>
          {props.children}
        </ModalBody>
        <ModalFooter>
          <Button
            color="light"
            onClick={() => props.resetState(props.resetForm)}
          >
            Cancel
          </Button>
          <Button
            color="success"
            type="submit"
            disabled={props.isSubmitting}
          >
            Save
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}

export default ModalLayout;
