import React, {Component} from 'react'; // NOSONAR
import PageLayout from '../Layout/PageLayout'
import queryAPI from '../../api/query';
import commonAPI from '../../api/common';
import {showAlert} from '../../components/Alert';
import {formatError} from '../../common/utils';
import DataTable from '../../components/DataTable';
import QueryType from '../../common/query-type';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap'
import {Form, Formik} from 'formik'
import * as _ from 'lodash'
import businessUnitAPI from '../../api/business-unit'
import * as defaultConfig from '../../common/default-config.json'
import Chips from 'react-chips'
import QueryFilter from "./QueryFilter";

const formikInitiaValues = {
  fields: []
}
const tableName = 'customer';

class Query extends Component {
  constructor(props) {
    super(props);
    let {businessUnitId} = this.props.match.params
    businessUnitId = (businessUnitId) ? parseInt(businessUnitId) : undefined

    this.state = {

      businessUnitId,
      where: {businessUnitId},
      isLoading: false,
      queryList: [],

      //pagination
      totalDataSize: 0,
      currentPage: 1,
      sortOrder: defaultConfig.DEFAULT_SORTING,
      pagination: defaultConfig.DEFAULT_PAGINATION,
      modal: false,
      fields: null,
      exportCSVQueryId: null,
    };
  }

  componentDidMount() {
    this.getData();
    this.getTableConfig()
  }

  onPageChange = (page, sizePerPage) => {
    this.setState({currentPage: page})
    this.setState({pagination: {offset: (page * sizePerPage) - sizePerPage, limit: sizePerPage}})
    this.getData(undefined, {
      offset: page * sizePerPage - sizePerPage,
      limit: sizePerPage
    })
  };
  onSortChange = (sortName, sortOrder) => {
    this.setState({sortOrder: `${sortName} ${sortOrder}`})
    this.getData(`${sortName} ${sortOrder}`)
  };

  handleFailure = (error, actions) => {
    showAlert(formatError(error), 'error', true);
    if (actions) {
      actions.setSubmitting(false)
    }
    this.setState({
      isLoading: false,
    });
  }

  getData = (sorting = this.state.sortOrder, pagination = this.state.pagination) => {
    return this.getQueryData(this.state.where, sorting, pagination);
  }

  /**
   * method passed to child component QueryFilter to handle data fetching
   * @param where
   * @returns {Promise<void>}
   */
  getFilteredData = (where = {}) => {
    where["businessUnitId"] = this.state.businessUnitId;
    this.setState({where});
    return this.getQueryData(where, null, null);
  };

  getQueryData = async (where = this.state.where, sorting = this.state.sortOrder, pagination = this.state.pagination) => {
    this.setState({
      isLoading: true,
    });

    await this.getTotalCount(where);
    try {

      const res = await queryAPI.get(where, sorting, pagination, {});
      this.setState({queryList: res.data, isLoading: false})

    } catch (error) {
      this.handleFailure(error.response)
    }
  }
  getTotalCount = async (where) => {
    try {
      const res = await queryAPI.getTotalCount(where);
      this.setState({totalDataSize: res.data.count})
    } catch (error) {
      this.handleFailure(error.response)
    }
  }

  download = async key => {
    this.setState({
      isLoading: true
    });
    try {
      const res = await commonAPI.downloadFile(key);
      this.setState({
        isLoading: false
      });
      window.location = res.data
    } catch (error) {
      this.handleFailure(error.response)
    }
  };

  getQueryCount = async id => {
    this.setState({
      isLoading: true
    });
    try {
      const res = await queryAPI.getQueryCount(id);
      this.setState({
        isLoading: false
      });
      (res.data.count <= 0) ? showAlert(`No customer for this query`, 'error') : showAlert(`Total customers for this query are: ${res.data.count}`);
    } catch (error) {
      this.handleFailure(error.response)
    }
  };

  toggle = () => {
    this.setState({modal: false})
  }
  setQueryIdForExportCSV = (id) => {
    this.setState({exportCSVQueryId: id})
  }
  actionFieldFormat = (cell, row) => {
    let editURL = `/addEditQuery/${row.id}`;
    if (this.state.businessUnitId) {
      editURL = `/business-unit/${this.state.businessUnitId}/addEditQuery/${row.id}`;
    }
    return (
      <div role='group' className='btn-group-none'>
        <i
          title='Edit'
          className='btn text-primary pointer mr-1 icon-pencil'
          onClick={() => this.props.history.push(`${editURL}`)}
        />
        {(row.type === QueryType.ADHOC)
          ?
          <i
            title='Download Customers'
            className='fa fa-cloud-download btn text-primary pointer'
            onClick={() => this.download(row.s3Key)}
          />
          :
          <>
            <i
              title='Get Query Count'
              className='fa fa-calculator btn text-primary pointer'
              onClick={() => this.getQueryCount(row.id)}
            />
            <i
              title='Download Customers'
              className='fa fa-cloud-download btn text-primary pointer'
              onClick={() => {
                this.setQueryIdForExportCSV(row.id)
                this.setState({modal: true})
              }}
            />
          </>
        }
      </div>
    )
  }

  templateFieldFormat = (cell, row) => row.template.name
  getTableConfig = async () => {
    const res = await businessUnitAPI.getTableConfig(this.state.businessUnitId, tableName)
    const sortedFieldNames = _.sortBy(Object.values(res.data[0].modelDefinition).map(el => el.postgresql.columnName))
    this.setState({fields: sortedFieldNames})
  }

  csvDownload = async (queryId, values) => {
    this.setState({isLoading: true})
    try {
      const res = await queryAPI.exportCSV(queryId, values)
      this.setState({isLoading: false})
      window.location = res.data
    } catch (error) {
      this.handleFailure(error.response)
    }
  }

  handleSubmit = async (values) => {
    await this.csvDownload(this.state.exportCSVQueryId, values)
  }

  render() {
    const {fields} = this.state
    const gridColumns = [
      {dataField: 'name', text: 'Name', sort: true},
      {dataField: 'channelType', text: 'Channel Type'},
      {dataField: 'template', text: 'Template', formatter: this.templateFieldFormat},
      {dataField: 'action', text: 'Action', formatter: this.actionFieldFormat}
    ]
    let formUrl = "/addEditQuery";
    if (this.state.businessUnitId) {
      formUrl = `/business-unit/${this.state.businessUnitId}/addEditQuery`;
    }

    return (
      <PageLayout allowAddForm formUrl={formUrl} headerName='Manage Queries'>
        <Formik
          initialValues={formikInitiaValues}
          onSubmit={(values, actions) => this.handleSubmit(values, actions)}
          enableReinitialize={true}
        >
          {
            props => {
              const {
                values,
                handleReset,
                setValues,
                isSubmitting
              } = props

              return (
                <Modal
                  isOpen={this.state.modal}
                  toggle={() => {
                    handleReset()
                    this.toggle()
                  }}
                  backdrop={true}

                >
                  <ModalHeader
                    className="text-primary"
                  >
                    Download File
                  </ModalHeader>
                  <ModalBody
                    style={{minHeight: 300}}>
                    <Form className="login-form">
                      <Container>

                        <Row>
                          <Col lg={6} xs={6} sm={6} md={6}>
                            <FormGroup>
                              <Card>
                                <Container>
                                  <ul className="list-group" style={{height: 300, overflow: 'auto'}}>
                                    {fields && fields.map(el => {

                                      return (
                                        <li key={el} className="list-group-item border-0">
                                          <div className='form-checked'>
                                            <Input
                                              id={`input-${el}`}
                                              className='form-checked-input'
                                              name='el'
                                              type='checkbox'
                                              key={el}
                                              value={el}
                                              checked={values.fields.includes(el)}
                                              onChange={(e) => {
                                                if (e.target.checked) {
                                                  setValues({
                                                    fields: [...values.fields, e.target.value]
                                                  })
                                                }

                                                if (!e.target.checked) {
                                                  setValues({
                                                    fields: [...values.fields.filter(element => element !== e.target.value)]
                                                  })
                                                }
                                              }}
                                            >
                                            </Input>
                                            <label htmlFor={`input-${el}`} className='form-check-label'>
                                              {el}
                                            </label>
                                          </div>
                                        </li>
                                      )
                                    })
                                    }
                                  </ul>
                                </Container>
                              </Card>
                            </FormGroup>
                          </Col>
                          <Col lg={6} xs={6} sm={6} md={6}>
                            <FormGroup>
                              <Card style={{height: 300}}>
                                <CardBody>
                                  <Chips
                                    value={values.fields}
                                    onChange={(chips) => {
                                      setValues({
                                        fields: [...chips]
                                      })
                                    }}
                                  />
                                </CardBody>
                              </Card>
                            </FormGroup>
                          </Col>
                        </Row>
                        <ModalFooter>
                          <Button
                            color="light"
                            onClick={(e) => {
                              handleReset(e)
                              this.toggle()
                            }
                            }
                          >
                            Cancel
                          </Button>

                          <Button
                            color="success"
                            type="submit"
                            disabled={isSubmitting}
                          >
                            Download
                          </Button>
                        </ModalFooter>
                      </Container>
                    </Form>
                  </ModalBody>
                </Modal>
              )

            }

          }
        </Formik>
        <QueryFilter getFilteredData={this.getFilteredData.bind(this)}/>
        <DataTable
          isLoading={this.state.isLoading}
          data={this.state.queryList}
          columns={gridColumns}
          key={'id'}
          onSortChange={(sortName, sortOrder) => this.onSortChange(sortName, sortOrder)}
          currentPage={this.state.currentPage}
          totalDataSize={this.state.totalDataSize}
          onPageChange={(page, sizePerPage) => this.onPageChange(page, sizePerPage)}
        />
      </PageLayout>
    );
  }
}

export default Query;
