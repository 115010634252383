import axiosInstance from '../../api/index';

const login = async payload => await axiosInstance.post('/auth/login-token', payload)

const me = async () => await axiosInstance.get('/auth/me')

const checkToken = async config => await axiosInstance.get(`users/check-token`, config)

const authAPI = {
    login,
    me,
    checkToken,
};
export default authAPI;
