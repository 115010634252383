import axiosInstance from '../../api'

const baseURL = 'consumers'

const add = async payload => await axiosInstance.post(baseURL, payload)

const deleteConsumer = async id => await axiosInstance.delete(`consumers/${id}`)

const get = async (businessUnitId, sorting, pagination) => {
  const query = { where: { businessUnitId }, include: [{ relation: "businessUnit" }] };
  if (sorting) {
    query.order = sorting;
  }
  if (pagination) {
    query.offset = pagination.offset;
    query.limit = pagination.limit;
  }
  const filter = JSON.stringify(query);
  return await axiosInstance.get(baseURL, { params: { filter } })
};

const getTotalCount = async businessUnitId => await axiosInstance.get(`${baseURL}/count`, { params: { where: JSON.stringify({ businessUnitId }) } })

const consumerAPI = {
  add,
  get,
  deleteConsumer,
  getTotalCount
};

export default consumerAPI;
