import React, {Component} from 'react'; // NOSONAR
import PageLayout from '../Layout/PageLayout'
import templateAPI from '../../api/template';
import commonAPI from '../../api/common';
import {confirmAlert, showAlert} from '../../components/Alert';
import {formatError} from '../../common/utils';
import DataTable from '../../components/DataTable';
import DefaultStatus from '../../common/default-status'
import TemplateFilter from "./TemplateFilter";

import * as defaultConfig from '../../common/default-config.json'

class Template extends Component {
  constructor(props) {
    super(props);
    let {businessUnitId} = this.props.match.params
    businessUnitId = (businessUnitId) ? parseInt(businessUnitId) : undefined
    this.state = {

      businessUnitId,
      where: {businessUnitId},
      templateList: [],

      isLoading: false,
      mode: 'Add',

      //pagination
      totalDataSize: 0,
      currentPage: 1,
      sortOrder: defaultConfig.DEFAULT_SORTING,
      pagination: defaultConfig.DEFAULT_PAGINATION,
    };
  }

  componentDidMount() {
    this.getData();
  }

  onPageChange = (page, sizePerPage) => {
    this.setState({currentPage: page})
    this.setState({pagination: {offset: (page * sizePerPage) - sizePerPage, limit: sizePerPage}})
    this.getData(undefined, {
      offset: page * sizePerPage - sizePerPage,
      limit: sizePerPage
    })
  };

  onSortChange = (sortName, sortOrder) => {
    this.setState({sortOrder: `${sortName} ${sortOrder}`})
    this.getData(`${sortName} ${sortOrder}`)
  };

  download = async key => {
    this.setState({
      isLoading: true
    });
    try {
      const res = await commonAPI.downloadFile(key);
      this.setState({
        isLoading: false
      });
      window.location = res.data
    } catch (error) {
      this.handleFailure(error.response)
    }
  };

  changeStatus = async (id, status) => {
    const text = (status === 'active') ? DefaultStatus.INACTIVE : DefaultStatus.ACTIVE
    const confirm = await confirmAlert(`Are you sure to mark this template as ${text}?`);
    if (confirm) {
      this.setState({
        isLoading: true
      });
      try {
        await templateAPI.changeStatus(id);
        const msg = 'Status changed successfully';
        this.handleSuccess(msg);
      } catch (error) {
        this.handleFailure(error.response)
      }
    }
  };

  handleSuccess = (msg, actions) => {
    showAlert(msg);
    if (actions) {
      this.resetState(actions.resetForm);
    }
    this.getData();
  }

  handleFailure = (error, actions) => {
    showAlert(formatError(error), 'error', true);
    if (actions) {
      actions.setSubmitting(false)
    }
    this.setState({
      isLoading: false,
    });
  }

  getData = (sorting = this.state.sortOrder, pagination = this.state.pagination) => {
    return this.getTemplateData(this.state.where, sorting, pagination);
  };

  /**
   * method passed to child component TemplateFilter to handle data fetching
   * @param where
   * @returns {Promise<void>}
   */
  getFilteredData = (where = {}) => {
    where["businessUnitId"] = this.state.businessUnitId;
    this.setState({where});
    return this.getTemplateData(where, null, null);
  };

  getTemplateData = async (where = this.state.where, sorting = this.state.sortOrder, pagination = this.state.pagination) => {
    this.setState({
      isLoading: true,
    });

    await this.getTotalCount(where);
    try {
      const res = await templateAPI.get(where, sorting, pagination);
      this.setState({templateList: res.data, isLoading: false});
    } catch (error) {
      this.handleFailure(error.response)
    }
  };

  getTotalCount = async (where) => {
    try {
      const res = await templateAPI.getTotalCount(where);
      this.setState({totalDataSize: res.data.count})
    } catch (error) {
      this.handleFailure(error.response)
    }
  }

  actionFieldFormat = (cell, row) => {
    let editURL = `/addEditTemplate/${row.id}`;
    if (this.state.businessUnitId) {
      editURL = `/business-unit/${this.state.businessUnitId}/addEditTemplate/${row.id}`;
    }
    return (
      <div role='group' className='btn-group-none'>
        <i
          title='Edit'
          className='btn text-primary pointer mr-1 icon-pencil'
          onClick={() => this.props.history.push(`${editURL}`)}
        />
        <i
          title='Download'
          className='fa fa-cloud-download btn text-primary pointer mr-1'
          onClick={() => this.download(row.key)}
        />
        {(row.status === DefaultStatus.ACTIVE)
          ?
          <i
            title='Make it Inactive'
            className='fa fa-ban btn text-primary pointer'
            onClick={() => this.changeStatus(row.id, row.status)}
          />
          :
          <i
            title='Make it Active'
            className='fa fa-check-square btn text-primary pointer'
            onClick={() => this.changeStatus(row.id, row.status)}
          />
        }
      </div>
    )
  }

  templateTypeFieldFormat = (cell, row) => {
    if (row.types) {
      return row.types.name
    }
  }

  subscriptionTypeFieldFormat = (cell, row) => row.subscriptionTypes.name

  render() {
    const gridColumns = [
      {dataField: 'name', text: 'Name', sort: true},
      {dataField: 'subject', text: 'Subject'},
      {dataField: 'channelType', text: 'Channel Type'},
      {dataField: 'type', text: 'Pinpoint Config', formatter: this.templateTypeFieldFormat},
      {dataField: 'subscriptionType', text: 'Subscription Type', formatter: this.subscriptionTypeFieldFormat},
      {dataField: 'status', text: 'Status', sort: true},
      {dataField: 'action', text: 'Action', formatter: this.actionFieldFormat}
    ]
    let formUrl = "/addEditTemplate";
    if (this.state.businessUnitId) {
      formUrl = `/business-unit/${this.state.businessUnitId}/addEditTemplate`;
    }
    return (
      <PageLayout allowAddForm formUrl={formUrl} headerName='Manage Templates'>
        <TemplateFilter getFilteredData={this.getFilteredData.bind(this)}/>
        <DataTable
          isLoading={this.state.isLoading}
          data={this.state.templateList}
          columns={gridColumns}
          key={'id'}
          onSortChange={(sortName, sortOrder) => this.onSortChange(sortName, sortOrder)}
          currentPage={this.state.currentPage}
          totalDataSize={this.state.totalDataSize}
          onPageChange={(page, sizePerPage) => this.onPageChange(page, sizePerPage)}
        />
      </PageLayout>
    );
  }
}

export default Template;
