import React, { Component } from 'react'; // NOSONAR
import { Button, Col, FormGroup, Label, Row } from 'reactstrap';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import PageLayout from '../Layout/PageLayout'
import templateAPI from '../../api/template';
import templateConfigAPI from '../../api/templateConfig';
import { showAlert } from '../../components/Alert';
import { formatError } from '../../common/utils';
import Loader from '../../components/Loader';
import DefaultStatus from '../../common/default-status'
import ChannelType from "../../common/channel-type";
import ModalEditorLayout from '../Layout/ModalEditorLayout';
import UnlayerEditor from '../../components/UnlayerEditor';
import defaultTemplateJson from './defaultTemplateJson';
import Preview from '../../components/Preview';
import { Scrollbars } from 'react-custom-scrollbars';
import defaultTemplateHTML from './defaultTemplateHTML';


const template = {
  name: '',
  type: '',
  subscriptionType: '',
  subject: '',
  channelType: '',
  body: '',
  description: '',
  businessUnitId: NaN,
  status: '',
  jsonBody: {}
}

class TemplateForm extends Component {
  constructor(props) {
    super(props);
    let { businessUnitId } = this.props.match.params
    businessUnitId = (businessUnitId) ? parseInt(businessUnitId) : undefined
    const mode = (this.props.match.params.id) ? 'Edit' : 'Add'
    const is_disabled = (this.props.match.params.id) ? true : false

    this.state = {
      editorHTML: null,
      editorJSON: null,
      editorModal: false,
      isLoading: true,
      is_disabled,
      mode,
      businessUnitId,
      templateTypeList: [],
      filteredTemplateTypesList: [],
      subscriptionTypeList: [],
      template,
      text: '',
      channelType: ''
    };
  }

  componentDidMount = async () => {
    await this.getTemplateTypes();
    await this.getSubscriptionTypes();
    if (this.props.match.params.id) {
     await this.getTemplate(this.props.match.params.id);
    }
  }

  getTemplateTypes = async () => {
    try {
      const res = await templateConfigAPI.getConfigsForForm(this.state.businessUnitId)
      this.setState({ templateTypeList: res.data });
    } catch (error) {
      this.handleFailure(error.response)
    }
  };

  getSubscriptionTypes = async () => {
    try {
      const res = await templateAPI.getSubscriptionTypes();
      this.setState({ subscriptionTypeList: res.data });
      if (!this.props.match.params.id) {
        this.setState({ isLoading: false });
      }
    } catch (error) {
      this.handleFailure(error.response)
    }
  };

  getTemplate = async id => {
    try {
      const res = await templateAPI.getById(id);
      if(res.data.jsonBody === null){
        res.data.jsonBody = {}
      }
      this.setState({
        template: res.data,
        isLoading: false,
        editorJSON: Object.keys(res.data.jsonBody).length ? res.data.jsonBody : null,
        channelType: res.data.channelType
      })
      if (this.state.mode === 'Edit') {
        const filteredConfig = this.state.templateTypeList.filter((config) => config.channelType === this.state.channelType)
        this.setState({ filteredTemplateTypesList: filteredConfig })
      }
    } catch (error) {
      this.handleFailure(error.response)
    }
  }

  handleEmailContentChange = text => {
    this.setState({ text })
  }

  handleSubmit = (values, actions) => {
    const data = {
      ...values,
      businessUnitId: this.state.businessUnitId,
    };
    // If current user is business user then no need to pass business unit ID, it will be default to current user's business unit
    if (!this.state.businessUnitId) {
      delete data.businessUnitId
    }
    this.setState({
      isLoading: true,
    });
    if (ChannelType.SMS === data.channelType) {
      delete data.subject;
    }
    data.type = parseInt(data.type)
    data.subscriptionType = parseInt(data.subscriptionType)
    if (!values.id) {
      delete data.id;
      this.add(data, actions);
    } else {
      if ([ChannelType.SMS, ChannelType.PUSH].includes(data.channelType)) {
        delete data.jsonBody;
      }
      const notAllowedEdit = ['channelType', 'name', 'version', 'key', 'arn', 'businessUnitId', 'deleted', 'createdOn', 'modifiedOn', 'subscriptionTypes', 'types', 'defaultSubstitutions']; // following properties are not allowed to edit
      notAllowedEdit.map(val => {
        return delete data[val]
      })
      delete data.name
      this.update(data, actions);
    }
  };

  add = async (data, actions) => {
    try {
      await templateAPI.add(data);
      const msg = 'Template added successfully';
      this.handleSuccess(msg, actions);
    } catch (error) {
      this.handleFailure(error.response, actions)
    }
  }

  update = async (data, actions) => {
    const payload = { ...data }
    const id = data.id
    delete payload.id
    try {
      await templateAPI.update(id, payload);
      const msg = 'Template updated successfully';
      this.handleSuccess(msg, actions);
    } catch (error) {
      this.handleFailure(error.response, actions)
    }
  };


  handleSuccess = (msg) => {
    showAlert(msg);
    this.redirect();
  }

  handleFailure = (error, actions) => {
    showAlert(formatError(error), 'error', true);
    if (actions) {
      actions.setSubmitting(false)
    }
    this.setState({
      isLoading: false,
    });
  }

  redirect = () => {
    if (this.state.businessUnitId) {
      this.props.history.push(`/business-unit/${this.state.businessUnitId}/template`);
    } else {
      this.props.history.push('/template');
    }
  }
  toggleEditorModal = () => {
    this.setState({ editorModal: false });
  };

  onSave = (json, html) => {
    this.setState({ editorHTML: html, editorJSON: json, editorModal: false })
  }

  render() {
    return (
      <PageLayout headerName={`${this.state.mode} Template`}>
        {this.state.isLoading ? <Loader /> : null}
        <Formik
          initialValues={this.state.template}
          onSubmit={(values, actions) => this.handleSubmit(values, actions)}
          validationSchema={templateValidationSchema}
          enableReinitialize={true}
        >
          {prop => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              setValues
                        } = prop;
            return (
              <Form className='login-form' noValidate autoComplete='off'>
                {isSubmitting ? <Loader /> : null}
                <Row>
                  <Col lg={6}>
                    <FormGroup>
                      <Label className="text-muted" htmlFor="name">
                        Name<sup className="required">*</sup> (<b>Only alphanumeric, - & _ are allowed beginning with
                        alphabet</b>)
                      </Label>
                      <Field
                        name='name'
                        type='text'
                        placeholder='Name'
                        className='form-control'
                        required
                        value={values.name}
                        disabled={this.state.is_disabled}
                      />
                      {errors.name && touched.name ? (
                        <small className='error'>{errors.name}</small>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col lg={6}>
                    <FormGroup>
                      <Label className="text-muted" htmlFor="description">
                        Description
                      </Label>
                      <Field
                        name='description'
                        as='textarea'
                        placeholder='Description'
                        className='form-control'
                        value={values.description}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg={6}>
                    <FormGroup>
                      <Label className="text-muted" htmlFor="status">
                        Status <sup className="required">*</sup>
                      </Label>
                      <Field as="select" name="status" required value={values.status} className='form-control'
                        onChange={e => {
                          handleChange(e)
                        }}
                      >
                        <option value="">Status</option>
                        <option key={DefaultStatus.ACTIVE} value={DefaultStatus.ACTIVE}>{DefaultStatus.ACTIVE}</option>
                        <option key={DefaultStatus.INACTIVE}
                          value={DefaultStatus.INACTIVE}>{DefaultStatus.INACTIVE}</option>
                      </Field>
                      {errors.status && touched.status ? (
                        <small className='error'>{errors.status}</small>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col lg={6}>
                    <FormGroup>
                      <Label className="text-muted" htmlFor="subscriptionType">
                        Subscription Type <sup className="required">*</sup>
                      </Label>
                      <Field as="select" name="subscriptionType" required value={values.subscriptionType.toString()}
                        className='form-control' onChange={e => {
                          handleChange(e)
                        }}
                      >
                        <option value="">Select Type</option>
                        {this.state.subscriptionTypeList.map(subscriptionType => <option key={subscriptionType.id}
                          value={subscriptionType.id}>{subscriptionType.name}</option>)}
                      </Field>
                      {errors.subscriptionType && touched.subscriptionType ? (
                        <small className='error'>{errors.subscriptionType}</small>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col lg={6}>
                    <FormGroup>
                      <Label className="text-muted" htmlFor="channelType">
                        Channel Type <sup className="required">*</sup>
                      </Label>
                      <Field disabled={!!values.id} as="select" name="channelType" required
                        value={values.channelType.toString()}
                        className='form-control' onChange={e => {
                          setValues({
                            ...values,
                            type: '',
                            body: '',
                            jsonBody: {},
                            subject: ''
                          })
                          handleChange(e);
                          const filteredConfigs = this.state.templateTypeList.filter(config => config.channelType === e.target.value);
                          this.setState({ channelType: e.target.value, filteredTemplateTypesList: filteredConfigs })
                        }}
                      >
                        <option value="">Select Channel Type</option>
                        {Object.keys(ChannelType).map(key => <option key={key} value={key}>{key}</option>)}
                      </Field>
                      {errors.channelType && touched.channelType ? (
                        <small className='error'>{errors.channelType}</small>
                      ) : null}
                    </FormGroup>
                  </Col>

                  <Col lg={6}>
                    <FormGroup>
                      <Label className="text-muted" htmlFor="type">
                        Pinpoint Project Config <sup className="required">*</sup>
                      </Label>
                      <Field as="select" name="type" required value={values.type.toString()} className='form-control'
                        onChange={e => {
                          handleChange(e);
                        }}
                        disabled={!values.channelType}
                      >
                        <option value="">Select Config</option>
                        {this.state.filteredTemplateTypesList.map(templateType => <option key={templateType.id}
                          value={templateType.id}>{templateType.name}</option>)}
                      </Field>
                      {errors.type && touched.type ? (
                        <small className='error'>{errors.type}</small>
                      ) : null}
                    </FormGroup>
                  </Col>
                  {[ChannelType.EMAIL, ChannelType.PUSH].includes(values.channelType) && <Col lg={6}>
                    <FormGroup>
                      <Label className="text-muted" htmlFor="subject">
                        Subject <sup className="required">*</sup>
                      </Label>
                      <Field
                        name='subject'
                        type='text'
                        placeholder='Subject'
                        className='form-control'
                        required
                        value={values.subject}
                      />
                      {errors.subject && touched.subject ? (
                        <small className='error'>{errors.subject}</small>
                      ) : null}
                    </FormGroup>
                  </Col>}
                  {[ChannelType.EMAIL, ChannelType.PUSH].includes(values.channelType) && <Col lg={6} />}
                  {[ChannelType.SMS, ChannelType.PUSH].includes(values.channelType) && <Col xl={6}>
                    <FormGroup>
                      <Label className="text-muted" htmlFor="body">
                        Body <sup className="required">*</sup>
                      </Label>
                      <Field
                        name='body'
                        as='textarea'
                        rows='10'
                        placeholder={`${values.channelType} Content`}
                        className='form-control'
                        value={values.body}
                        style={{ height: ChannelType.EMAIL === values.channelType ? 532 : 200 }}
                        maxLength={values.channelType === ChannelType.SMS && 160}
                      />
                      {errors.body && touched.body ? (
                        <small className='error'>{errors.body}</small>
                      ) : null}
                    </FormGroup>
                  </Col>}

                  {ChannelType.EMAIL === values.channelType && (
                    <Col lg={6}>
                      <Button color="primary" className={'mr-2'} style={{ marginBottom: 20 }}
                        onClick={() => this.setState({ editorModal: true })}>
                        Open Editor
                      </Button>
                      <div>
                        {errors.body && touched.body ? (
                          <small className='error'>{errors.body}</small>
                        ) : null}
                      </div>
                    </Col>)
                  }

                  {[ChannelType.EMAIL].includes(values.channelType) && <Col lg={6} />}
                  {values.channelType === ChannelType.EMAIL && (
                    <Col xl={6}>
                      <FormGroup>
                        <Label className="text-muted" htmlFor="body">
                          <b>HTML</b>
                        </Label>
                        <textarea
                          name='show-html'
                          as='textarea'
                          rows='10'
                          className='form-control'
                          value={values.body || defaultTemplateHTML}
                          style={{ height: 532 }}
                          disabled
                        />
                      </FormGroup>
                    </Col>)}

                  {ChannelType.EMAIL === values.channelType &&
                    <Col xl={6}>
                      <FormGroup>
                        <Label className="text-muted" htmlFor="Preview">
                          <b>Preview</b>
                        </Label>
                        <div className="rounded p-3 queryOutput table-style">
                          <Scrollbars autoHide style={{ height: 500 }}>
                            <Preview html={values.body || defaultTemplateHTML} />
                          </Scrollbars>
                        </div>
                      </FormGroup>
                    </Col>

                  }

                  <FormGroup>
                    <ModalEditorLayout
                      isOpen={this.state.editorModal}
                      toggle={this.toggleEditorModal}
                    >
                      <UnlayerEditor
                        onSave={(json, html) => {
                          this.onSave(json, html);
                          setValues({
                            ...values,
                            body: html,
                            jsonBody: json
                          })
                        }}
                        jsonBody={this.state.editorJSON || defaultTemplateJson}
                      />
                    </ModalEditorLayout>

                  </FormGroup>

                  <Col lg={12} className="text-right">
                    <Button
                      className="mr-2"
                      color="light"
                      onClick={() => this.redirect()}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="success"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </PageLayout>
    );
  }
}

export default TemplateForm;

const templateValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required')
    .matches(/^[a-zA-Z]([a-zA-Z0-9_-]+)*$/, 'Only alphanumeric, - & _ are allowed beginning with alphabet') //NOSONAR
    .min(3, 'Should be minimum of 3 characters')
    .nullable(),
  body: Yup.string()
    .required('body is required')
    .nullable(),
  type: Yup.string()
    .required('Config is required')
    .nullable(),
  channelType: Yup.string()
    .required('Channel Type is required')
    .nullable(),
  subscriptionType: Yup.string()
    .required('Subscription Type is required')
    .nullable(),
  status: Yup.string()
    .required('Status is required')
    .nullable(),
  subject: Yup.string()
    .when('channelType', {
      is: ChannelType.EMAIL,
      then: Yup.string().required('Subject is required')
    }).when('channelType', {
      is: ChannelType.PUSH,
      then: Yup.string().required('Subject is required')
    }).nullable(),
});
