import Exception from './exception'
import * as _ from 'lodash'

export const formatError = error => {
    if (!error) {
        return Exception.INTERNAL_SERVER_ERROR
    }
    let text = error.response?.data?.error?.message?.message || error.response?.data?.error?.message || error.data?.error?.message;
    let validations = []
    if (error.data?.error?.statusCode === 422 && error.data?.error?.details.length > 0) {
        // There could be multiple errors so check for each error
        validations.push(error.data.error.details.map(validationError => {
            // Check for specific types for an error and display the message accordingly
            if (validationError.code === 'additionalProperties') {
                return `${validationError.message} ${validationError.info.additionalProperty}`
            }
            return `${validationError.path.slice(1)} ${validationError.message}`
        }))
    }
    text = (validations.length > 0) ? validations.join(` `) : text
    text = (text) ? text : Exception.INTERNAL_SERVER_ERROR;
    return text
}

export const capitalizeFirstLetter = strToCapitalize => {
    return strToCapitalize.replace(/^./, str => str.toUpperCase())
}

export const createFormattedSQL = sql => {
    let formattedSql = sql.split(" ");
    for (let i = 0; i < formattedSql.length; i++) {
        if (_.toLower(formattedSql[i]) === 'and') {
            formattedSql[i] = `<br /><span style='color:red; font-weight: bold;'> ${_.toUpper(formattedSql[i])} </span><br />`;
        }
        if (_.toLower(formattedSql[i]) === 'or') {
            formattedSql[i] = `<span style='color:blue; font-weight: bold;'> ${_.toUpper(formattedSql[i])} </span>`;
        }
    }
    return makeSQLCompatibleToDB(formattedSql.join(' ').slice(1, -1))
}

export const validatePassword = password => {
    return (
        /(?=^.{10,}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s)[0-9a-zA-Z+_!@#$%^&*()]*$/.test(password)
        ||
        /(?=^.{10,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[+_!@#$%^&*()])(?!.*\s)[0-9a-zA-Z+_!@#$%^&*()]*$/.test(password)
        ||
        /(?=^.{10,}$)(?=.*\d)(?=.*[a-z])(?=.*[+_!@#$%^&*()])(?!.*\s)[0-9a-zA-Z+_!@#$%^&*()]*$/.test(password)
        ||
        /(?=^.{10,}$)(?=.*\d)(?=.*[A-Z])(?=.*[+_!@#$%^&*()])(?!.*\s)[0-9a-zA-Z+_!@#$%^&*()]*$/.test(password)
    )
}

export const makeSQLCompatibleToDB = sql => sql.replace(/"/g, "'").replace(/#/g, "\"");
