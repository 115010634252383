const defaultTemplateHTML = `<!DOCTYPE html
    PUBLIC "-//W3C//DTD XHTML 1.0 Transitional //EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml"
    xmlns:o="urn:schemas-microsoft-com:office:office">

<head>
    <!--[if gte mso 9]>
<xml>
  <o:OfficeDocumentSettings>
    <o:AllowPNG/>
    <o:PixelsPerInch>96</o:PixelsPerInch>
  </o:OfficeDocumentSettings>
</xml>
<![endif]-->
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta name="x-apple-disable-message-reformatting">
    <!--[if !mso]><!-->
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <!--<![endif]-->
    <title></title>

    <style type="text/css">
        a {
            color: #0000ee;
            text-decoration: underline;
        }

        body {
            margin: 0;
            padding: 0;
        }

        table,
        tr,
        td {
            vertical-align: top;
            border-collapse: collapse;
        }

        p {
            margin: 0;
        }

        .ie-container table,
        .mso-container table {
            table-layout: fixed;
        }

        * {
            line-height: inherit;
        }

        a[x-apple-data-detectors='true'] {
            color: inherit !important;
            text-decoration: none !important;
        }

        .ExternalClass,
        .ExternalClass p,
        .ExternalClass span,
        .ExternalClass font,
        .ExternalClass td,
        .ExternalClass div {
            line-height: 100%;
        }

        [owa] .email-row .email-col {
            display: table-cell;
            float: none !important;
            vertical-align: top;
        }

        .ie-container .email-col-100,
        .ie-container .email-row,
        [owa] .email-col-100,
        [owa] .email-row {
            width: 500px !important;
        }

        .ie-container .email-col-17,
        [owa] .email-col-17 {
            width: 85px !important;
        }

        .ie-container .email-col-25,
        [owa] .email-col-25 {
            width: 125px !important;
        }

        .ie-container .email-col-33,
        [owa] .email-col-33 {
            width: 165px !important;
        }

        .ie-container .email-col-50,
        [owa] .email-col-50 {
            width: 250px !important;
        }

        .ie-container .email-col-67,
        [owa] .email-col-67 {
            width: 335px !important;
        }

        @media only screen and (min-width: 520px) {
            .email-row {
                width: 500px !important;
            }

            .email-row .email-col {
                vertical-align: top;
            }

            .email-row .email-col-100 {
                width: 500px !important;
            }

            .email-row .email-col-67 {
                width: 335px !important;
            }

            .email-row .email-col-50 {
                width: 250px !important;
            }

            .email-row .email-col-33 {
                width: 165px !important;
            }

            .email-row .email-col-25 {
                width: 125px !important;
            }

            .email-row .email-col-17 {
                width: 85px !important;
            }
        }

        @media (max-width: 520px) {
            .email-row-container {
                padding-left: 0px !important;
                padding-right: 0px !important;
            }

            .email-row .email-col {
                min-width: 320px !important;
                max-width: 100% !important;
                display: block !important;
            }

            .email-row {
                width: calc(100% - 40px) !important;
            }

            .email-col {
                width: 100% !important;
            }

            .email-col>div {
                margin: 0 auto;
            }

            .no-stack .email-col {
                min-width: 0 !important;
                display: table-cell !important;
            }

            .no-stack .email-col-50 {
                width: 50% !important;
            }

            .no-stack .email-col-33 {
                width: 33% !important;
            }

            .no-stack .email-col-67 {
                width: 67% !important;
            }

            .no-stack .email-col-25 {
                width: 25% !important;
            }

            .no-stack .email-col-17 {
                width: 17% !important;
            }
        }

        @media (max-width: 480px) {
            .hide-mobile {
                display: none !important;
                max-height: 0px;
                overflow: hidden;
            }
        }

        @media (min-width: 481px) {
            .hide-desktop {
                display: none !important;
                max-height: none !important;
            }
        }
    </style>



</head>

<body class="clean-body" style="margin: 0;padding: 0;-webkit-text-size-adjust: 100%;background-color: #e7e7e7">
    <!--[if IE]><div class="ie-container"><![endif]-->
    <!--[if mso]><div class="mso-container"><![endif]-->
    <table class="nl-container"
        style="border-collapse: collapse;table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align: top;min-width: 320px;Margin: 0 auto;background-color: #e7e7e7;width:100%"
        cellpadding="0" cellspacing="0">
        <tbody>
            <tr style="vertical-align: top">
                <td style="word-break: break-word;border-collapse: collapse !important;vertical-align: top">
                    <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td align="center" style="background-color: #e7e7e7;"><![endif]-->


                    <div class="email-row-container"
                        style="padding: 0px;background-image: url(&apos;https://bayview-qa-ui.sourcefuse.com/assets/img/bls-logo-white.png&apos;);background-repeat: no-repeat;background-position: center top;background-color: transparent">
                        <div style="Margin: 0 auto;min-width: 320px;max-width: 500px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: #004488;"
                            class="email-row">
                            <div
                                style="border-collapse: collapse;display: table;width: 100%;background-color: #004488;">
                                <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 0px;background-image: url('https://bayview-qa-ui.sourcefuse.com/assets/img/bls-logo-white.png');background-repeat: no-repeat;background-position: center top;background-color: transparent;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:500px;"><tr style="background-color: #004488;"><![endif]-->

                                <!--[if (mso)|(IE)]><td align="center" width="500" style="width: 500px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;" valign="top"><![endif]-->
                                <div class="email-col email-col-100"
                                    style="max-width: 320px;min-width: 500px;display: table-cell;vertical-align: top;">
                                    <div style="width: 100% !important;">
                                        <!--[if (!mso)&(!IE)]><!-->
                                        <div
                                            style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;">
                                            <!--<![endif]-->

                                            <table id="u_content_image_1" class="u_content_image"
                                                style="font-family:arial,helvetica,sans-serif;" role="presentation"
                                                cellpadding="0" cellspacing="0" width="100%" border="0">
                                                <tbody>
                                                    <tr>
                                                        <td style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;"
                                                            align="left">

                                                            <table width="100%" cellpadding="0" cellspacing="0"
                                                                border="0">
                                                                <tbody>
                                                                    <tr>
                                                                        <td style="padding-right: 0px;padding-left: 0px;"
                                                                            align="left">

                                                                            <img align="left" border="0"
                                                                                src="https://bayview-qa-ui.sourcefuse.com/assets/img/bls-logo-white.png"
                                                                                alt="Image" title="Image"
                                                                                style="outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: block !important;border: none;height: auto;float: none;width: 31%;max-width: 148.8px;"
                                                                                width="148.8"
                                                                                class="v-src-width v-src-max-width">

                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>

                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <!--[if (!mso)&(!IE)]><!-->
                                        </div>
                                        <!--<![endif]-->
                                    </div>
                                </div>
                                <!--[if (mso)|(IE)]></td><![endif]-->
                                <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
                            </div>
                        </div>
                    </div>



                    <div class="email-row-container" style="padding: 0px;background-color: transparent">
                        <div style="Margin: 0 auto;min-width: 320px;max-width: 500px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: #ffffff;"
                            class="email-row">
                            <div
                                style="border-collapse: collapse;display: table;width: 100%;background-color: #ffffff;">
                                <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 0px;background-color: transparent;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:500px;"><tr style="background-color: #ffffff;"><![endif]-->

                                <!--[if (mso)|(IE)]><td align="center" width="500" style="width: 500px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;" valign="top"><![endif]-->
                                <div class="email-col email-col-100"
                                    style="max-width: 320px;min-width: 500px;display: table-cell;vertical-align: top;">
                                    <div style="width: 100% !important;">
                                        <!--[if (!mso)&(!IE)]><!-->
                                        <div
                                            style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;">
                                            <!--<![endif]-->

                                            <table id="u_content_text_1" class="u_content_text"
                                                style="font-family:arial,helvetica,sans-serif;" role="presentation"
                                                cellpadding="0" cellspacing="0" width="100%" border="0">
                                                <tbody>
                                                    <tr>
                                                        <td style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;"
                                                            align="left">

                                                            <div class="v-text-align"
                                                                style="color: #000000; line-height: 140%; text-align: left; word-wrap: break-word;">
                                                                <p style="font-size: 14px; line-height: 140%;"><span
                                                                        style="font-family: arial, helvetica, sans-serif; font-size: 14px; line-height: 19.6px;">Hi</span>
                                                                </p>
                                                                <p style="font-size: 14px; line-height: 140%;">&#xA0;
                                                                </p>
                                                                <p
                                                                    style="font-size: 14px; line-height: 140%; text-align: center;">
                                                                    This is the area where you can define the content of
                                                                    the email.</p>
                                                                <p
                                                                    style="font-size: 14px; line-height: 140%; text-align: center;">
                                                                    To add a dynamic content from customer table follow
                                                                    the convention as {{Attributes.db_field_name}}</p>
                                                            </div>

                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <!--[if (!mso)&(!IE)]><!-->
                                        </div>
                                        <!--<![endif]-->
                                    </div>
                                </div>
                                <!--[if (mso)|(IE)]></td><![endif]-->
                                <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
                            </div>
                        </div>
                    </div>



                    <div class="email-row-container" style="padding: 0px;background-color: transparent">
                        <div style="Margin: 0 auto;min-width: 320px;max-width: 500px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: #ffffff;"
                            class="email-row">
                            <div
                                style="border-collapse: collapse;display: table;width: 100%;background-color: #ffffff;">
                                <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 0px;background-color: transparent;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:500px;"><tr style="background-color: #ffffff;"><![endif]-->

                                <!--[if (mso)|(IE)]><td align="center" width="500" style="width: 500px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;" valign="top"><![endif]-->
                                <div class="email-col email-col-100"
                                    style="max-width: 320px;min-width: 500px;display: table-cell;vertical-align: top;">
                                    <div style="width: 100% !important;">
                                        <!--[if (!mso)&(!IE)]><!-->
                                        <div
                                            style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;">
                                            <!--<![endif]-->

                                            <table id="u_content_button_1" class="u_content_button"
                                                style="font-family:arial,helvetica,sans-serif;" role="presentation"
                                                cellpadding="0" cellspacing="0" width="100%" border="0">
                                                <tbody>
                                                    <tr>
                                                        <td style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;"
                                                            align="left">

                                                            <div class="v-text-align" align="center">
                                                                <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="border-spacing: 0; border-collapse: collapse; mso-table-lspace:0pt; mso-table-rspace:0pt;font-family:arial,helvetica,sans-serif;"><tr><td class="v-text-align" style="font-family:arial,helvetica,sans-serif;" align="center"><v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href="" style="height:37px; v-text-anchor:middle; width:122px;" arcsize="0%" stroke="f" fillcolor="#1e8935"><w:anchorlock/><center style="color:#FFFFFF;font-family:arial,helvetica,sans-serif;"><![endif]-->
                                                                <a href target="_blank" class="v-size-width"
                                                                    style="box-sizing: border-box;display: inline-block;font-family:arial,helvetica,sans-serif;text-decoration: none;-webkit-text-size-adjust: none;text-align: center;color: #FFFFFF; background-color: #1e8935; border-radius: 0px; -webkit-border-radius: 0px; -moz-border-radius: 0px; width:auto; max-width:100%; overflow-wrap: break-word; word-break: break-word; word-wrap:break-word; mso-border-alt: none;">
                                                                    <span class="v-padding"
                                                                        style="display:block;padding:10px 20px;line-height:120%;"><span
                                                                            style="font-size: 14px; line-height: 16.8px;">Button
                                                                            Name</span></span>
                                                                </a>
                                                                <!--[if mso]></center></v:roundrect></td></tr></table><![endif]-->
                                                            </div>

                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <!--[if (!mso)&(!IE)]><!-->
                                        </div>
                                        <!--<![endif]-->
                                    </div>
                                </div>
                                <!--[if (mso)|(IE)]></td><![endif]-->
                                <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
                            </div>
                        </div>
                    </div>



                    <div class="email-row-container" style="padding: 0px;background-color: transparent">
                        <div style="Margin: 0 auto;min-width: 320px;max-width: 500px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: #ffffff;"
                            class="email-row">
                            <div
                                style="border-collapse: collapse;display: table;width: 100%;background-color: #ffffff;">
                                <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 0px;background-color: transparent;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:500px;"><tr style="background-color: #ffffff;"><![endif]-->

                                <!--[if (mso)|(IE)]><td align="center" width="500" style="width: 500px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;" valign="top"><![endif]-->
                                <div class="email-col email-col-100"
                                    style="max-width: 320px;min-width: 500px;display: table-cell;vertical-align: top;">
                                    <div style="width: 100% !important;">
                                        <!--[if (!mso)&(!IE)]><!-->
                                        <div
                                            style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;">
                                            <!--<![endif]-->

                                            <table id="u_content_text_2" class="u_content_text"
                                                style="font-family:arial,helvetica,sans-serif;" role="presentation"
                                                cellpadding="0" cellspacing="0" width="100%" border="0">
                                                <tbody>
                                                    <tr>
                                                        <td style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;"
                                                            align="left">

                                                            <div class="v-text-align"
                                                                style="color: #000000; line-height: 140%; text-align: left; word-wrap: break-word;">
                                                                <p
                                                                    style="font-size: 14px; line-height: 140%; text-align: center;">
                                                                    To add a link to button just click on button and add
                                                                    URL</p>
                                                                <p
                                                                    style="font-size: 14px; line-height: 140%; text-align: center;">
                                                                    under &quot;Action Type&quot;</p>
                                                                <p
                                                                    style="font-size: 14px; line-height: 140%; text-align: center;">
                                                                    &#xA0;</p>
                                                                <p
                                                                    style="font-size: 14px; line-height: 140%; text-align: center;">
                                                                    <br><span
                                                                        style="font-size: 24px; line-height: 33.6px;"><strong>Contact
                                                                            Us</strong></span>&#xA0;</p>
                                                                <p
                                                                    style="font-size: 14px; line-height: 140%; text-align: center;">
                                                                    &#xA0;</p>
                                                                <p
                                                                    style="font-size: 14px; line-height: 140%; text-align: left;">
                                                                    If you have a question about your account, please
                                                                    contact our Customer Relations team at
                                                                    1.800.457.5105 Monday through Friday from 8:00 AM to
                                                                    9:00 PM EST. </p>
                                                                <p
                                                                    style="font-size: 14px; line-height: 140%; text-align: left;">
                                                                    &#xA0;</p>
                                                                <p
                                                                    style="font-size: 14px; line-height: 140%; text-align: left;">
                                                                    <span
                                                                        style="background-color: #ecf0f1; font-size: 14px; line-height: 19.6px;">This
                                                                        email is sent from an unattended mailbox. Do not
                                                                        reply to this message with account-related
                                                                        questions.</span>&#xA0;</p>
                                                                <p style="font-size: 14px; line-height: 140%;">&#xA0;
                                                                </p>
                                                            </div>

                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <!--[if (!mso)&(!IE)]><!-->
                                        </div>
                                        <!--<![endif]-->
                                    </div>
                                </div>
                                <!--[if (mso)|(IE)]></td><![endif]-->
                                <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
                            </div>
                        </div>
                    </div>



                    <div class="email-row-container" style="padding: 0px;background-color: transparent">
                        <div style="Margin: 0 auto;min-width: 320px;max-width: 500px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: #003764;"
                            class="email-row">
                            <div
                                style="border-collapse: collapse;display: table;width: 100%;background-color: #003764;">
                                <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 0px;background-color: transparent;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:500px;"><tr style="background-color: #003764;"><![endif]-->

                                <!--[if (mso)|(IE)]><td align="center" width="500" style="width: 500px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;" valign="top"><![endif]-->
                                <div class="email-col email-col-100"
                                    style="max-width: 320px;min-width: 500px;display: table-cell;vertical-align: top;">
                                    <div style="width: 100% !important;">
                                        <!--[if (!mso)&(!IE)]><!-->
                                        <div
                                            style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;">
                                            <!--<![endif]-->

                                            <table id="u_content_text_3" class="u_content_text"
                                                style="font-family:arial,helvetica,sans-serif;" role="presentation"
                                                cellpadding="0" cellspacing="0" width="100%" border="0">
                                                <tbody>
                                                    <tr>
                                                        <td style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;"
                                                            align="left">

                                                            <div class="v-text-align"
                                                                style="color: #000000; line-height: 140%; text-align: left; word-wrap: break-word;">
                                                                <p style="font-size: 14px; line-height: 140%;"><span
                                                                        style="font-size: 12px; line-height: 16.8px; color: #ffffff;">Our
                                                                        mailing address is Bayview Loan Servicing,
                                                                        Customer Support</span><br><span
                                                                        style="font-size: 12px; line-height: 16.8px; color: #ffffff;">4425
                                                                        Ponce De Leon Blvd, 5th Floor, Coral Gables,
                                                                        FL,</span></p>
                                                                <p style="font-size: 14px; line-height: 140%;">&#xA0;
                                                                </p>
                                                                <p style="font-size: 14px; line-height: 140%;"><span
                                                                        style="font-size: 12px; line-height: 16.8px; color: #ffffff;">Bayview
                                                                        Loan Servicing, LLC, is a debt collector. This
                                                                        letter is an attempt to collect a debt. Any
                                                                        information obtained will be used for that
                                                                        purpose. To the extent that your obligation has
                                                                        been discharged or is subject to an automatic
                                                                        stay of bankruptcy, this notice is for
                                                                        information purposes only and does not
                                                                        constitute a demand of payment or any attempt to
                                                                        collect such obligation. Bayview Loan Servicing,
                                                                        LLC NMLS #2469. Equal Housing Lender.</span></p>
                                                                <p style="font-size: 14px; line-height: 140%;">&#xA0;
                                                                </p>
                                                                <p style="font-size: 14px; line-height: 140%;"><span
                                                                        style="font-size: 12px; line-height: 16.8px; color: #ffffff;">Copyright
                                                                        &#xA9; 2020 Bayview Loan Servicing.</span></p>
                                                            </div>

                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <table id="u_content_html_1" class="u_content_html"
                                                style="font-family:arial,helvetica,sans-serif;" role="presentation"
                                                cellpadding="0" cellspacing="0" width="100%" border="0">
                                                <tbody>
                                                    <tr>
                                                        <td style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;"
                                                            align="left">

                                                            <div>
                                                                <p style="font-family:arial;font-size:12px;color:white">
                                                                    If you do not wish to receive emails from us, please
                                                                    <a id="unsubscribe_link_tag"
                                                                        style="font-family:arial;font-size:12px;color:white"
                                                                        href="https://bayview-qa-ui.sourcefuse.com?bvc={{Attributes.username}}&amp;type=Payment%20Reminder&amp;businessUnit=TestSmstable"
                                                                        ses:tags="unsubscribeLinkTag:unclick;">unsubscribe</a>
                                                                </p>
                                                            </div>

                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <!--[if (!mso)&(!IE)]><!-->
                                        </div>
                                        <!--<![endif]-->
                                    </div>
                                </div>
                                <!--[if (mso)|(IE)]></td><![endif]-->
                                <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
                            </div>
                        </div>
                    </div>


                    <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
                </td>
            </tr>
        </tbody>
    </table>
    <!--[if (mso)|(IE)]></div><![endif]-->



</body>

</html>`
export default defaultTemplateHTML;