export const LocalDataKeys = {
  accessToken: 'accessToken',
  refreshToken: 'refreshToken',
  firstName: 'firstName',
  lastName: 'lastName',
  role: 'role',
  businessUnit: 'businessUnit',
  businessUnitId: 'businessUnitId',
  permissions: 'permissions'
};

const manipulateStorage = (key, mode, data) => {
  switch (mode) {
    case 'get':
      return localStorage.getItem(key);
    case 'set':
      if (data) localStorage.setItem(key, data);
      break;
    case 'remove':
      localStorage.removeItem(key);
      break;
    default:
      return null;
  }
};

const clearLocalStorage = () => {
  Object.keys(LocalDataKeys).map(key => {
    manipulateStorage(key, 'remove');
    return key
  })
};

export const LocalStorage = {
  manipulateStorage,
  clearLocalStorage
};
export default LocalStorage;
