import axiosInstance from '../../api'
import axios from 'axios';

const baseURL = 'queries'

const add = async payload => await axiosInstance.post(baseURL, payload)

const update = async (id, payload) => await axiosInstance.patch(`${baseURL}/${id}`, payload)

const get = async (where, sorting, pagination, {channelType}) => {
  const query = {
    include: [
      {
        relation: 'template'
      }
    ],
    where
  };
  if (channelType) {
    query.where.channelType = channelType;
  }
  if (sorting) {
    query.order = sorting;
  }
  if (pagination) {
    query.offset = pagination.offset;
    query.limit = pagination.limit;
  }
  const filter = JSON.stringify(query);
  return await axiosInstance.get(baseURL, {params: {filter}})
};

const getTotalCount = async where => await axiosInstance.get(`${baseURL}/count`, {params: {where}})

const getById = async id => await axiosInstance.get(`${baseURL}/${id}`);

const getQueryCount = async id => await axiosInstance.get(`${baseURL}/${id}/count`);

const getUploadURL = async (file, queryName) => await axiosInstance.get(`${baseURL}/csv-upload/${queryName}/${file.name}`);

const uploadFile = async (file, url) => {
  return await axios.put(url, file);
}

const exportCSV = async (id, payload) => await axiosInstance.post(`${baseURL}/${id}/export-csv`, payload)

const queryAPI = {
  add,
  update,
  get,
  getTotalCount,
  getById,
  getQueryCount,
  uploadFile,
  getUploadURL,
  exportCSV
};

export default queryAPI;
