import axiosInstance from '../../api'

const baseURL = 'campaign-attempt-logs'

const get = async (campaignId, sorting, pagination) => {
  const query = { where: { campaignId } };
  query.include = [
    {
      relation: 'campaign',
    }
  ];
  if (sorting) {
    query.order = sorting;
  }
  if (pagination) {
    query.offset = pagination.offset;
    query.limit = pagination.limit;
  }
  const filter = JSON.stringify(query);
  return await axiosInstance.get(baseURL, { params: { filter } })
};

const getTotalCount = async campaignId => await axiosInstance.get(`${baseURL}/count`, { params: { where: JSON.stringify({ campaignId }) } })

const attemptAPI = {
  get,
  getTotalCount,
};

export default attemptAPI;
