import React, { Component } from 'react'; // NOSONAR
import PageLayout from '../Layout/PageLayout'
import { showAlert } from '../../components/Alert';
import { formatError } from '../../common/utils';
import DataTable from '../../components/DataTable';
import * as defaultConfig from '../../common/default-config.json'
import templateConfigAPI from '../../api/templateConfig';

const config = defaultConfig.default;

class TemplateConfig extends Component {
    constructor(props) {
        super(props);
        let { businessUnitId } = this.props.match.params
        businessUnitId = (businessUnitId) ? parseInt(businessUnitId) : undefined
        this.state = {
            isLoading: false,
            mode: 'Add',
            templateConfigList: [],
            totalDataSize: 0,
            businessUnitId,
            currentPage: 1,
            sortOrder: config.DEFAULT_SORTING,
            pagination: config.DEFAULT_PAGINATION,
        };
    }

    componentDidMount(){
        this.getTotalCount();
        this.getData();
    }

    onPageChange = (page, sizePerPage) => {
        this.setState({ currentPage: page })
        this.setState({ pagination: { offset: (page * sizePerPage) - sizePerPage, limit: sizePerPage } })
        this.getData(undefined, {
            offset: page * sizePerPage - sizePerPage,
            limit: sizePerPage
        })
    };
    onSortChange = (sortName, sortOrder) => {
        this.setState({ sortOrder: `${sortName} ${sortOrder}` })
        this.getData(`${sortName} ${sortOrder}`)
    };


    handleSuccess = (msg, actions) => {
        showAlert(msg);
        if (actions) {
            this.resetState(actions.resetForm);
        }
        this.getData();
    }

    handleFailure = (error, actions) => {
        showAlert(formatError(error), 'error', true);
        if (actions) {
            actions.setSubmitting(false)
        }
        this.setState({
            isLoading: false,
        });
    }

    getData = async (sorting = this.state.sortOrder, pagination = this.state.pagination) => {
        this.setState({
            isLoading: true,
        });
        try {
            const res = await templateConfigAPI.get(this.state.businessUnitId, sorting, pagination,{});
            this.setState({ templateConfigList: res.data, isLoading: false });
        } catch (error) {
            this.handleFailure(error.response)
        }
    };

    getTotalCount = async () => {
        try {
            const res = await templateConfigAPI.getTotalCount(this.state.businessUnitId);
            this.setState({ totalDataSize: res.data.count })
        } catch (error) {
            this.handleFailure(error.response)
        }
    }

    actionFieldFormat = (cell, row) => {
        let editURL = `/addEditTemplateConfig/${row.id}`;
        if (this.state.businessUnitId) {
            editURL = `/business-unit/${this.state.businessUnitId}/addEditTemplateConfig/${row.id}`;
        }
        return (
            <div role='group' className='btn-group-none'>
                <i
                    title='Edit'
                    className='btn text-primary pointer mr-1 icon-pencil'
                    onClick={() => this.props.history.push(`${editURL}`)}
                />
            </div>
        )
    }

    render() {
        const gridColumns = [
            { dataField: 'name', text: 'Name', sort: true },
            { dataField: 'channelType', text: 'Channel Type' },
            { dataField: 'appId', text: 'App ID' },
            { dataField: 'action', text: 'Action', formatter: this.actionFieldFormat }
        ]
        let formUrl = "/addEditTemplateConfig";
        if (this.state.businessUnitId) {
            formUrl = `/business-unit/${this.state.businessUnitId}/addEditTemplateConfig`;
        }
        return (
            <PageLayout allowAddForm formUrl={formUrl} headerName='Manage Pinpoint Project Config'>
                <DataTable
                    isLoading={this.state.isLoading}
                    data={this.state.templateConfigList}
                    columns={gridColumns}
                    key={'id'}
                    onSortChange={(sortName, sortOrder) => this.onSortChange(sortName, sortOrder)}
                    currentPage={this.state.currentPage}
                    totalDataSize={this.state.totalDataSize}
                    onPageChange={(page, sizePerPage) => this.onPageChange(page, sizePerPage)}
                />
            </PageLayout>
        );
    }
}

export default TemplateConfig;
