import React, { Component } from 'react'; // NOSONAR
import { Button, Card, CardBody, Col, Container, Label, Row, FormGroup } from 'reactstrap';
import logo from '../../../assets/img/brand/bayview-logo.png';
import { Field, Formik, Form } from 'formik';
import { object as yupObject, string as yupString } from 'yup';
import Loader from '../../../components/Loader';
import { parse } from 'query-string';
import userAPI from '../../../api/users';
import authAPI from '../../../api/auth';
import { showAlert } from '../../../components/Alert';
import { formatError } from '../../../common/utils';
import { validatePassword } from '../../../common/utils'
import Exception from '../../../common/exception'

let token = null;
let config = {
    headers: { Authorization: null },
};
export default class SetPassword extends Component {
    componentDidMount = async () => {
        let query = parse(this.props.location.search);
        token = query.token;
        config = {
            headers: { Authorization: `Bearer ${token}` },
        };
        try {
            return await authAPI.checkToken(config);
        } catch (error) {
            showAlert('Invalid Token', 'error', true);
            this.props.history.push('/login');
        }
    }

    handleSubmit = async (values, actions) => {
        if (!validatePassword(values.password)) {
            actions.resetForm();
            actions.setSubmitting(false);
            showAlert(Exception.PASSWORD_EXCEPTION, 'error', true);
            return
        }
        const payload = { password: values.password };
        const apiAction = (this.props.type === 'new') ? 'setPassword' : 'resetPassword';
        try {
            await userAPI[apiAction](payload, config);
            showAlert('Password set successfully');
            this.props.history.push('/login');
        } catch (error) {
            actions.resetForm();
            actions.setSubmitting(false);
            showAlert(formatError(error), 'error', true);
        }
    };

    render() {
        return (
            <div className="login-layout">
                <div className="app cst-hgt flex-row align-items-center">
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg="6">
                                <Card className="border-0">
                                    <CardBody>
                                        <Formik
                                            validationSchema={yupObject().shape({
                                                password: yupString().required('password is required'),
                                                confirmPassword: yupString()
                                                    .required('password is required')
                                                    .test('passwords matched', 'Passwords does not match', function (
                                                        value,
                                                    ) {
                                                        return this.parent.password === value;
                                                    }),
                                            })}
                                            initialValues={{
                                                password: '',
                                                confirmPassword: '',
                                            }}
                                            onSubmit={(values, actions) => {
                                                this.handleSubmit(values, actions);
                                            }}
                                        >
                                            {props => {
                                                const {
                                                    isSubmitting,
                                                    values,
                                                    errors,
                                                    touched,
                                                    handleChange
                                                } = props;
                                                return (
                                                    <Form className="login-form" noValidate autoComplete="off">
                                                        {isSubmitting ? <Loader /> : null}
                                                        <div className="top text-center mb-3">
                                                            <div className="text-center mb-2">
                                                                <img src={logo} alt="Bayview Logo" />
                                                            </div>
                                                            <h1>Set Password</h1>
                                                        </div>
                                                        <FormGroup>
                                                            <Label className="text-muted" htmlFor="password">
                                                                Password <sup className="required">*</sup>
                                                            </Label>
                                                            <Field
                                                                name="password"
                                                                type="password"
                                                                placeholder="Password"
                                                                className="form-control"
                                                                required
                                                                label={'password'}
                                                                onChange={handleChange}
                                                                value={values.password}
                                                            />
                                                            {errors.password && touched.password ? (
                                                                <small className="error pb-0">{errors.password}</small>
                                                            ) : null}
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label className="text-muted" htmlFor="password">
                                                                Confirm Password <sup className="required">*</sup>
                                                            </Label>
                                                            <Field
                                                                name="confirmPassword"
                                                                placeholder="Confirm Password"
                                                                className="form-control"
                                                                type="password"
                                                                onChange={handleChange}
                                                                required
                                                                value={values.confirmPassword}
                                                            />
                                                            {errors.confirmPassword && touched.confirmPassword ? (
                                                                <small className="error pb-0">
                                                                    {errors.confirmPassword}
                                                                </small>
                                                            ) : null}
                                                        </FormGroup>

                                                        <Row>
                                                            <Col xs="12">
                                                                <Button
                                                                    type="submit"
                                                                    disabled={isSubmitting}
                                                                    color="primary"
                                                                    className="px-4 btn-lg btn-block"
                                                                >
                                                                    Set Password
                                                                    </Button>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                );
                                            }}
                                        </Formik>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        );
    }
}
