import React, {Component} from 'react'; // NOSONAR
import {Button, Col, FormGroup, Label, Row} from 'reactstrap';
import {Field, Form, Formik} from 'formik';
import * as Yup from 'yup';
import PageLayout from '../Layout/PageLayout'
import queryAPI from '../../api/query';
import templateAPI from '../../api/template';
import businessUnitAPI from '../../api/business-unit';
import commonAPI from '../../api/common';
import {showAlert} from '../../components/Alert';
import {createFormattedSQL, formatError, makeSQLCompatibleToDB} from '../../common/utils';
import Loader from '../../components/Loader';
import QueryBuilder, {formatQuery} from 'react-querybuilder';
import * as _ from 'lodash'
import convert from 'htmr';
import LocalStorage, {LocalDataKeys} from '../../services/local-storage';
import QueryType from '../../common/query-type';
import ChannelType from "../../common/channel-type";

const query = {
  name: '',
  dbQuery: '',
  queryJson: {},
  businessUnitId: NaN,
  templateId: '',
  type: '',
  channelType: '',
}

class QueryForm extends Component {
  constructor(props) {
    super(props);
    let {businessUnitId} = this.props.match.params
    businessUnitId = (businessUnitId) ? parseInt(businessUnitId) : undefined
    const mode = (this.props.match.params.id) ? 'Edit' : 'Add'

    this.state = {
      isLoading: true,
      mode,
      templateListSms: [],
      templateListEmail: [],
      templateListPush: [],
      query,
      businessUnitId,
      fields: [],
      preparedQuery: '',
      formattedSQL: '',
      formatDBSQL: '',
      type: '',
      s3File: '',
      previousCustomers: ''
    };
  }

  componentDidMount = async () => {
    this.getTemplates();
    await this.getCustomerModel();
    if (this.props.match.params.id) {
      this.getQuery(this.props.match.params.id);
    }
  }

  getQuery = async id => {
    try {
      const res = await queryAPI.getById(id);
      let formattedSQL = '';
      if (res.data.type !== QueryType.ADHOC) {
        formattedSQL = createFormattedSQL(formatQuery(res.data.queryJson, 'sql'));
      }
      this.setState({
        preparedQuery: res.data.queryJson,
        formatDBSQL: res.data.dbQuery,
        query: res.data,
        formattedSQL,
        isLoading: false,
        type: res.data.type,
        previousCustomers: res.data.s3Key
      })
    } catch (error) {
      this.handleFailure(error.response)
    }
  }

  getTemplates = async () => {
    try {
      const resForEmail = await templateAPI.getByBuIdAndChannelType(this.state.businessUnitId,   ChannelType.EMAIL);
      const resForSms = await templateAPI.getByBuIdAndChannelType(this.state.businessUnitId, ChannelType.SMS);
      const resForPush = await templateAPI.getByBuIdAndChannelType(this.state.businessUnitId, ChannelType.PUSH);

      this.setState({
        templateListEmail: resForEmail.data,
        templateListSms: resForSms.data,
        templateListPush: resForPush.data
      })
    } catch (error) {
      this.handleFailure(error.response)
    }
  }

  getCustomerModel = async () => {
    // TODO: Need to remove the following line and server should handle for associated tables, if I don't pass bu id then it takes the current logged in user one.
    const businessUnitId = (this.state.businessUnitId) ? this.state.businessUnitId : LocalStorage.manipulateStorage(LocalDataKeys.businessUnitId, 'get');
    try {
      const res = await businessUnitAPI.getTableConfig(businessUnitId, 'customer');
      _.mapKeys(res.data[0].modelDefinition, (value, key) => {
        value.label = (value.postgresql) ? value.postgresql?.columnName : value.name;
        value.name = (value.postgresql) ? `#${value.postgresql?.columnName}#` : `#${value.name}#`;
        return value;
      });
      this.setState({fields: _.sortBy(res.data[0].modelDefinition, 'name')})
      if (!this.props.match.params.id) {
        this.setState({isLoading: false});
      }
      return Promise.resolve();
    } catch (error) {
      this.handleFailure(error.response)
      return Promise.reject();
    }
  }

  download = async () => {
    this.setState({
      isLoading: true
    });
    try {
      const res = await commonAPI.downloadFile(this.state.previousCustomers);
      this.setState({
        isLoading: false
      });
      window.location = res.data
    } catch (error) {
      this.handleFailure(error.response)
    }
  };

  handleSubmit = async (values, actions) => {
    const data = {
      ...values,
      businessUnitId: this.state.businessUnitId
    };
    // If current user is business user then no need to pass business unit ID, it will be default to current user's business unit
    if (!this.state.businessUnitId) {
      delete data.businessUnitId
    }

    data.templateId = parseInt(data.templateId)

    // Check if query is for Ad-hoc campaign then file upload is must. In case of update only if query type has changed from customer to ad-hoc then only its required.
    if (data.type === QueryType.ADHOC) {
      if ((!this.state.s3File && !values.id) || (!this.state.s3File && this.state.query.type !== QueryType.ADHOC)) {
        actions.setErrors({
          file: 'Please upload a file'
        })
        actions.setSubmitting(false)
        return;
      }
      if (this.state.s3File) {
        const s3File = await this.uploadFile(this.state.s3File, data.name, actions)
        if (!s3File) return;
        data.s3Key = s3File
      }
    }

    if (data.type !== QueryType.ADHOC && this.state.preparedQuery?.rules.length <= 0) {
      actions.setErrors({
        queryJson: 'Query is required'
      })
      actions.setSubmitting(false)
      return;
    } else {
      data.queryJson = this.state.preparedQuery;
      data.dbQuery = this.state.formatDBSQL;
    }

    if (data.type === QueryType.ADHOC) {
      delete data.queryJson
      delete data.dbQuery
    } else {
      delete data.s3Key
    }

    this.setState({
      isLoading: true,
    });

    if (!values.id) {
      delete data.id;
      this.add(data, actions);
    } else {
      // Business unit cannot be updated
      delete data.businessUnitId
      delete data.channelType
      this.update(data, actions);
    }
  };

  add = async (data, actions) => {
    try {
      await queryAPI.add(data);
      const msg = 'Query added successfully';
      this.handleSuccess(msg);
    } catch (error) {
      this.handleFailure(error.response, actions)
    }
  }

  update = async (data, actions) => {
    const payload = {...data}
    const id = data.id
    delete payload.id
    try {
      await queryAPI.update(id, payload);
      const msg = 'Query updated successfully';
      this.handleSuccess(msg);
    } catch (error) {
      this.handleFailure(error.response, actions)
    }
  };

  handleSuccess = (msg) => {
    showAlert(msg);
    this.redirect();
  }

  handleFailure = (error, actions) => {
    showAlert(formatError(error), 'error', true);
    if (actions) {
      actions.setErrors({
        file: 'If the file has changed please upload the file again. To properly reset please select another file and select the updated file again.'
      })
      actions.setSubmitting(false)
    }
    this.setState({
      isLoading: false,
      s3File: ''
    });
  }

  redirect = () => {
    if (this.state.businessUnitId) {
      this.props.history.push(`/business-unit/${this.state.businessUnitId}/query`);
    } else {
      this.props.history.push('/query');
    }
  }

  onQueryChange = updatedQuery => {
    this.setState({preparedQuery: updatedQuery});
    let formattedSQL = formatQuery(updatedQuery, 'sql');
    const formatDBSQL = makeSQLCompatibleToDB(formattedSQL);
    this.setState({formattedSQL: createFormattedSQL(formattedSQL), formatDBSQL});
  }

  getOperators = field => {
    const selectedField = _.find(this.state.fields, {'name': field})
    switch (_.lowerCase(selectedField.type)) {
      case 'boolean':
        return [{name: '=', label: 'is'}, {name: 'is not', label: 'is not'}];
      case 'string':
        return [{name: 'null', label: 'is null'}, {name: 'notNull', label: 'is not null'}, {
          name: 'in',
          label: 'in'
        }, {name: 'notIn', label: 'not in'}, {name: '=', label: '='}, {name: '!=', label: '!='}, {
          name: 'like',
          label: 'like'
        }, {name: 'not like', label: 'not like'}];
      case 'number':
        return [{name: 'null', label: 'is null'}, {name: 'notNull', label: 'is not null'}, {
          name: '=',
          label: '='
        }, {name: '!=', label: '!='}, {name: '<', label: '<'}, {name: '>', label: '>'}, {
          name: '<=',
          label: '<='
        }, {name: '>=', label: '>='}, {name: 'in', label: 'in'}, {name: 'notIn', label: 'not in'}];
      case 'date':
        return [{name: 'null', label: 'is null'}, {name: 'notNull', label: 'is not null'}, {
          name: 'past_days_eq',
          label: 'Past Days ='
        }, {name: 'past_days_lte', label: 'Past Days <='}, {
          name: 'past_days_gte',
          label: 'Past Days >='
        }, {name: 'due_days_eq', label: 'Due Date ='}, {
          name: 'due_days_lte',
          label: 'Due Date <='
        }, {name: 'due_days_gte', label: 'Due Date >='}];

    }
  }

  getValueEditorType = (field, operator) => {
    const selectedField = _.find(this.state.fields, {'name': field})
    if (_.lowerCase(selectedField.type) === 'boolean') {
      return 'checkbox';
    }
    return 'text';
  }

  getInputType = (field, operator) => {
    const selectedField = _.find(this.state.fields, {'name': field})
    if (_.lowerCase(selectedField.type) === 'number' || _.lowerCase(selectedField.type) === 'date') {
      return 'number';
    }
    return 'text';
  }

  uploadFile = async (file, queryName, actions) => {
    try {
      const uploadURL = await queryAPI.getUploadURL(file, queryName);
      if (uploadURL.data) {
        await queryAPI.uploadFile(file, uploadURL.data.url, uploadURL.data.key)
        return uploadURL.data.key;
      } else {
        return false;
      }
    } catch (error) {
      showAlert('Error in uploading file. Please try again', error, true);
      return false;
    }
  }

  render() {
    return (
      <PageLayout headerName={`${this.state.mode} Query`}>
        {this.state.isLoading ? <Loader/> :
          <Formik
            initialValues={this.state.query}
            onSubmit={(values, actions) => this.handleSubmit(values, actions)}
            validationSchema={queryValidationSchema}
            enableReinitialize={true}
          >
            {prop => {
              const {
                values,
                touched,
                errors,
                isSubmitting,
                handleChange,
                setFieldValue
              } = prop;
              return (
                <Form className='login-form' noValidate autoComplete='off'>
                  {isSubmitting ? <Loader/> : null}
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Label className="text-muted" htmlFor="name">
                          Name <sup className="required">*</sup>
                        </Label>
                        <Field
                          name='name'
                          type='text'
                          placeholder='Name'
                          className='form-control'
                          required
                          value={values.name}
                        />
                        {errors.name && touched.name ? (
                          <small className='error'>{errors.name}</small>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup>
                        <Label className="text-muted" htmlFor="channelType">
                          Channel Type <sup className="required">*</sup>
                        </Label>
                        <Field disabled={!!values.id} as="select" name="channelType" required
                               value={values.channelType.toString()}
                               className='form-control' onChange={e => {
                          setFieldValue('templateId', '');
                          handleChange(e)
                        }}
                        >
                          <option value="">Select Channel Type</option>
                          {Object.keys(ChannelType).map(key => <option key={key} value={key}>{key}</option>)}
                        </Field>
                        {errors.channelType && touched.channelType ? (
                          <small className='error'>{errors.channelType}</small>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label className="text-muted" htmlFor="template">
                          Template <sup className="required">*</sup>
                        </Label>
                        <Field as="select" name="templateId" required value={values.templateId.toString()}
                               className='form-control'
                        >
                          <option value="">Select Template</option>
                          {ChannelType.EMAIL === values.channelType && this.state.templateListEmail.map(template =>
                            <option
                              key={template.id}
                              value={template.id}>{template.name}</option>)}
                          {ChannelType.SMS === values.channelType && this.state.templateListSms.map(template => <option
                            key={template.id}
                            value={template.id}>{template.name}</option>)}
                          {ChannelType.PUSH === values.channelType && this.state.templateListPush.map(template =>
                            <option
                              key={template.id}
                              value={template.id}>{template.name}</option>)}
                        </Field>
                        {errors.templateId && touched.templateId ? (
                          <small className='error'>{errors.templateId}</small>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label className="text-muted" htmlFor="type">
                          Query Type <sup className="required">*</sup>
                        </Label>
                        <Field as="select" name="type" required value={values.type} className='form-control'
                               onChange={e => {
                                 this.setState({type: e.target.value});
                                 handleChange(e)
                               }}
                        >
                          <option value="">Select Type</option>
                          {Object.entries(QueryType).map((type) => <option key={type[1]}
                                                                           value={type[1]}>{type[1]}</option>)}
                        </Field>
                        {errors.type && touched.type ? (
                          <small className='error'>{errors.type}</small>
                        ) : null}
                      </FormGroup>
                    </Col>
                    {this.state.type === QueryType.ADHOC &&
                    <Col xl={6}>
                      <FormGroup>
                        <Label className="text-muted" htmlFor="endDate">
                          Upload Customers <sup className="required">*</sup>
                        </Label>
                        <Field
                          name='file'
                          type='file'
                          className='form-control'
                          onChange={(event) => {
                            this.setState({s3File: event.currentTarget.files[0]})
                          }}
                        />
                        {errors.file ? (
                          <small className='error'>{errors.file}</small>
                        ) : null}
                      </FormGroup>
                    </Col>
                    }
                    {this.state.type === QueryType.ADHOC && this.state.mode === 'Edit' ?
                      <Col xl={6}>
                        <FormGroup>
                          <Label className="text-muted" htmlFor="endDate">
                            Download Previously Uploaded Customers
                          </Label>
                          <div className="rounded p-3 queryOutput table-style">
                            ADHOC Customers -
                            <i
                              title='Download Customers'
                              className='fa fa-cloud-download btn text-primary pointer'
                              onClick={() => this.download()}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      :
                      null
                    }
                    {this.state.type !== QueryType.ADHOC &&
                    <>
                      <Col xl={6}>
                        <FormGroup>
                          <Label className="text-muted" htmlFor="query">
                            Query Builder <sup className="required">*</sup>
                          </Label>
                          <div className="w-100 p-1 border rounded query-main">
                            <div className="d-block">
                              <QueryBuilder
                                query={this.state.preparedQuery}
                                fields={this.state.fields}
                                onQueryChange={this.onQueryChange}
                                getOperators={this.getOperators}
                                getInputType={this.getInputType}
                                translations={
                                  {
                                    removeGroup: {
                                      label: "X",
                                    }
                                  }
                                }
                                controlClassnames={{
                                  queryBuilder: "",
                                  ruleGroup: "p-2 rounded",
                                  header: "",
                                  combinators: "form-control mr-2",
                                  addRule: "btn btn-primary mr-2",
                                  addGroup: "btn btn-dark",
                                  removeGroup: "btn btn-danger ml-2",
                                  notToggle: "",
                                  rule: "rounded",
                                  fields: "form-control mr-2",
                                  operators: "form-control mr-2",
                                  value: "form-control",
                                  removeRule: "btn btn-danger ml-2"
                                }}
                                getValueEditorType={this.getValueEditorType}
                              />
                            </div>
                          </div>
                          {errors.queryJson && touched.queryJson ? (
                            <small className='error'>{errors.queryJson}</small>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col xl={6}>
                        <FormGroup>
                          <Label className="text-muted" htmlFor="template">
                            Query Output
                          </Label>
                          <div className="rounded p-3 queryOutput">
                            {convert(this.state.formattedSQL)}
                          </div>
                        </FormGroup>
                      </Col>
                    </>
                    }
                    <Col sm={12} className="text-right">
                      <Button
                        className="mr-2"
                        color="light"
                        onClick={() => this.redirect()}
                      >
                        Cancel
                      </Button>
                      <Button
                        color="success"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Save
                      </Button>
                    </Col>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        }
      </PageLayout>
    );
  }
}

export default QueryForm;

const queryValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required')
    .min(3, 'Should be minimum of 3 characters')
    .nullable(),
  templateId: Yup.number()
    .required('Template is required')
    .nullable(),
  type: Yup.string()
    .required('Type is required')
    .nullable(),
  channelType: Yup.string()
    .required('Channel Type is required')
    .nullable(),
});
