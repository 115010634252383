import UserType from '../src/common/user-type'

export default {
    items: [
        {
            name: 'User Management',
            url: '/user-management',
            icon: 'fa fa-user',
            role: UserType.ADMIN
        },
        {
            name: 'Business Units',
            url: '/business-unit',
            icon: 'icon-star',
            role: UserType.ADMIN
        },
        {
            name: 'Manage Campaigns',
            url: '/campaign',
            icon: 'fa fa-bullhorn',
            role: UserType.BUSINESS_USER
        },
        {
            name: 'Manage Queries',
            url: '/query',
            icon: 'fa fa-question-circle',
            role: UserType.BUSINESS_USER
        },
        {
            name: 'Manage Templates',
            url: '/template',
            icon: 'fa fa-envelope',
            role: UserType.BUSINESS_USER
        },
        {
            name: 'Manage Tables',
            url: '/customTable',
            icon: 'fa fa-table',
            role: UserType.BUSINESS_USER
        },
        {
            name:'Change Password',
            url: '/change-password',
            icon: 'fa fa-key',
            role: UserType.ALL
        },
        {
            name: 'Logout',
            url: '/logout',
            icon: 'fa fa-lock',
            role: UserType.ALL
        },
        {
            divider: true,
        },
    ],
};
