import React, {Component} from 'react'; // NOSONAR
import PageLayout from '../Layout/PageLayout'
import campaignAPI from '../../api/campaign';
import {showAlert} from '../../components/Alert';
import {formatError} from '../../common/utils';
import DataTable from '../../components/DataTable';
import * as defaultConfig from '../../common/default-config.json'
import moment from 'moment';
import CampaignFilter from "./CampaignFilter";


class Campaign extends Component {
  constructor(props) {
    super(props);
    let {businessUnitId} = this.props.match.params
    businessUnitId = (businessUnitId) ? parseInt(businessUnitId) : undefined;

    this.state = {
      businessUnitId,
      where: {businessUnitId},
      isLoading: false,
      campaignList: [],

      //pagination
      totalDataSize: 0,
      currentPage: 1,
      sortOrder: defaultConfig.DEFAULT_SORTING,
      pagination: defaultConfig.DEFAULT_PAGINATION
    };
  }

  componentDidMount() {
    this.getData();
  }

  onPageChange = (page, sizePerPage) => {
    this.setState({currentPage: page})
    this.setState({pagination: {offset: (page * sizePerPage) - sizePerPage, limit: sizePerPage}})
    this.getData(undefined, {
      offset: page * sizePerPage - sizePerPage,
      limit: sizePerPage
    })
  };
  onSortChange = (sortName, sortOrder) => {
    this.setState({sortOrder: `${sortName} ${sortOrder}`})
    this.getData(`${sortName} ${sortOrder}`)
  };

  handleFailure = (error, actions) => {
    showAlert(formatError(error), 'error', true);
    if (actions) {
      actions.setSubmitting(false)
    }
    this.setState({
      isLoading: false,
    });
  }

  getData = (sorting = this.state.sortOrder, pagination = this.state.pagination) => {
    return this.getCampaignData(this.state.where, sorting, pagination);
  };

  /**
   * method passed to child component CampaignFilter to handle data fetching
   * @param where
   * @returns {Promise<void>}
   */
  getFilteredData = (where = {}) => {
    where["businessUnitId"] = this.state.businessUnitId;
    this.setState({where});
    return this.getCampaignData(where, null, null);
  };

  getCampaignData = async (where = this.state.where, sorting = this.state.sortOrder, pagination = this.state.pagination) => {
    this.setState({
      isLoading: true,
    });

    await this.getTotalCount(where);
    try {
      const res = await campaignAPI.get(where, sorting, pagination);
      this.setState({campaignList: res.data, isLoading: false});
    } catch (error) {
      this.handleFailure(error.response)
    }
  };

  getTotalCount = async (where) => {
    try {
      const res = await campaignAPI.getTotalCount(where);
      this.setState({totalDataSize: res.data.count})
    } catch (error) {
      this.handleFailure(error.response)
    }
  }

  actionFieldFormat = (cell, row) => {
    let editURL = `/addEditCampaign/${row.id}`;
    if (this.state.businessUnitId) {
      editURL = `/business-unit/${this.state.businessUnitId}/addEditCampaign/${row.id}`;
    }
    return (
      <div role='group' className='btn-group-none'>
        <i
          title='Edit'
          className='btn text-primary pointer mr-1 icon-pencil'
          onClick={() => this.props.history.push(`${editURL}`)}
        />
        <i
          title='View Attempts'
          className='btn text-primary pointer icon-eye'
          onClick={() => this.props.history.push(`/campaignAttemptLog/${row.id}`)}
        />
      </div>
    )
  }

  queryFieldFormat = (cell, row) => {
    return row.query.name
  }

  startFieldFormat = (cell, row) => {
    const onlyDate = `${moment(row.startDate).utc().format(defaultConfig.UI_DATE_FORMAT)}`;
    return moment(`${onlyDate} ${row.startTime}`).format(defaultConfig.UI_DATE_TIME_FORMAT);
  }

  endFieldFormat = (cell, row) => (row.endDate) ? moment(row.endDate).utc().format(defaultConfig.UI_DATE_FORMAT) : null;

  render() {
    const gridColumns = [
      {dataField: 'name', text: 'Name', sort: true},
      {dataField: 'channelType', text: 'Channel Type'},
      {dataField: 'queryId', text: 'Query', formatter: this.queryFieldFormat},
      {dataField: 'frequency', text: 'Frequency', sort: true},
      {dataField: 'startDate', text: 'Start', sort: true, formatter: this.startFieldFormat},
      {dataField: 'endDate', text: 'End', sort: true, formatter: this.endFieldFormat},
      {dataField: 'timeZone', text: 'Timezone'},
      {dataField: 'status', text: 'Status', sort: true},
      {dataField: 'action', text: 'Action', formatter: this.actionFieldFormat}
    ]
    let formUrl = "/addEditCampaign";
    if (this.state.businessUnitId) {
      formUrl = `/business-unit/${this.state.businessUnitId}/addEditCampaign`;
    }

    return (
      <PageLayout allowAddForm formUrl={formUrl} headerName='Manage Campaigns'>
        <CampaignFilter getFilteredData={this.getFilteredData.bind(this)}/>
        <DataTable
          isLoading={this.state.isLoading}
          data={this.state.campaignList}
          columns={gridColumns}
          key={'id'}
          onSortChange={(sortName, sortOrder) => this.onSortChange(sortName, sortOrder)}
          currentPage={this.state.currentPage}
          totalDataSize={this.state.totalDataSize}
          onPageChange={(page, sizePerPage) => this.onPageChange(page, sizePerPage)}
        />
      </PageLayout>
    );
  }
}

export default Campaign;
