import axios from 'axios';
import LocalStorage, { LocalDataKeys } from '../services/local-storage';

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_END_POINT}`,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(async config => {

  let accessToken;
  const awsAdhocUrl = 'amazonaws.com/adhoc';

  accessToken = LocalStorage.manipulateStorage(LocalDataKeys.accessToken, 'get');
  if (accessToken) {
    if (!config.url.includes(awsAdhocUrl)) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
  } else {
    if (!window.location.hash.includes('set-password') && !window.location.hash.includes('forgot-password')) {
      window.location.href = '/#/login';
    }
  }
  return Promise.resolve(config);
});

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401 && error.config.url === '/auth/token-refresh') {
      LocalStorage.clearLocalStorage();
      window.location.href = '/#/login';
    }
    if (error.response && error.response.status === 401 && error.config.url !== '/auth/login-token') {
      // Check if refresh token is valid
      const refreshToken = LocalStorage.manipulateStorage(LocalDataKeys.refreshToken, 'get');
      if (refreshToken) {
        // Get new access token
        return axiosInstance
          .post('/auth/token-refresh', { refreshToken })
          .then(res => {
            LocalStorage.manipulateStorage(LocalDataKeys.accessToken, 'set', res.data.accessToken);
            LocalStorage.manipulateStorage(LocalDataKeys.refreshToken, 'set', res.data.refreshToken);
            // Replay the original request again with new access token
            return axiosInstance(error.config);
          });
      }
      LocalStorage.clearLocalStorage();
      window.location.href = '/#/login';
    }
    return Promise.reject(error);
  },
);

export default axiosInstance;
