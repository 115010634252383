const defaultTemplateJson = {
    "body": {
        "rows": [
            {
                "cells": [
                    1
                ],
                "values": {
                    "_meta": {
                        "htmlID": "u_row_1",
                        "htmlClassNames": "u_row"
                    },
                    "columns": false,
                    "padding": "0px",
                    "deletable": true,
                    "draggable": true,
                    "hideMobile": false,
                    "selectable": true,
                    "hideDesktop": false,
                    "duplicatable": true,
                    "noStackMobile": false,
                    "backgroundColor": "",
                    "backgroundImage": {
                        "url": "https://bayview-qa-ui.sourcefuse.com/assets/img/bls-logo-white.png",
                        "cover": false,
                        "width": 300,
                        "center": true,
                        "height": 115,
                        "repeat": false,
                        "fullWidth": true
                    },
                    "columnsBackgroundColor": "#004488"
                },
                "columns": [
                    {
                        "values": {
                            "_meta": {
                                "htmlID": "u_column_1",
                                "htmlClassNames": "u_column"
                            },
                            "border": {},
                            "padding": "0px",
                            "backgroundColor": ""
                        },
                        "contents": [
                            {
                                "type": "image",
                                "values": {
                                    "src": {
                                        "url": "https://bayview-qa-ui.sourcefuse.com/assets/img/bls-logo-white.png",
                                        "width": 300,
                                        "height": 115,
                                        "maxWidth": "31%",
                                        "autoWidth": false
                                    },
                                    "_meta": {
                                        "htmlID": "u_content_image_1",
                                        "htmlClassNames": "u_content_image"
                                    },
                                    "action": {
                                        "name": "web",
                                        "values": {
                                            "href": "",
                                            "target": "_blank"
                                        }
                                    },
                                    "altText": "Image",
                                    "deletable": true,
                                    "draggable": true,
                                    "textAlign": "left",
                                    "hideMobile": false,
                                    "selectable": true,
                                    "hideDesktop": false,
                                    "duplicatable": true,
                                    "containerPadding": "10px"
                                }
                            }
                        ]
                    }
                ]
            },
            {
                "cells": [
                    1
                ],
                "values": {
                    "_meta": {
                        "htmlID": "u_row_2",
                        "htmlClassNames": "u_row"
                    },
                    "columns": false,
                    "padding": "0px",
                    "deletable": true,
                    "draggable": true,
                    "hideMobile": false,
                    "selectable": true,
                    "hideDesktop": false,
                    "duplicatable": true,
                    "noStackMobile": false,
                    "backgroundColor": "",
                    "backgroundImage": {
                        "url": "",
                        "cover": false,
                        "center": true,
                        "repeat": false,
                        "fullWidth": true
                    },
                    "columnsBackgroundColor": "#ffffff"
                },
                "columns": [
                    {
                        "values": {
                            "_meta": {
                                "htmlID": "u_column_2",
                                "htmlClassNames": "u_column"
                            },
                            "border": {},
                            "padding": "0px",
                            "backgroundColor": ""
                        },
                        "contents": [
                            {
                                "type": "text",
                                "values": {
                                    "text": "<p style=\"font-size: 14px; line-height: 140%;\"><span style=\"font-family: arial, helvetica, sans-serif; font-size: 14px; line-height: 19.6px;\">Hi</span></p>\n<p style=\"font-size: 14px; line-height: 140%;\">&nbsp;</p>\n<p style=\"font-size: 14px; line-height: 140%; text-align: center;\">This is the area where you can define the content of the email.</p>\n<p style=\"font-size: 14px; line-height: 140%; text-align: center;\">To add a dynamic content from customer table follow the convention as {{Attributes.db_field_name}}</p>",
                                    "_meta": {
                                        "htmlID": "u_content_text_1",
                                        "htmlClassNames": "u_content_text"
                                    },
                                    "color": "#000000",
                                    "deletable": true,
                                    "draggable": true,
                                    "linkStyle": {
                                        "inherit": true,
                                        "linkColor": "#0000ee",
                                        "linkUnderline": true,
                                        "linkHoverColor": "#0000ee",
                                        "linkHoverUnderline": true
                                    },
                                    "textAlign": "left",
                                    "hideMobile": false,
                                    "lineHeight": "140%",
                                    "selectable": true,
                                    "hideDesktop": false,
                                    "duplicatable": true,
                                    "containerPadding": "10px"
                                }
                            }
                        ]
                    }
                ]
            },
            {
                "cells": [
                    1
                ],
                "values": {
                    "_meta": {
                        "htmlID": "u_row_3",
                        "htmlClassNames": "u_row"
                    },
                    "columns": false,
                    "padding": "0px",
                    "deletable": true,
                    "draggable": true,
                    "hideMobile": false,
                    "selectable": true,
                    "hideDesktop": false,
                    "duplicatable": true,
                    "noStackMobile": false,
                    "backgroundColor": "",
                    "backgroundImage": {
                        "url": "",
                        "cover": false,
                        "center": true,
                        "repeat": false,
                        "fullWidth": true
                    },
                    "columnsBackgroundColor": "#ffffff"
                },
                "columns": [
                    {
                        "values": {
                            "_meta": {
                                "htmlID": "u_column_3",
                                "htmlClassNames": "u_column"
                            },
                            "border": {},
                            "padding": "0px",
                            "backgroundColor": ""
                        },
                        "contents": [
                            {
                                "type": "button",
                                "values": {
                                    "href": {
                                        "name": "web",
                                        "values": {
                                            "href": "",
                                            "target": "_blank"
                                        }
                                    },
                                    "size": {
                                        "width": "100%",
                                        "autoWidth": true
                                    },
                                    "text": "<span style=\"font-size: 14px; line-height: 16.8px;\">Button Name</span>",
                                    "_meta": {
                                        "htmlID": "u_content_button_1",
                                        "htmlClassNames": "u_content_button"
                                    },
                                    "border": {},
                                    "padding": "10px 20px",
                                    "deletable": true,
                                    "draggable": true,
                                    "textAlign": "center",
                                    "hideMobile": false,
                                    "lineHeight": "120%",
                                    "selectable": true,
                                    "hideDesktop": false,
                                    "borderRadius": "0px",
                                    "buttonColors": {
                                        "color": "#FFFFFF",
                                        "hoverColor": "#FFFFFF",
                                        "backgroundColor": "#1e8935",
                                        "hoverBackgroundColor": "#3AAEE0"
                                    },
                                    "duplicatable": true,
                                    "calculatedWidth": 122,
                                    "calculatedHeight": 37,
                                    "containerPadding": "10px"
                                }
                            }
                        ]
                    }
                ]
            },
            {
                "cells": [
                    1
                ],
                "values": {
                    "_meta": {
                        "htmlID": "u_row_4",
                        "htmlClassNames": "u_row"
                    },
                    "columns": false,
                    "padding": "0px",
                    "deletable": true,
                    "draggable": true,
                    "hideMobile": false,
                    "selectable": true,
                    "hideDesktop": false,
                    "duplicatable": true,
                    "noStackMobile": false,
                    "backgroundColor": "",
                    "backgroundImage": {
                        "url": "",
                        "cover": false,
                        "center": true,
                        "repeat": false,
                        "fullWidth": true
                    },
                    "columnsBackgroundColor": "#ffffff"
                },
                "columns": [
                    {
                        "values": {
                            "_meta": {
                                "htmlID": "u_column_4",
                                "htmlClassNames": "u_column"
                            },
                            "border": {},
                            "padding": "0px",
                            "backgroundColor": ""
                        },
                        "contents": [
                            {
                                "type": "text",
                                "values": {
                                    "text": "<p style=\"font-size: 14px; line-height: 140%; text-align: center;\">To add a link to button just click on button and add URL</p>\n<p style=\"font-size: 14px; line-height: 140%; text-align: center;\">under \"Action Type\"</p>\n<p style=\"font-size: 14px; line-height: 140%; text-align: center;\">&nbsp;</p>\n<p style=\"font-size: 14px; line-height: 140%; text-align: center;\"><br /><span style=\"font-size: 24px; line-height: 33.6px;\"><strong>Contact Us</strong></span>&nbsp;</p>\n<p style=\"font-size: 14px; line-height: 140%; text-align: center;\">&nbsp;</p>\n<p style=\"font-size: 14px; line-height: 140%; text-align: left;\">If you have a question about your account, please contact our Customer Relations team at 1.800.457.5105 Monday through Friday from 8:00 AM to 9:00 PM EST.&nbsp;</p>\n<p style=\"font-size: 14px; line-height: 140%; text-align: left;\">&nbsp;</p>\n<p style=\"font-size: 14px; line-height: 140%; text-align: left;\"><span style=\"background-color: #ecf0f1; font-size: 14px; line-height: 19.6px;\">This email is sent from an unattended mailbox. Do not reply to this message with account-related questions.</span>&nbsp;</p>\n<p style=\"font-size: 14px; line-height: 140%;\">&nbsp;</p>",
                                    "_meta": {
                                        "htmlID": "u_content_text_2",
                                        "htmlClassNames": "u_content_text"
                                    },
                                    "color": "#000000",
                                    "deletable": true,
                                    "draggable": true,
                                    "linkStyle": {
                                        "inherit": true,
                                        "linkColor": "#0000ee",
                                        "linkUnderline": true,
                                        "linkHoverColor": "#0000ee",
                                        "linkHoverUnderline": true
                                    },
                                    "textAlign": "left",
                                    "hideMobile": false,
                                    "lineHeight": "140%",
                                    "selectable": true,
                                    "hideDesktop": false,
                                    "duplicatable": true,
                                    "containerPadding": "10px"
                                }
                            }
                        ]
                    }
                ]
            },
            {
                "cells": [
                    1
                ],
                "values": {
                    "_meta": {
                        "htmlID": "u_row_5",
                        "htmlClassNames": "u_row"
                    },
                    "columns": false,
                    "padding": "0px",
                    "deletable": true,
                    "draggable": true,
                    "hideMobile": false,
                    "selectable": true,
                    "hideDesktop": false,
                    "duplicatable": true,
                    "noStackMobile": false,
                    "backgroundColor": "",
                    "backgroundImage": {
                        "url": "",
                        "cover": false,
                        "center": true,
                        "repeat": false,
                        "fullWidth": true
                    },
                    "columnsBackgroundColor": "#003764"
                },
                "columns": [
                    {
                        "values": {
                            "_meta": {
                                "htmlID": "u_column_5",
                                "htmlClassNames": "u_column"
                            },
                            "border": {},
                            "padding": "0px",
                            "backgroundColor": ""
                        },
                        "contents": [
                            {
                                "type": "text",
                                "values": {
                                    "text": "<p style=\"font-size: 14px; line-height: 140%;\"><span style=\"font-size: 12px; line-height: 16.8px; color: #ffffff;\">Our mailing address is Bayview Loan Servicing, Customer Support</span><br /><span style=\"font-size: 12px; line-height: 16.8px; color: #ffffff;\">4425 Ponce De Leon Blvd, 5th Floor, Coral Gables, FL,</span></p>\n<p style=\"font-size: 14px; line-height: 140%;\">&nbsp;</p>\n<p style=\"font-size: 14px; line-height: 140%;\"><span style=\"font-size: 12px; line-height: 16.8px; color: #ffffff;\">Bayview Loan Servicing, LLC, is a debt collector. This letter is an attempt to collect a debt. Any information obtained will be used for that purpose. To the extent that your obligation has been discharged or is subject to an automatic stay of bankruptcy, this notice is for information purposes only and does not constitute a demand of payment or any attempt to collect such obligation. Bayview Loan Servicing, LLC NMLS #2469. Equal Housing Lender.</span></p>\n<p style=\"font-size: 14px; line-height: 140%;\">&nbsp;</p>\n<p style=\"font-size: 14px; line-height: 140%;\"><span style=\"font-size: 12px; line-height: 16.8px; color: #ffffff;\">Copyright &copy; 2020 Bayview Loan Servicing.</span></p>",
                                    "_meta": {
                                        "htmlID": "u_content_text_3",
                                        "htmlClassNames": "u_content_text"
                                    },
                                    "color": "#000000",
                                    "deletable": true,
                                    "draggable": true,
                                    "linkStyle": {
                                        "inherit": true,
                                        "linkColor": "#0000ee",
                                        "linkUnderline": true,
                                        "linkHoverColor": "#0000ee",
                                        "linkHoverUnderline": true
                                    },
                                    "textAlign": "left",
                                    "hideMobile": false,
                                    "lineHeight": "140%",
                                    "selectable": true,
                                    "hideDesktop": false,
                                    "duplicatable": true,
                                    "containerPadding": "10px"
                                }
                            },
                            {
                                "type": "html",
                                "values": {
                                    "html": "<p style=\"font-family:arial;font-size:12px;color:white\">If you do not wish to receive emails from us, please <a id=\"unsubscribe_link_tag\" style=\"font-family:arial;font-size:12px;color:white\">unsubscribe</a></p>",
                                    "_meta": {
                                        "htmlID": "u_content_html_1",
                                        "htmlClassNames": "u_content_html"
                                    },
                                    "deletable": true,
                                    "draggable": true,
                                    "hideMobile": false,
                                    "selectable": true,
                                    "hideDesktop": false,
                                    "duplicatable": true,
                                    "containerPadding": "10px"
                                }
                            }
                        ]
                    }
                ]
            }
        ],
        "values": {
            "_meta": {
                "htmlID": "u_body",
                "htmlClassNames": "u_body"
            },
            "linkStyle": {
                "body": true,
                "linkColor": "#0000ee",
                "linkUnderline": true,
                "linkHoverColor": "#0000ee",
                "linkHoverUnderline": true
            },
            "fontFamily": {
                "label": "Arial",
                "value": "arial,helvetica,sans-serif"
            },
            "contentWidth": "500px",
            "backgroundColor": "#e7e7e7",
            "backgroundImage": {
                "url": "",
                "cover": false,
                "center": true,
                "repeat": false,
                "fullWidth": true
            }
        }
    },
    "counters": {
        "u_row": 5,
        "u_column": 5,
        "u_content_html": 1,
        "u_content_text": 3,
        "u_content_image": 1,
        "u_content_button": 1
    },
    "schemaVersion": 5
}
export default defaultTemplateJson