import axiosInstance from '../../api';

const baseURL = 'users';

const add = async payload => await axiosInstance.post(baseURL, payload)

const update = async (id, payload) => await axiosInstance.patch(`${baseURL}/${id}`, payload)

const get = async (sorting, pagination) => {
    const query = {
        include: [
            {
                relation: 'userBusinessUnit',
                scope: {
                    include: [{ relation: 'businessUnit' }, { relation: 'role' }],
                },
            },
        ],
    };

    if (sorting) {
        query.order = sorting;
    }
    if (pagination) {
        query.offset = pagination.offset;
        query.limit = pagination.limit;
    }
    const filter = JSON.stringify(query);
    return await axiosInstance.get(baseURL, { params: { filter } })
};

const getTotalCount = async () => await axiosInstance.get(`${baseURL}/count`)

const setPassword = async (payload, config) => await axiosInstance.patch(`${baseURL}/set-password`, payload, config)

const resetPassword = async (payload, config) => await axiosInstance.patch(`${baseURL}/reset-password`, payload, config)

const changePassword = async (payload, config) => await axiosInstance.patch(`${baseURL}/change-password`, payload, config)

const resendRegistrationEmail = async id => await axiosInstance.patch(`${baseURL}/send-verification-email/${id}`)

const forgotPassword = async payload => await axiosInstance.patch(`${baseURL}/forgot-password`, payload)

const userAPI = {
    add,
    update,
    get,
    getTotalCount,
    resendRegistrationEmail,
    setPassword,
    changePassword,
    forgotPassword,
    resetPassword
};
export default userAPI;
