import React, { Component } from 'react'; // NOSONAR
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

function PageLayout(props) {
  return (
    <div className="animated fadeIn">
      <Row>
        <Col xl={12}>
          <Card>
            <CardHeader>
              <Row>
                <Col xs={5}>
                  <h3 className="text-primary">{props.headerName}</h3>
                </Col>
                <Col xs={7} className="text-right">
                  <Row className="justify-content-end">
                    {
                      props.allowAddForm ?
                        <Col xs="auto">
                          {props.toggleModal ?
                            <button
                              onClick={() =>
                                props.toggleModal()
                              }
                              type="button"
                              className="btn btn-primary"
                              data-toggle="modal"
                              data-target="#exampleModalScrollable"
                            >
                              <span className="cil-plus btn-icon mr-2"></span>
                              Add New
                            </button>
                            :
                            <Link to={props.formUrl}>
                              <button
                                type="button"
                                className="btn btn-primary"
                                data-toggle="modal"
                                data-target="#exampleModalScrollable"
                              >
                                <span className="cil-plus btn-icon mr-2"></span>
                                Add New
                              </button>
                            </Link>
                          }
                        </Col> : null
                    }
                  </Row>
                </Col>
              </Row>
            </CardHeader>

            <CardBody>
              {props.children}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default PageLayout;
