const Exception = {
    INTERNAL_SERVER_ERROR: 'Internal Server Error',
    PASSWORD_EXCEPTION: `Password must be at least 10 characters and contain characters from at least 3 of the following categories:
    1. Uppercase characters (A, B, C…..Z)
    2. Lower case characters (a, b, c…..z)
    3. Numbers (1, 2, 3, 4, 5, 6, 7, 8, 9, 0)
    4. Non-alphanumeric characters (!, @, #, $, %, ^, &, *, (, ), _,+)`
}

export default Exception;
