import React, { Component } from 'react'; // NOSONAR
import PageLayout from '../Layout/PageLayout'
import attemptAPI from '../../api/campaign/attemptAPI';
import campaignAPI from '../../api/campaign';
import commonAPI from '../../api/common';
import { showAlert } from '../../components/Alert';
import { formatError, capitalizeFirstLetter } from '../../common/utils';
import DataTable from '../../components/DataTable';

import * as defaultConfig from '../../common/default-config.json'
import moment from 'moment';
const config = defaultConfig.default;

class CampaignAttemptLog extends Component {
    constructor(props) {
        super(props);
        let { campaignId } = this.props.match.params
        campaignId = (campaignId) ? parseInt(campaignId) : undefined
        this.state = {
            isLoading: false,
            campaignAttemptLogList: [],
            totalDataSize: 0,
            campaignId,
            campaignName: '',
            currentPage: 1,
            sortOrder: config.DEFAULT_SORTING,
            pagination: config.DEFAULT_PAGINATION,
        };
    }

    componentDidMount() {
        this.getTotalCount();
        this.getData();
    }

    onPageChange = (page, sizePerPage) => {
        this.setState({ currentPage: page })
        this.setState({ pagination: { offset: (page * sizePerPage) - sizePerPage, limit: sizePerPage } })
        this.getData(undefined, {
            offset: page * sizePerPage - sizePerPage,
            limit: sizePerPage
        })
    };
    onSortChange = (sortName, sortOrder) => {
        this.setState({ sortOrder: `${sortName} ${sortOrder}` })
        this.getData(`${sortName} ${sortOrder}`)
    };

    handleFailure = (error, actions) => {
        showAlert(formatError(error), 'error', true);
        if (actions) {
            actions.setSubmitting(false)
        }
        this.setState({
            isLoading: false,
        });
    }

    getTotalCount = async () => {
        try {
            const res = await attemptAPI.getTotalCount(this.state.campaignId);
            this.setState({ totalDataSize: res.data.count })
        } catch (error) {
            this.handleFailure(error.response)
        }
    }

    getData = async (sorting = this.state.sortOrder, pagination = this.state.pagination) => {
        this.setState({
            isLoading: true,
        });
        try {
            const res = await attemptAPI.get(this.state.campaignId, sorting, pagination);
            this.setState({ campaignAttemptLogList: res.data, isLoading: false })
            if (res.data.length > 0) {
                this.setState({ campaignName: res.data[0]?.campaign?.name })
            } else {
                if (!this.state.campaignName) {
                    try {
                        const response = await campaignAPI.getById(this.state.campaignId);
                        this.setState({ campaignName: response.data.name })
                    } catch (error) {
                        this.handleFailure(error.response)
                    }
                }
            }
        } catch (error) {
            this.handleFailure(error.response)
        }
    };

    download = async key => {
        this.setState({
            isLoading: true
        });
        try {
            const res = await commonAPI.downloadFile(key);
            this.setState({
                isLoading: false
            });
            window.location = res.data
        } catch (error) {
            this.handleFailure(error.response)
        }
    };

    actionFieldFormat = (cell, row) => {
        const attemptFiles = (row.s3UrlAttemptFile) ? row.s3UrlAttemptFile.split(',') : '';
        return (
            <div role='group' className='btn-group-none'>
                {attemptFiles.length > 0 && attemptFiles.map(file =>
                    <i
                        title='Download'
                        className='fa fa-cloud-download btn btn-lg text-primary pointer'
                        onClick={() => this.download(file)}
                    />
                )}
            </div>
        )
    }

    dateFieldFormat = (cell, row) => moment(row.createdOn).format(config.UI_DATE_FORMAT);

    render() {
        const gridColumns = [
            { dataField: 'pinpointCampaignId', text: 'Pinpoint Campaign Id' },
            { dataField: 'createdOnDate', text: 'Attempt Date', sort: true, formatter: this.dateFieldFormat },
            { dataField: 'stage', text: 'Current Stage', sort: true },
            { dataField: 'status', text: 'Status', sort: true },
            {
                dataField: 'action', text: 'Action', formatter: this.actionFieldFormat, headerStyle: () => {
                    return { width: "20%" };
                }
            }
        ]

        return (
            <PageLayout headerName={`${capitalizeFirstLetter(this.state.campaignName)} Attempt Log`}>
                <DataTable
                    isLoading={this.state.isLoading}
                    data={this.state.campaignAttemptLogList}
                    columns={gridColumns}
                    key={'id'}
                    onSortChange={(sortName, sortOrder) => this.onSortChange(sortName, sortOrder)}
                    currentPage={this.state.currentPage}
                    totalDataSize={this.state.totalDataSize}
                    onPageChange={(page, sizePerPage) => this.onPageChange(page, sizePerPage)}
                />
            </PageLayout>
        );
    }
}

export default CampaignAttemptLog;
